import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import useWindowWidth from '../../hooks/window';
import { shopPageApi } from '../../store/Slices/productSlice';
const CatSlider = props => {
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isToken = useSelector(state => state.authuser.isToken);
  const user = useSelector(state => state.authuser.user);
  const isHeaderCategoryList = useSelector(state => state.category.isHeaderCategoryList);
  const category =
    isHeaderCategoryList && isHeaderCategoryList?.categories && isHeaderCategoryList?.categories?.length > 0
      ? isHeaderCategoryList?.categories
      : [];
  const isApiStatus = useSelector(state => state.category.isApiStatus);
  const isLoading = isApiStatus && isApiStatus.headerCategoryApi == 'loading';
  const [itemBg, setItemBg] = useState([
    '#fffceb',
    '#ecffec',
    '#feefea',
    '#fff3eb',
    '#fff3ff',
    '#f2fce4',
    '#feefea',
    '#fffceb',
    '#feefea',
    '#ecffec',
    '#feefea',
    '#fff3eb',
    '#fff3ff',
    '#f2fce4',
    '#feefea',
    '#fffceb',
    '#feefea',
    '#ecffec'
  ]);

  const slider = useRef();

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    fade: false,
    arrows: width > 992 ? true : false,
    autoplay: width > 992 ? 2000 : false,
    centerMode: width > 992 ? true : false
  };

  return (
    <>
      <div className='catSliderSection'>
        <div className='container-fluid' ref={slider}>
          <h2 className='hd'>Featured Categories</h2>
          <Slider {...settings} className='cat_slider_Main' id='cat_slider_Main'>
            {category.length > 0 && !isLoading ? (
              category.map((item, index) => {
                return (
                  <div
                    className='item'
                    key={index}
                    onClick={() => {
                      dispatch(shopPageApi({ isToken, category_id: item?.id })).then(() => {
                        navigate(`/cat/${item.title.toLowerCase().replace(/\s+/g, '-')}`);
                      });
                    }}
                  >
                    <div className='info' style={{ background: itemBg[index] }}>
                      <img src={`${isHeaderCategoryList?.storage_url}/${item.image}`} width='80' />
                      <h5 className='text-capitalize mt-3'>{item.title}</h5>
                      <p>{item?.product_count} items</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='mt-0 pt-0'>
                <div className='d-flex'>
                  <Skeleton
                    height={200}
                    width={215}
                    count={1}
                    highlightColor='#def9ec'
                    baseColor='#f9f6f4'
                    style={{ marginRight: '2rem' }}
                  />
                  <Skeleton
                    height={200}
                    width={215}
                    count={1}
                    highlightColor='#def9ec'
                    baseColor='#f9f6f4'
                    style={{ marginRight: '2rem' }}
                  />
                  <Skeleton
                    height={200}
                    width={215}
                    count={1}
                    highlightColor='#def9ec'
                    baseColor='#f9f6f4'
                    style={{ marginRight: '2rem' }}
                  />
                  <Skeleton
                    height={200}
                    width={215}
                    count={1}
                    highlightColor='#def9ec'
                    baseColor='#f9f6f4'
                    style={{ marginRight: '2rem' }}
                  />
                </div>
              </div>
            )}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default CatSlider;
