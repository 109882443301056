import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Avatar,
  Pagination,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Card,
  CardActionArea,
  CardMedia,
  CardContent
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { BlogViewType, blogApi } from '../../store/Slices/categorySlice';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import useWindowWidth from '../../hooks/window';
import GridViewIcon from '@mui/icons-material/GridView';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import BlogGridView from './BlogGridView';
import BlogListView from './BlogListView';
import BlogListDensed from './BlogListDensed';
import BlogDetail from './BlogDetail';
import notFound from '../../assets/images/404.png';
import './blog.css';

const Blogs = () => {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const isBlogData = useSelector(state => state.category.isBlogData);
  const blogs =
    isBlogData && isBlogData?.blogs && isBlogData?.blogs?.data && isBlogData?.blogs?.data?.length > 0
      ? isBlogData?.blogs?.data
      : [];
  const totalPages = isBlogData?.blogs?.last_page || 1;
  const isOpenBlogDetailModal = useSelector(state => state.category.isOpenBlogDetailModal);
  const isBlogViewType = useSelector(state => state.category.isBlogViewType);
  const isToken = useSelector(state => state.authuser.isToken);
  const isApiStatus = useSelector(state => state.category.isApiStatus);
  const blogLoading = isApiStatus && isApiStatus?.blogApi === 'loading';
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    dispatch(blogApi({ isToken, page, keyword }));
  }, [dispatch, isToken, page, keyword]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleAlignment = (event, newAlignment) => {
    dispatch(BlogViewType(newAlignment));
  };

  const handleKeywordChange = event => {
    setKeyword(event.target.value);
  };

  return (
    <Box sx={{ margin: width > 992 ? '270px 45px 40px 45px' : '120px 45px 40px 45px' }}>
      <Stack display={'flex'} justifyContent={'space-between'} direction={'row'} sx={{ marginBottom: '28px' }}>
        <Typography variant='h4' component='h1' gutterBottom>
          Blogs
        </Typography>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <TextField
            variant='outlined'
            label='Search Author'
            value={keyword}
            onChange={handleKeywordChange}
            sx={{ marginRight: 2 }}
          />
          <ToggleButtonGroup value={isBlogViewType} exclusive onChange={handleAlignment} aria-label='text alignment'>
            <ToggleButton value='grid' aria-label='left aligned'>
              <GridViewIcon />
            </ToggleButton>
            <ToggleButton value='list' aria-label='centered'>
              <TableRowsIcon />
            </ToggleButton>
            <ToggleButton value='list_densed' aria-label='right aligned'>
              <ViewHeadlineIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Stack>

      {blogLoading ? (
        <Grid container spacing={2}>
          {Array.from({ length: 8 }).map((_, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <Skeleton height={300} />
            </Grid>
          ))}
        </Grid>
      ) : blogs.length > 0 ? (
        isBlogViewType === 'grid' ? (
          <BlogGridView isBlogData={isBlogData} isToken={isToken} isApiStatus={isApiStatus} />
        ) : isBlogViewType === 'list' ? (
          <BlogListView isBlogData={isBlogData} isToken={isToken} isApiStatus={isApiStatus} />
        ) : isBlogViewType === 'list_densed' ? (
          <BlogListDensed isBlogData={isBlogData} isToken={isToken} isApiStatus={isApiStatus} />
        ) : null
      ) : (
        <Box display={'flex'} justifyContent={'center'}>
        <Card component={Paper} elevation={5} sx={{ maxWidth:width > 992 ? '720px' : '320px' }}>
          <CardActionArea>
            <CardMedia
              component='img'
              image={notFound}
              alt='No Blogs Found'
              sx={{ height: '320px', objectFit: 'contain' }}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                No Blogs Found
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                We couldn't find any blogs matching your search criteria. Please try again with different keywords.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        </Box>
      )}
      {totalPages > 1 ? (
      <Box mt={4} display='flex' justifyContent='center'>
        <Pagination count={isBlogData?.blogs?.last_page || 1} page={page} onChange={handlePageChange} color='secondary'/>
      </Box>
      ) : null}
      {isOpenBlogDetailModal && <BlogDetail />}
    </Box>
  );
};

export default Blogs;
