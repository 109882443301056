import Swal from 'sweetalert2';
import { useEffect } from 'react';

const useSweetAlert = () => {
  const fireAlert = (icon, title, message, question = false, confirmText = 'OK') => {
    return Swal.fire({
      icon,
      title,
      text: message,
      showCancelButton: question,
      confirmButtonText: confirmText,
    });
  };

  useEffect(() => {
    // Optionally, you can initialize or clean up SweetAlert2 resources here
    return () => {
      // Clean up any resources if needed
    };
  }, []);

  return {
    showAlert: fireAlert,
  };
};

export default useSweetAlert;
