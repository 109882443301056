import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { logout } from '../store/Slices/authSlice';
import axiosInstance from '../pages/axios/axios';

function PrivateRoute({ children }) {
  const isLoggedIn = useSelector(state => state.authuser.isLoggedIn);

  if (isLoggedIn) {
    return children;
  } else {
    return <Navigate to='/signIn' replace={true} />;
  }
}

export default PrivateRoute;
