import React, { useEffect, useState } from 'react';
import './style.css';
import Rating from '@mui/material/Rating';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { productAddCompareApi, productDetailApi } from '../../store/Slices/productSlice';
import { Watch } from 'react-loader-spinner';
import useSweetAlert from '../../hooks/sweetalert';
import { countableItemApi, headerCategoryApi } from '../../store/Slices/categorySlice';
import { addProductToWishListApi } from '../../store/Slices/profileSlice';
import { cartStoreApi } from '../../store/Slices/cartSlice';
import useWindowWidth from '../../hooks/window';

const Product = props => {
  const [productData, setProductData] = useState();
  const { showAlert } = useSweetAlert();
  const [isAdded, setIsadded] = useState(false);
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const isToken = useSelector(state => state.authuser.isToken);
  const isApiStatus = useSelector(state => state.product.isApiStatus);
  const navigate = useNavigate();
  useEffect(() => {
    if (props.item) {
      setProductData(props.item);
    }
  }, [props.item]);

  return (
    <div className='productThumb'>
      <span className={`badge ${'new'}`}>in stock</span>
      {productData !== undefined && (
        <>
          <div className='imgWrapper'>
            <div className='p-4 wrapper mb-3'>
              <img
                src={`${productData?.storage_url}/${productData?.thumbnail_image}` + '?im=Resize=(420,420)'}
                className='w-100'
                style={{ maxHeight: '270px', objectFit: 'contain' }}
              />
            </div>
            <div className='overlay transition'>
              <ul className='list list-inline mb-0'>
                <li className='list-inline-item'>
                  <a className='cursor' tooltip='Add to Wishlist' onClick={()=>{
                    dispatch(addProductToWishListApi({isToken,product_id:productData?.id})).then((action)=>{
                      if(action?.meta?.requestStatus == "fulfilled"){
                        if(action?.payload?.already_added){
                          showAlert('warning', 'Oops...', 'Product already added to wishlist', false, 'OK');
                        }else{
                          dispatch(countableItemApi({ isToken })).then(()=>{
                            showAlert('success', 'Success', 'Product Added To WishList', false, 'OK');
                            dispatch(headerCategoryApi({isToken}));
                          });
                        }
                      }
                    });
                  }}>
                    {productData?.wishlist_count > 0 ? (<FavoriteIcon/>) : (<FavoriteBorderOutlinedIcon />)}
                  </a>
                </li>
                <li className='list-inline-item'>
                  <a className='cursor' tooltip='Compare' onClick={()=>{
                    dispatch(productAddCompareApi({isToken,id:productData?.id})).then((action)=>{
                      if(action?.meta?.requestStatus == "fulfilled"){
                        if(action?.payload?.already_added){
                          showAlert('warning', 'Oops...', 'Product already added to compare', false, 'OK');
                        }else{
                          dispatch(countableItemApi({ isToken })).then(()=>{
                            showAlert('success', 'Success', 'Product Added To Comparison', false, 'OK');
                          });
                        }
                      }
                    });
                    
                  }}>
                    <CompareArrowsOutlinedIcon />
                  </a>
                </li>
                <li
                  className='list-inline-item'
                  onClick={() => {
                    dispatch(productDetailApi({ id: productData?.id, isToken: isToken })).then(() => {
                      navigate(`/product/${productData?.id}`);
                    });
                  }}
                >
                  <a className='cursor' tooltip='Quick View'>
                    {isApiStatus?.productDetailApi == 'loading' ? (
                      <Watch
                        visible={true}
                        height='20'
                        width='20'
                        color='#4fa94d'
                        ariaLabel='watch-loading'
                        wrapperStyle={{}}
                        wrapperClass=''
                      />
                    ) : (
                      <RemoveRedEyeOutlinedIcon />
                    )}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='info'>
            <span className='d-block catName'>{productData?.brand?.title}</span>
            <h4 className='title'>
              <a className='cursor-pointer' onClick={() => {
                    dispatch(productDetailApi({ id: productData?.id, isToken: isToken })).then(() => {
                      navigate(`/product/${productData?.id}`);
                    });
                  }}>{productData?.title?.substr(0,width > 1341 ? 25 : width > 1125 ? 16 : width > 992 ? 20 : width < 400 ? 12 : 18) + '...'}</a>
            </h4>
            <Rating name='half-rating-read' value={parseFloat(4)} precision={0.5} readOnly />
            <span className='brand d-block text-g'>
              By <Link className='text-g'>{productData?.brand?.title}</Link>
            </span>
            <div className='d-flex align-items-center mt-3'>
              <div className='d-flex align-items-center w-100'>
                <span className='price text-g font-weight-bold'>Rs {productData?.price}</span>{' '}
                <span className='oldPrice ml-auto'>Rs {productData?.old_price}</span>
              </div>
            </div>
            <Button className='w-100 transition mt-3' onClick={()=>{
               dispatch(cartStoreApi({ isToken, product_id: productData?.id, quantity:1 })).then(action => {
                dispatch(countableItemApi({ isToken }))
                if (action?.meta?.requestStatus === 'fulfilled') {
                  showAlert(
                    'success',
                    'Success',
                    'Product successfully added to cart',
                    false,
                    'OK'
                  );
                }
              });
            }}>
              <ShoppingCartOutlinedIcon />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
