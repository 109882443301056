import React from 'react';
import './style.css';

import { Link, useNavigate } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import { productDetailApi } from '../../../store/Slices/productSlice';
import { useDispatch, useSelector } from 'react-redux';

const TopProducts = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Products = props && props?.item ? props?.item : '';
  const isToken = useSelector(state => state.authuser.isToken);
  return (
    <div className='items d-flex align-items-center'>
      <div className='img'>
        <Link to=''>
          <img
            src={`${Products?.storage_url}/${Products?.thumbnail_image}`}
            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
          />
        </Link>
      </div>

      <div className='info px-3'>
        <a className='cursor-pointer'
          onClick={() => {
            dispatch(productDetailApi({ id: Products?.id, isToken: isToken })).then(() => {
              navigate(`/product/${Products?.id}`);
            });
          }}
        >
          <h4>{Products?.title}</h4>
        </a>
        <Rating name='half-rating-read' defaultValue={3.5} precision={0.5} readOnly />
        <div className='d-flex align-items-center'>
          <span className='price text-g font-weight-bold'>${Products?.price}</span>{' '}
          <span className='oldPrice'>${Products?.old_price}</span>
        </div>
      </div>
    </div>
  );
};

export default TopProducts;
