import React, { useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import config from '../config';
import { logout } from '../store/Slices/authSlice';
import axios from 'axios';

function PublicRoute({ children }) {
  const isLoggedIn = useSelector(state => state.authuser.isLoggedIn);
  const isToken = useSelector(state => state.authuser.isToken);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${config.API_URL}afterlogin/authCheck`,
  //         {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${isToken}` // Pass the token in the headers
  //           }
  //         }
  //       );
  //       const status = response && response.data && response.data.status;
  //       if (!status) {
  //         dispatch(logout());
  //       }
  //     } catch (error) {
  //       console.error('Error checking token:', error);
  //     }
  //   };

  //   if (isLoggedIn && isToken) {
  //     // checkToken();
  //   }
  // }, [dispatch, isLoggedIn, isToken]);

  if (isLoggedIn) {
    return <Navigate to={'/'} replace={true} />;
  } else {
    return children;
  }
}

export default PublicRoute;
