import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateIcon from '@mui/icons-material/Create';
import {
  Avatar,
  Chip,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  Stack,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { styled } from '@mui/system';
import notFound from '../../../assets/images/404.png';
import useWindowWidth from '../../../hooks/window';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import useSweetAlert from '../../../hooks/sweetalert';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import {
  CustomerAddressTab,
  countryDropdownApi,
  userAddressDetailApi,
  userAddressListsApi
} from '../../../store/Slices/profileSlice';
import CreateAddress from './CreateAddress';
import EditAddress from './EditAddress';
import { cartItemRemoveApi } from '../../../store/Slices/cartSlice';
import CheckIcon from '@mui/icons-material/Check';

const Address = () => {
  const dispatch = useDispatch();
  const { showAlert } = useSweetAlert();
  const isToken = useSelector(state => state.authuser.isToken);
  const isCustomerAddressTab = useSelector(state => state.profile.isCustomerAddressTab);
  const isAddressLists = useSelector(state => state.profile.isAddressLists);
  const isApiStatus = useSelector(state => state.profile.isApiStatus);
  const addresses = isAddressLists && isAddressLists?.addresses?.length > 0 ? isAddressLists?.addresses : [];
  const isAddressLoading = isApiStatus && isApiStatus?.userAddressListsApi === 'loading';

  useEffect(() => {
    dispatch(userAddressListsApi({ isToken }));
  }, [isToken]);

  return (
    <Box>
      {isCustomerAddressTab && isCustomerAddressTab == 'list' ? (
        <Stack>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h4' sx={{ margin: '25px 0px' }}>
              My Addresses
            </Typography>
            <Button
              variant='contained'
              onClick={() => {
                dispatch(countryDropdownApi({ isToken })).then(() => {
                  dispatch(CustomerAddressTab('create'));
                });
              }}
            >
              Create Address
            </Button>
          </Box>
          <Grid spacing={3}>
            <Grid item xs={12} md={8}>
              <TableContainer component={Paper} elevation={3}>
                {isAddressLoading ? (
                  <Table sx={{ width: '100%' }} aria-label='cart table'>
                    <TableHead>
                      <TableRow sx={{ color: '#828f9b' }}>
                        <TableCell sx={{ color: '#828f9b' }}>First Name</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Last Name</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Mobile Number</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Email</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Country</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Primary Address</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    {Array.from(Array(5).keys()).map((_, index) => (
                      <TableRow>
                        <TableCell>
                          <Skeleton width={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={40} height={40} circle />
                        </TableCell>
                        <TableCell>
                          <Stack direction={'row'} display={'flex'} justifyContent={'space-evenly'}>
                            <Skeleton width={40} height={40} circle />
                            <Skeleton width={40} height={40} circle />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                ) : (
                  <Table sx={{ width: '100%' }} aria-label='cart table'>
                    <TableHead>
                      <TableRow sx={{ background: '#f4f6f8' }}>
                        <TableCell sx={{ color: '#828f9b' }}>First Name</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Last Name</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Mobile Number</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Email</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Country</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}>Primary Address</TableCell>
                        <TableCell sx={{ color: '#828f9b' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addresses && addresses.length > 0 ? (
                        addresses.map((item, i) => {
                          return (
                            <TableRow>
                              <TableCell>{item.first_name}</TableCell>
                              <TableCell>{item.last_name}</TableCell>
                              <TableCell>{item.mobile}</TableCell>
                              <TableCell>{item.email}</TableCell>
                              <TableCell>{item.country.name}</TableCell>
                              <TableCell>
                                {item?.is_active == 1 ? (
                                  <Avatar sx={{ background: '#3bb77e' }}>
                                    <CheckIcon />
                                  </Avatar>
                                ) : (
                                  <Avatar sx={{ background: '#dc3545' }}>
                                    <CloseIcon />
                                  </Avatar>
                                )}
                              </TableCell>
                              <TableCell>
                                <Stack display={'flex'} justifyContent={'space-evenly'} direction={'row'}>
                                  <Avatar
                                    sx={{ background: '#007bff' }}
                                    onClick={() => {
                                      dispatch(userAddressDetailApi({ isToken, id: item?.id })).then(() => {
                                        dispatch(countryDropdownApi({ isToken })).then(() => {
                                          dispatch(CustomerAddressTab('edit'));
                                        });
                                      });
                                    }}
                                  >
                                    <CreateIcon />
                                  </Avatar>
                                  <Avatar
                                    onClick={() => {
                                      showAlert(
                                        'warning',
                                        'Warning',
                                        'Are you sure you want to remove this address?',
                                        true,
                                        'YES'
                                      ).then(result => {
                                        if (result.isConfirmed) {
                                          dispatch(cartItemRemoveApi({ isToken, product_id: item?.product.id })).then();
                                        }
                                      });
                                    }}
                                  >
                                    <CloseIcon />
                                  </Avatar>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <motion.tr
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.5 }}
                          style={{ height: '300px', width: '100%' }}
                        >
                          <TableCell colSpan={8} sx={{ padding: 0 }}>
                            <Stack
                              display='flex'
                              direction='column'
                              justifyContent='center'
                              alignItems='center'
                              sx={{ height: '100%', width: '100%' }}
                            >
                              <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5 }}
                              >
                                <Avatar src={notFound} sx={{ width: '150px', height: '150px' }} />
                              </motion.div>
                              <Typography variant='h6' textAlign='center' sx={{ mt: 2 }}>
                              No address found. Please add a new address to proceed.
                              </Typography>
                            </Stack>
                          </TableCell>
                        </motion.tr>
                      )}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Grid>
          </Grid>
        </Stack>
      ) : (
        ''
      )}
      {isCustomerAddressTab && isCustomerAddressTab == 'create' ? <CreateAddress /> : ''}
      {isCustomerAddressTab && isCustomerAddressTab == 'edit' ? <EditAddress /> : ''}
    </Box>
  );
};

export default Address;
