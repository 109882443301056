import axios from 'axios';
import config from '../config';

const API_URL = config.API_URL;

const register = values => {
  const action = 'beforelogin/register';
  const formData = new FormData();
  Object.keys(values).forEach(key => {
    if (key === 'profile_image') {
      if (values[key]) {
        formData.append(key, values[key]);
      }
    } else {
      formData.append(key, values[key]);
    }
  });
  return axios.post(API_URL + action, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const login = values => {
  const action = 'beforelogin/login';
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  return axios.post(API_URL + action, formData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const forgotpassword = values => {
  const action = 'beforelogin/forgot-password';
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  return axios.post(API_URL + action, formData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const changepassword = values => {
  const action = 'afterlogin/password-change';
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  return axios.post(API_URL + action, formData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${values?.isToken}`
    }
  });
};

const contactus = values => {
  const action = 'afterlogin/contact-us';
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  return axios.post(API_URL + action, formData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${values?.isToken}`
    }
  });
};

const logout = values => {
  const token = values?.isToken;
  const action = 'afterlogin/logout';

  return axios.get(`${API_URL}${action}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const authCheck = values => {
  const token = values?.isToken;
  const action = 'afterlogin/authCheck';

  return axios.get(`${API_URL}${action}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const authApiController = {
  register,
  forgotpassword,
  contactus,
  login,
  logout,
  authCheck,
  changepassword
};

export default authApiController;
