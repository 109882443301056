import React, { useState, useEffect, useRef } from 'react';
import '../header/header.css';
import Logo from '../../assets/images/logo.svg';
import SearchIcon from '@mui/icons-material/Search';
import SelectCommon from '../selectDrop/select';
import axios from 'axios';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import IconCompare from '../../assets/images/icon-compare.svg';
import IconHeart from '../../assets/images/icon-heart.svg';
import IconCart from '../../assets/images/icon-cart.svg';
import IconUser from '../../assets/images/icon-user.svg';

import Button from '@mui/material/Button';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import Nav from './nav/nav';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { motion } from 'framer-motion';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { purgeStoredState } from 'redux-persist';
import { authLogOutApi } from '../../store/Slices/authSlice';
import { persistor } from '../../store/store';
import useWindowWidth from '../../hooks/window';
import {
  productDetailApi,
  productGetCompareListApi,
  productSearchApi,
  shopPageApi
} from '../../store/Slices/productSlice';
import { Puff } from 'react-loader-spinner';
import { ProfileTab, userAddressUpdateApi, userWishListApi } from '../../store/Slices/profileSlice';
import { activeAddressApi, cartListApi } from '../../store/Slices/cartSlice';
import { AddressSelectModal, AddressSelectModalClose, addressDropDownApi } from '../../store/Slices/categorySlice';
import PlaceIcon from '@mui/icons-material/Place';
import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography
} from '@mui/material';

const Header = props => {
  const width = useWindowWidth();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '',
    width: width > 768 ? 600 : width > 567 ? '80%' : '90%',
    boxShadow: 24,
    borderRadius: 4,
    p: 4
  };
  const [isOpenDropDown, setisOpenDropDown] = useState(false);
  const [isOpenAccDropDown, setisOpenAccDropDown] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isopenSearch, setOpenSearch] = useState(false);
  const [OpenSearchProduct, setOpenSearchProduct] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [CompareLoading, setIsCompareLoading] = useState(false);
  const [WishListLoading, setIsWishListLoading] = useState(false);
  const [CartListLoading, setIsCartListLoading] = useState(false);
  const [AddressUpdateLoading, setIsAddressUpdateLoading] = useState(false);
  const navigate = useNavigate();
  const headerRef = useRef();
  const searchInput = useRef();
  const user = useSelector(state => state.authuser.user);
  const isToken = useSelector(state => state.authuser.isToken);
  const isHeaderCategoryList = useSelector(state => state.category.isHeaderCategoryList);
  const isOpenAddressSelectModal = useSelector(state => state.category.isOpenAddressSelectModal);
  const isAddressList = useSelector(state => state.category.isAddressList);
  const isApiStatus = useSelector(state => state.category.isApiStatus);
  const addresses = isAddressList && isAddressList?.addresses?.length > 0 ? isAddressList?.addresses : [];
  const isCountableItem = useSelector(state => state.category.isCountableItem);
  const isCategoryName = useSelector(state => state.product.isCategoryName);
  const isProductSearch = useSelector(state => state.product.isProductSearch);
  const category =
    isHeaderCategoryList?.categories && isHeaderCategoryList?.categories?.length > 0
      ? isHeaderCategoryList?.categories
      : [];
  const ProductSearch =
    isProductSearch?.products && isProductSearch?.products?.length > 0 ? isProductSearch?.products : [];

  const history = useNavigate();
  const dispatch = useDispatch();

  const DropDownCategories = category && category.length > 0 ? category.map(item => item.title) : [];

  const [categories, setcategories] = useState(DropDownCategories);
  const [selectedAddress, setSelectedAddress] = useState('');

  useEffect(() => {
    const activeAddress = addresses.find(address => address.is_active === 1);
    if (activeAddress) {
      setSelectedAddress(activeAddress.id);
    }
  }, [addresses]);

  const handleChange = event => {
    setSelectedAddress(event.target.value);
  };

  useEffect(() => {
    dispatch(addressDropDownApi({ isToken }));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let position = window.pageYOffset;
      if (headerRef.current) {
        // Check if headerRef.current is not null
        if (position > 100) {
          headerRef.current.classList.add('fixed');
        } else {
          headerRef.current.classList.remove('fixed');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const signOut = () => {
    dispatch(authLogOutApi()).then(action => {
      persistor.purge();
    });
  };

  const openSearch = () => {
    setOpenSearch(true);
    if (searchInput && searchInput.current) {
      searchInput?.current?.focus();
    }
  };

  const closeSearch = () => {
    setOpenSearch(false);
    searchInput.current.blur();
    searchInput.current.value = '';
  };

  const openNav = () => {
    setIsOpenNav(true);
  };

  const closeNav = () => {
    setIsOpenNav(false);
    setisOpenAccDropDown(false);
  };

  const searchProduct = e => {
    setOpenSearchProduct(true);
    const keyword = e.target.value ? e.target.value : '';
    dispatch(
      productSearchApi({
        isToken: isToken,
        keyword: keyword,
        title: isCategoryName?.title == 'All Categories' ? '' : isCategoryName?.title
      })
    );
  };

  const menuItemVariants = {
    open: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
        duration: 0.3 // Animation duration for each menu item
      }
    },
    closed: {
      x: '100%',
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
        duration: 0.2 // Animation duration for each menu item
      }
    }
  };

  return (
    <>
      <div className='headerWrapper' ref={headerRef}>
        <div className='parent-head-wrappper'>
          <header>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-sm-2 part1 d-flex align-items-center'>
                  <Link to='/'>
                    <img src={Logo} className='logo' />
                  </Link>
                  {width < 992 && (
                    <div className='ml-auto d-flex align-items-center'>
                      <div className='navbarToggle mr-0' onClick={openSearch}>
                        <SearchIcon />
                      </div>
                      <ul className='list list-inline mb-0 headerTabs pl-0 mr-4'>
                        <li className='list-inline-item'>
                          <span>
                            <Link to={'/cart'}>
                              {' '}
                              <img src={IconCart} />
                              <span className='badge bg-success rounded-circle'>
                                {isCountableItem?.cartListProductCount ?? 0}
                              </span>
                            </Link>
                          </span>
                        </li>
                      </ul>
                      <div className='navbarToggle mr-2' onClick={openNav}>
                        <MenuIcon />
                      </div>
                      {user && (
                        <div className='' onClick={() => setisOpenAccDropDown(!isOpenAccDropDown)}>
                          <Avatar
                            component={Paper}
                            elevation={5}
                            src={`${user?.storage_url}/${user?.profile_image}`}
                            sx={{ width: '55px', height: '55px', background: '#d8f1e5' }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className='col-sm-5 part2'>
                  <div className={`headerSearch d-flex align-items-center ${isopenSearch === true ? 'open' : ''}`}>
                    {width < 992 && (
                      <div class='closeSearch' onClick={closeSearch}>
                        <ArrowBackIosIcon />
                      </div>
                    )}
                    <SelectCommon data={DropDownCategories} placeholder={'All Categories'} icon={false} />
                    <div className='position-relative d-flex align-items-center w-100'>
                      <div className='search w-100'>
                        <input type='text' placeholder='Search for items...' onChange={e => searchProduct(e)} />
                        <SearchIcon className='searchIcon cursor' />
                      </div>
                      {OpenSearchProduct && (
                        <div className='position-absolute dropdown-wrapper'>
                          <ul>
                            {ProductSearch && ProductSearch.length > 0 ? (
                              ProductSearch.map((item, i) => {
                                return (
                                  <li className='d-flex flex-wrap'>
                                    {
                                      <img
                                        src={`${item?.thumbnail_url}/${item?.thumbnail_image}`}
                                        style={{
                                          width: '40px',
                                          height: '40px',
                                          objectFit: 'cover',
                                          borderRadius: '50%',
                                          background: '#d8f1e5',
                                          border: '4px solid #FFF',
                                          outline: '1px solid #d9d9d9',
                                          marginRight: '2rem'
                                        }}
                                      />
                                    }
                                    <a
                                      href='javascript:void(0)'
                                      onClick={() => {
                                        dispatch(productDetailApi({ id: item?.id, isToken: isToken })).then(() => {
                                          setOpenSearchProduct(false);
                                          setIsOpenNav(false);
                                          setOpenSearch(false);
                                          navigate(`/product/${item?.id}`);
                                        });
                                      }}
                                    >
                                      <span>
                                        {item?.title.length > 32 ? item.title.substring(0, 29) + '...' : item.title}
                                      </span>
                                    </a>
                                  </li>
                                );
                              })
                            ) : (
                              <li>
                                <Button>
                                  <a href='javascript:void(0)'>No Data Found</a>
                                </Button>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/*headerSearch start here */}

                <div className='col-sm-5 d-flex align-items-center part3 res-hide'>
                  <div className='ml-auto d-flex align-items-center'>
                    {width > 1221 ? (
                      <div className='countryWrapper'>
                        <Button
                          variant='outlined'
                          startIcon={
                            isApiStatus && isApiStatus?.addressDropDownApi == 'loading' ? (
                              <CircularProgress size={25} color='inherit' />
                            ) : (
                              <PlaceIcon />
                            )
                          }
                          sx={{
                            color: '#3bb77e',
                            borderColor: '#3bb77e',
                            padding: '0.5rem 2rem',
                            '&:hover': {
                              borderColor: '#3bb77e',
                              color: '#3bb77e'
                            }
                          }}
                          onClick={() => {
                            dispatch(addressDropDownApi({ isToken })).then(action => {
                              dispatch(AddressSelectModal(true));
                            });
                          }}
                        >
                          Your Location
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                    <ClickAwayListener onClickAway={() => setisOpenDropDown(false)}>
                      <ul className='list list-inline mb-0 headerTabs'>
                        <li className='list-inline-item'>
                          <span
                            onClick={() => {
                              setIsCompareLoading(true);
                              dispatch(productGetCompareListApi({ isToken: isToken })).then(() => {
                                navigate('/compare');
                                setIsCompareLoading(false);
                              });
                            }}
                          >
                            {CompareLoading ? (
                              <div
                                class='spinner-border'
                                role='status'
                                style={{ width: '25px', height: '25px', marginRight: '4px' }}
                              >
                                <span class='visually-hidden'></span>
                              </div>
                            ) : (
                              <img src={IconCompare} />
                            )}
                            <span className='badge bg-success rounded-circle'>
                              {isCountableItem?.compareProductCount ?? 0}
                            </span>
                            Compare
                          </span>
                        </li>
                        <li className='list-inline-item'>
                          <span
                            onClick={() => {
                              setIsWishListLoading(true);
                              dispatch(userWishListApi({ isToken: isToken })).then(() => {
                                navigate('/profile');
                                setIsWishListLoading(false);
                              });
                            }}
                          >
                            <img src={IconHeart} />
                            <span className='badge bg-success rounded-circle'>
                              {isCountableItem?.wishListProductCount ?? 0}
                            </span>
                            Wishlist
                          </span>
                        </li>
                        <li className='list-inline-item'>
                          <span
                            onClick={() => {
                              setIsCartListLoading(true);
                              dispatch(cartListApi({ isToken: isToken })).then(() => {
                                navigate('/cart');
                                setIsCartListLoading(false);
                              });
                            }}
                          >
                            <a href='javascript:void(0)'>
                              <img src={IconCart} />
                              <span className='badge bg-success rounded-circle'>
                                {isCountableItem?.cartListProductCount ?? 0}
                              </span>
                              Cart
                            </a>
                          </span>
                        </li>

                        {user ? (
                          <li className='list-inline-item'>
                            <span onClick={() => {
                              setisOpenDropDown(!isOpenDropDown)
                              setisOpenAccDropDown(false)
                              }}>
                              <motion.img
                                src={`${user?.storage_url}/${user?.profile_image}`}
                                style={{
                                  width: '60px',
                                  height: '60px',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                  background: '#d8f1e5',
                                  border: '4px solid #FFF',
                                  outline: '1px solid #fda92d',
                                  boxShadow: isOpenDropDown
                                    ? '0px 4px 6px rgba(0, 0, 0, 0.1)'
                                    : '0px 4px 6px rgba(0, 0, 0, 0.1)' // Optional: Add a shadow when dropdown is open
                                }}
                                whileHover={{ scale: 1.1 }} // Optional: Scale up on hover
                                animate={{ outlineWidth: isOpenDropDown ? '4px' : '1px' }} // Animate outline width based on isOpenDropDown state
                              />
                            </span>

                            {isOpenDropDown && (
                              <motion.ul
                                className='dropdownMenu'
                                initial='closed'
                                animate='open'
                                variants={{ open: { opacity: 1 }, closed: { opacity: 0 } }}
                              >
                                <motion.li variants={menuItemVariants}>
                                  <Button
                                    className='align-items-center'
                                    onClick={() => {
                                      dispatch(ProfileTab(0));
                                      navigate('/profile');
                                    }}
                                  >
                                    <Person2OutlinedIcon /> My Account
                                  </Button>
                                </motion.li>
                                <motion.li variants={menuItemVariants}>
                                  <Button
                                    onClick={() => {
                                      dispatch(ProfileTab(1));
                                      navigate('/profile');
                                    }}
                                  >
                                    <LocationOnOutlinedIcon /> My Address
                                  </Button>
                                </motion.li>
                                <motion.li variants={menuItemVariants}>
                                  <Button
                                    onClick={() => {
                                      dispatch(ProfileTab(3));
                                      navigate('/profile');
                                    }}
                                  >
                                    <FavoriteBorderOutlinedIcon /> My Wishlist
                                  </Button>
                                </motion.li>
                                <motion.li variants={menuItemVariants}>
                                  <Button onClick={() => {
                                      dispatch(ProfileTab(4));
                                      navigate('/profile');
                                    }}>
                                    <SettingsOutlinedIcon /> Setting
                                  </Button>
                                </motion.li>
                                <motion.li variants={menuItemVariants}>
                                  <Button onClick={signOut}>
                                    <LogoutOutlinedIcon /> Sign out
                                  </Button>
                                </motion.li>
                              </motion.ul>
                            )}
                          </li>
                        ) : (
                          <li className='list-inline-item'>
                            <Link to={'/signIn'}>
                              <Button className='btn btn-g'>Sign In</Button>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </ClickAwayListener>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div
          className={`backdrop ${OpenSearchProduct ? 'show' : ''}`}
          onClick={() => setOpenSearchProduct(false)}
        ></div>
        <Nav data={props.data} openNav={isOpenNav} closeNav={closeNav} />
      </div>

      <div className='afterHeader'></div>

      {isOpenAccDropDown !== false && (
        <>
          <div className='navbarOverlay' onClick={closeNav}></div>
          <ul className='dropdownMenu dropdownMenuAcc' onClick={closeNav}>
            <li>
              <Button className='align-items-center'>
                <a
                  onClick={() => {
                    dispatch(ProfileTab(0));
                    navigate('/profile');
                  }}
                >
                  {' '}
                  <Person2OutlinedIcon /> My Account
                </a>
              </Button>
            </li>
            <li>
              <Button className='align-items-center'>
                <a
                  onClick={() => {
                    setIsCompareLoading(true);
                    dispatch(productGetCompareListApi({ isToken: isToken })).then(() => {
                      navigate('/compare');
                      setIsCompareLoading(false);
                    });
                  }}
                >
                  {' '}
                  {CompareLoading ? (
                    <div
                      class='spinner-border'
                      role='status'
                      style={{ width: '25px', height: '25px', marginRight: '4px' }}
                    >
                      <span class='visually-hidden'></span>
                    </div>
                  ) : (
                    <img src={IconCompare} />
                  )}
                  Compare
                </a>
              </Button>
            </li>
            <li>
              <Button className='align-items-center'>
                <a
                  onClick={() => {
                    setIsCartListLoading(true);
                    dispatch(cartListApi({ isToken: isToken })).then(() => {
                      navigate('/cart');
                      setIsCartListLoading(false);
                    });
                  }}
                >
                  {' '}
                  {CartListLoading ? (
                    <div
                      class='spinner-border'
                      role='status'
                      style={{ width: '25px', height: '25px', marginRight: '4px' }}
                    >
                      <span class='visually-hidden'></span>
                    </div>
                  ) : (
                    <img src={IconCart} />
                  )}
                  Cart
                </a>
              </Button>
            </li>
            <li>
              <Button>
                <a onClick={() => {
                    dispatch(ProfileTab(2));
                    navigate('/profile');
                  }}>
                  <LocationOnOutlinedIcon /> My Order
                </a>
              </Button>
            </li>
            <li>
              <Button>
                <a
                  onClick={() => {
                    setIsWishListLoading(true);
                    dispatch(userWishListApi({ isToken: isToken })).then(() => {
                      dispatch(ProfileTab(3));
                      navigate('/profile');
                      setIsWishListLoading(false);
                    });
                  }}
                >
                  {WishListLoading ? (
                    <div
                      class='spinner-border'
                      role='status'
                      style={{ width: '25px', height: '25px', marginRight: '4px' }}
                    >
                      <span class='visually-hidden'></span>
                    </div>
                  ) : (
                    <FavoriteBorderOutlinedIcon />
                  )}
                  My Wishlist
                </a>
              </Button>
            </li>
            <li>
              <Button>
                <a onClick={() => {
                    setIsWishListLoading(true);
                    dispatch(userWishListApi({ isToken: isToken })).then(() => {
                      dispatch(ProfileTab(4));
                      navigate('/profile');
                      setIsWishListLoading(false);
                    });
                  }}>
                  <SettingsOutlinedIcon /> Setting
                </a>
              </Button>
            </li>
            <li>
              <Button onClick={signOut}>
                <Link to=''>
                  <LogoutOutlinedIcon /> Sign out
                </Link>
              </Button>
            </li>
          </ul>
        </>
      )}
      <Modal
        open={isOpenAddressSelectModal}
        onClose={() => dispatch(AddressSelectModal(false))}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Your Address
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2, mb: 4 }}>
            Please select the address you want to make active.
          </Typography>
          <FormControl fullWidth sx={{ marginBottom: 4 }}>
            <InputLabel id='address-select-label'>Select Address</InputLabel>
            <Select
              labelId='address-select-label'
              id='address-select'
              value={selectedAddress}
              label='Select Address'
              onChange={handleChange}
            >
              {addresses.length > 0 ? (
                addresses.map(address => (
                  <MenuItem key={address.id} value={address.id}>
                    {`${address.first_name} ${address.last_name}, ${address.address}, ${address.city}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ cursor: 'default' }}>
                  You don't have any addresses.
                  <Link
                    to='/profile'
                    style={{
                      textDecoration: 'none',
                      color: '#3bb77e',
                      cursor: 'pointer',
                      marginLeft: 3
                    }}
                    onClick={() => {
                      dispatch(ProfileTab(1));
                      dispatch(AddressSelectModal(false));
                    }}
                  >
                    Create new one
                  </Link>
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <Box display={'flex'} justifyContent={'end'}>
            <Button
              variant='outlined'
              sx={{
                background: '#1C252E',
                color: '#FFF',
                borderColor: '#1C252E',
                marginRight: 2,
                '&:hover': {
                  borderColor: '#1C252E',
                  color: '#1C252E'
                }
              }}
              onClick={() => dispatch(AddressSelectModal(false))}
            >
              Cancel
            </Button>
            <Button
              variant='outlined'
              sx={{
                background: '#3bb77e',
                color: '#FFF',
                borderColor: '#3bb77e',
                marginRight: 2,
                '&:hover': {
                  borderColor: '#3bb77e',
                  color: '#3bb77e'
                }
              }}
              startIcon={AddressUpdateLoading ? <CircularProgress size={20} color='inherit' /> : null}
              onClick={() => {
                setIsAddressUpdateLoading(true);
                dispatch(userAddressUpdateApi({ isToken, address_id: selectedAddress, address_selection: true })).then(
                  action => {
                    dispatch(activeAddressApi({ isToken })).then(() => {
                      dispatch(AddressSelectModal(false));
                      setIsAddressUpdateLoading(false);
                    });
                  }
                );
              }}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
