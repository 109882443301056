import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Button, Grid, Typography, IconButton, InputAdornment, Alert, AlertTitle } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { authLoginApi, forgotPasswordApi } from '../../store/Slices/authSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isApiStatus = useSelector((state)=>state.authuser.isApiStatus);
  const isLoading = isApiStatus && isApiStatus?.forgotPasswordApi == 'loading';
 

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      dispatch(forgotPasswordApi(values)).then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          toast.success('Please check your mail we have send instruction to reset password');
        } else if (action.meta.requestStatus === 'rejected') {
          const errors = action?.payload?.errors ?? {};
          const message = action?.payload?.message ?? '';
          const status = action?.payload?.status ?? '';
          if (status === 422) {
            const formErrors = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0];
              return acc;
            }, {});
            setErrors(formErrors);
          } else if (status === 410) {
            toast.error(message);
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <div className='breadcrumbWrapper'>
        <div className='container-fluid'>
          <ul className='breadcrumb breadcrumb2 mb-0'>
            <li><Link to='/signIn'>Sign In</Link></li>
            <li><Link to='/signUp'>Sign Up</Link></li>
            <li>Forgot Password</li>
          </ul>
        </div>
      </div>
      <Grid container spacing={3} justifyContent='center' alignItems='center' sx={{ minHeight: '100vh' }}>
        <Grid item xs={11} md={6} lg={4} xl={3}>
          <Box sx={{ padding: 5, borderRadius: 2, boxShadow: 3, backgroundColor: 'background.paper' }}>
            <Typography variant='h5' align='center' gutterBottom>
              Forgot Password
            </Typography>
            <Typography variant='h6' align='center' gutterBottom>
            Enter your email and we'll send you instructions to reset your password
            </Typography>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <Box mb={2}>
                    <Field
                      as={TextField}
                      name='email'
                      label='Email'
                      type='email'
                      fullWidth
                      variant='outlined'
                      error={touched.email && Boolean(errors.email)}
                      helperText={<ErrorMessage name='email' />}
                    />
                  </Box>
                  <Button
                    type='submit'
                    variant='outlined'
                    sx={{
                      background: '#3bb77e',
                      color: '#FFF',
                      borderColor: '#3bb77e',
                      marginRight: 2,
                      '&:hover': {
                        borderColor: '#3bb77e',
                        color: '#3bb77e'
                      }
                    }}
                    disabled={isSubmitting}
                    fullWidth
                  >
                    {isLoading && <CircularProgress color='inherit' size={20} sx={{ marginRight:1 }}/>}submit
                  </Button>
                  <Box mt={2} textAlign='center'>
                    <Typography variant='body2'>
                      Already have an account?
                      <Link to='/signIn' style={{ marginLeft: 4 }}>
                        Sign In
                      </Link>
                    </Typography>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
