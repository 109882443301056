import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useWindowWidth from '../../hooks/window';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { CheckOutData, RemoveCouponCode, orderCreateApi, shippingChargeGetApi } from '../../store/Slices/cartSlice';
import { OpenThankYouModal, countryDropdownApi } from '../../store/Slices/profileSlice';
import ThankYouModel from './ThankYouModel';
import { countableItemApi } from '../../store/Slices/categorySlice';

const StyledSelect = styled('select')({
  font: 'inherit',
  letterSpacing: 'inherit',
  color: 'currentColor',
  padding: '16.5px 0px 16.5px 0px',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  boxSizing: 'content-box',
  background: 'none',
  height: 'auto',
  margin: '0',
  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  minWidth: '0',
  width: '100%',
  WebkitAnimationName: 'mui-auto-fill-cancel',
  animationName: 'mui-auto-fill-cancel',
  WebkitAnimationDuration: '10ms',
  animationDuration: '10ms',
  '&:focus': {
    borderColor: '#3f51b5',
    outline: 'none',
    boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)'
  }
});

const Checkout = () => {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const [isActive, setIsActive] = useState(0);
  const isToken = useSelector(state => state.authuser.isToken);
  const isOpenThankYouModal = useSelector((state)=>state.profile.isOpenThankYouModal);
  const isCountryDropdown = useSelector(state => state.profile.isCountryDropdown);
  const isCheckOutData = useSelector(state => state.cart.isCheckOutData);
  const isActiveAddressData = useSelector(state => state.cart.isActiveAddressData);
  const isApiStatus = useSelector(state => state.cart.isApiStatus);
  const isCheckOutLoading = isApiStatus && isApiStatus?.orderCreateApi == 'loading';
  const address = isActiveAddressData && isActiveAddressData.address ? isActiveAddressData.address : '';
  const isShippingChargeData = useSelector(state => state.cart.isShippingChargeData);
  const charges = isShippingChargeData && isShippingChargeData?.charges ? isShippingChargeData?.charges : '';
  const countries = isCountryDropdown && isCountryDropdown?.countries?.length > 0 ? isCountryDropdown?.countries : [];

  useEffect(() => {
    if (countries && countries?.length <= 0 && isToken) {
      dispatch(countryDropdownApi({ isToken }));
    }
  }, [isToken, countries]);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobile: Yup.string().required('Mobile is required'),
    address: Yup.string().required('Address is required'),
    apartment: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string().required('ZIP is required'),
    country_id: Yup.number().required('Country is required'),
    notes: Yup.string().optional()
  });

  const initialValues = useMemo(
    () => ({
      cardNumber: isCheckOutData?.cardNumber || '',
      country_id: isCheckOutData?.country_id || '',
      cvv: isCheckOutData?.cvv || '',
      coupon_id: isCheckOutData?.coupon_id || '',
      discountAmount: isCheckOutData?.discountAmount || 0,
      discountCoupon: isCheckOutData?.discountCoupon || '',
      expiryDate: isCheckOutData?.expiryDate || '',
      grand_total: isCheckOutData?.grand_total || '',
      product: isCheckOutData?.product || [],
      shippingCharge: isCheckOutData?.shippingCharge 
      ? isCheckOutData?.shippingCharge 
      : (charges ? parseFloat(charges?.amount) : 0),
      total: isCheckOutData?.total || 0,
      address_id: address?.id || '',
      first_name: address?.first_name || '',
      last_name: address?.last_name || '',
      email: address?.email || '',
      mobile: address?.mobile || '',
      address: address?.address || '',
      apartment: address?.apartment || '',
      city: address?.city || '',
      state: address?.state || '',
      zip: address?.zip || '',
      country_id: address?.country_id || '',
      notes: ''
    }),
    [address, isCheckOutData,charges]
  );

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      dispatch(orderCreateApi({ ...values, isToken })).then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          dispatch(countableItemApi({ isToken })).then(()=>{
            dispatch(RemoveCouponCode());
            dispatch(CheckOutData(''));
            dispatch(OpenThankYouModal(true));
          })
        } else if (action.meta.requestStatus == 'rejected') {
          const errors = action?.payload?.errors ?? {};
          const message = action?.payload?.message ?? '';
          const status = action?.payload?.status ?? '';
          if (status == 422) {
            const formErrors = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0];
              return acc;
            }, {});
            setErrors(formErrors);
          } else if (status == 410) {
            toast.error(message);
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ margin: width > 992 ? '270px 45px 40px 45px' : '120px 45px 20px 45px' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, values, setFieldValue }) => {

          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Paper elevation={5} sx={{ marginBottom: 10 }}>
                    <Card variant='outlined' sx={{ marginBottom: 10, padding: '3rem' }}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name='first_name'
                            as={TextField}
                            label='First Name'
                            error={touched.first_name && !!errors.first_name}
                            helperText={touched.first_name && errors.first_name}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name='last_name'
                            as={TextField}
                            label='Last Name'
                            error={touched.last_name && !!errors.last_name}
                            helperText={touched.last_name && errors.last_name}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name='email'
                            type='email'
                            as={TextField}
                            label='Email'
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name='mobile'
                            as={TextField}
                            label='Mobile'
                            error={touched.mobile && !!errors.mobile}
                            helperText={touched.mobile && errors.mobile}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name='address'
                            as={TextField}
                            label='Address'
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name='apartment'
                            as={TextField}
                            label='Apartment'
                            error={touched.apartment && !!errors.apartment}
                            helperText={touched.apartment && errors.apartment}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name='city'
                            as={TextField}
                            label='City'
                            error={touched.city && !!errors.city}
                            helperText={touched.city && errors.city}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name='state'
                            as={TextField}
                            label='State'
                            error={touched.state && !!errors.state}
                            helperText={touched.state && errors.state}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name='zip'
                            as={TextField}
                            label='ZIP'
                            error={touched.zip && !!errors.zip}
                            helperText={touched.zip && errors.zip}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name='notes'
                            label='Notes'
                            multiline
                            rows={4}
                            fullWidth
                            variant='outlined'
                            error={touched.notes && Boolean(errors.notes)}
                            helperText={touched.notes && errors.notes}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledSelect
                            name='country_id'
                            value={values.country_id}
                            onChange={e => {
                              const countryId = e?.target?.value ? e.target.value : '';
                              dispatch(shippingChargeGetApi({ isToken,country_id:countryId })).then(()=>{
                                setFieldValue('country_id', countryId)
                              });
                            }}
                            className={touched.country_id && errors.country_id ? 'error' : ''}
                          >
                            <option value='' disabled>
                              Select a country
                            </option>
                            {countries.map(country => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </StyledSelect>
                          {touched.country_id && errors.country_id && (
                            <Typography variant='caption' color='error'>
                              {errors.country_id}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper elevation={5} sx={{ marginBottom: 3 }}>
                    <List>
                      {isCheckOutData && isCheckOutData.product && isCheckOutData.product.length > 0 ? (
                        isCheckOutData.product.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar
                                    src={item?.product?.thumbnail_url}
                                    sx={{
                                      width: '60px',
                                      height: '60px',
                                      objectFit: 'cover',
                                      background: '#22C55E',
                                      borderRadius: 4
                                    }}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    item?.product?.title.length > 34
                                      ? `${item?.product?.title.substring(0, 34)}...`
                                      : item?.product?.title
                                  }
                                  secondary={`X ${item?.quantity}`}
                                />
                                <ListItemText
                                  sx={{ textAlign: 'end' }}
                                  primary={`$${item?.product?.price}`}
                                  secondary={`$${item?.product?.price * item?.quantity}`}
                                />
                              </ListItem>
                              {index !== isCheckOutData.product.length - 1 && <Divider component='li' />}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <ListItem>
                          <ListItemText primary='No items found' />
                        </ListItem>
                      )}
                    </List>
                  </Paper>
                  <Paper elevation={5} sx={{ marginBottom: 3 }}>
                    <List>
                      <ListItem>
                        <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                          <ListItemText primary='Total :' />
                          <ListItemText primary={`$${isCheckOutData?.total ?? 0}`} sx={{ textAlign: 'end' }} />
                        </Stack>
                      </ListItem>
                      <Divider component='li' />
                      <ListItem>
                        <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                          <ListItemText primary='Total Product :' />
                          <ListItemText
                            primary={isCheckOutData?.product?.length > 0 ? isCheckOutData?.product?.length : 0}
                            sx={{ textAlign: 'end' }}
                          />
                        </Stack>
                      </ListItem>
                      <Divider component='li' />
                      <ListItem>
                        <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                          <ListItemText primary='Discount :' />
                          <ListItemText primary={`- $${isCheckOutData?.discountAmount ?? 0}`} sx={{ textAlign: 'end',color:'#3bb77e' }} />
                        </Stack>
                      </ListItem>
                      <Divider component='li' />
                      <ListItem>
                        <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                          <ListItemText primary='Shipping Charge :' />
                          <ListItemText primary={`+ $${values?.shippingCharge ?? 0}`} sx={{ textAlign: 'end',color:'#d91515' }} />
                        </Stack>
                      </ListItem>
                      <Divider component='li' />
                      <ListItem>
                        <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                          <ListItemText primary='Total Price :' />
                          <ListItemText
                            primary={`$${isCheckOutData?.grand_total?.toFixed(2) ?? 0}`}
                            sx={{ textAlign: 'end' }}
                          />
                        </Stack>
                      </ListItem>
                    </List>
                  </Paper>
                  <Button
                    variant='outlined'
                    sx={{
                      background: '#1C252E',
                      color: '#FFF',
                      padding: '1rem 3rem',
                      borderColor: '#1C252E',
                      '&:hover': {
                        borderColor: '#1C252E',
                        color: '#1C252E'
                      }
                    }}
                    type='submit'
                    fullWidth
                  >
                    {isCheckOutLoading && <CircularProgress color='inherit' size={20} sx={{ marginRight:1 }}/>}Confirm Order
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {isOpenThankYouModal && <ThankYouModel/>}
    </Box>
  );
};

export default Checkout;
