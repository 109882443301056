import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowWidth from '../../hooks/window';
import { privacyPolicyApi } from '../../store/Slices/categorySlice';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const isToken = useSelector(state => state.authuser.isToken);
  const isPrivacyPolicyData = useSelector(state => state.category.isPrivacyPolicyData);
  const policy = isPrivacyPolicyData && isPrivacyPolicyData?.policy ? isPrivacyPolicyData?.policy : '';
  const isApiStatus = useSelector(state => state.category.isApiStatus);
  const isLoading = isApiStatus && isApiStatus?.privacyPolicyApi === 'loading';

  useEffect(() => {
    if (isToken) {
      dispatch(privacyPolicyApi({ isToken }));
    }
  }, [isToken, dispatch]);

  return (
    <Box sx={{ margin: width > 992 ? '270px 45px 40px 45px' : '120px 45px 40px 45px' }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <ListItem alignItems='flex-start'>
          <ListItemAvatar>
            {isLoading ? (
              <Skeleton />
            ) : (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                <Avatar
                  alt={policy?.title ?? ''}
                  src={policy?.policy_url ?? ''}
                  sx={{
                    width: '100%',
                    height: width > 943 ? '720px' : width < 438 ? '210px' : '310px',
                    borderRadius: 0,
                    objectFit: 'cover'
                  }}
                />
              </motion.div>
            )}
          </ListItemAvatar>
        </ListItem>
        <ListItem alignItems='flex-start'>
          <ListItemText
            primary={
              isLoading ? (
                <Skeleton width='60%' count={10} />
              ) : (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                  <Typography variant='h6' component='h1'>
                    {policy?.title ?? ''}
                  </Typography>
                </motion.div>
              )
            }
          />
        </ListItem>
        <ListItem alignItems='flex-start'>
          <ListItemText
            primary={
              isLoading ? (
                <Skeleton width='40%' count={10} />
              ) : (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                  <Typography
                    variant='body1'
                    component='p'
                    dangerouslySetInnerHTML={{ __html: policy?.content ?? '<p></p>' }}
                  />
                </motion.div>
              )
            }
            secondary={
              isLoading ? (
                <Skeleton count={10} />
              ) : (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                  <Typography variant='body2' color='text.secondary'>
                    Last Updated At {policy?.last_updated_at ?? ''}
                  </Typography>
                </motion.div>
              )
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default PrivacyPolicy;
