import axios from 'axios';
import config from '../../config.js';
import { useSelector } from 'react-redux';
import React from 'react';

const API_URL = config.API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const useAxiosInstance = () => {
  const token = useSelector(state => state.authuser.isToken);

  React.useEffect(() => {
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  }, [token]);

  return axiosInstance;
};

export default useAxiosInstance;
