import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
  Dialog
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import ReactToPrint from 'react-to-print';
import { OpenBlogDetailModal } from '../../store/Slices/categorySlice';
import useWindowWidth from '../../hooks/window';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const BlogDetail = () => {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const isOpenBlogDetailModal = useSelector(state => state.category.isOpenBlogDetailModal);
  const isBlogDetailData = useSelector(state => state.category.isBlogDetailData);
  
  const handleClose = () => {
    dispatch(OpenBlogDetailModal(false));
  };

  if (!isBlogDetailData?.status) return null;

  const { title, content, blogs_url, first_name, last_name, sort_description } = isBlogDetailData.blog;

  return (
    <Dialog
      fullScreen
      open={isOpenBlogDetailModal}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ background: '#FFF', color: '#000', padding: '0px' }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
             {title ? title.length > (width <= 992 ? 25 : 50) ? `${title.substring(0, width <= 992 ? 25 : 50)}...` : title : 'Blog Detail' }
          </Typography>
          <Button
            variant='outlined'
            sx={{
              background: '#1C252E',
              color: '#FFF',
              borderColor: '#1C252E',
              '&:hover': {
                borderColor: '#1C252E',
                color: '#1C252E'
              }
            }}
            startIcon={<CloseIcon />}
            onClick={handleClose}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Box id="blog-detail-content" sx={{ padding: '20px', overflowY: 'auto', marginTop: '70px' }}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt={title ?? ''}
                src={blogs_url ?? ''}
                sx={{ width: '100%', height: '320px', borderRadius: 0, objectFit: 'cover' }}
              />
            </ListItemAvatar>
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <Typography variant="h4" color="text.primary">
                  Author: {`${first_name ?? ''} ${last_name ?? ''}`}
                </Typography>
              }
              secondary={
                <Typography variant="h6" component="h1">
                  {title ?? ''}
                </Typography>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <Typography variant="body1" component="p" dangerouslySetInnerHTML={{ __html: content ?? '<p></p>' }} />
              }
              secondary={
                <Typography variant="body2" color="text.secondary">
                  {sort_description ?? ''}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Dialog>
  );
};

export default BlogDetail;
