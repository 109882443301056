import React, { useState } from 'react';
import { Grid, Card, Paper, Avatar, Typography, CardContent, CardActions, IconButton, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { useDispatch } from 'react-redux';
import { OpenBlogDetailModal, blogDetailApi } from '../../store/Slices/categorySlice';

const BlogGridView = ({ isBlogData,isToken }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState({});
  return (
    <Grid container spacing={2}>
      {isBlogData?.blogs?.data?.map((blog,index) => (
        <Grid item xs={12} md={6} lg={4} xl={3} key={blog.id}>
          <motion.div whileHover={{ scale: 1.01,boxShadow:'0px 4px 4px 4px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' }}>
            <Card component={Paper} elevation={3} sx={{ padding: 2 }}>
              <CardContent>
                <Avatar
                  variant='square'
                  src={`${blog.blogs_url}`}
                  alt={blog.title}
                  sx={{ width: '100%', height: 400, objectFit: 'contain', mb: 2 }}
                />
                <Typography
                  variant='h6'
                  component='h2'
                  gutterBottom
                  noWrap
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Author: {`${blog.first_name} ${blog.last_name}`}
                </Typography>
                <Typography
                  variant='body1'
                  component='h2'
                  gutterBottom
                  noWrap
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {blog.title}
                </Typography>
                <Typography
                  variant='body2'
                  component='p'
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {blog.sort_description}
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label='add to favorites'>
                  <FavoriteIcon sx={{ color:'#E32020' }}/>
                </IconButton>
                <IconButton aria-label='share'>
                  <ShareIcon  sx={{ color:'#0085FF' }}/>
                </IconButton>
                <IconButton aria-label='view' sx={{ marginLeft:'auto' }} onClick={()=>{
                  setLoader((prevLoader) => ({ ...prevLoader, [index]: true }));
                  dispatch(blogDetailApi({isToken,id:blog?.id})).then(()=>{
                    setLoader((prevLoader) => ({ ...prevLoader, [index]: false }));
                    dispatch(OpenBlogDetailModal(true));
                  })
                }}>
                  {loader[index] ? (
                      <CircularProgress size={24} color='warning'/>
                  ) : (
                    <RemoveRedEyeIcon color='secondary'/>
                  )}
                </IconButton>
              </CardActions>
            </Card>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
};

export default BlogGridView;
