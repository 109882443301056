import React, { useEffect } from 'react';
import Slider from 'react-slick';
import useWindowWidth from '../../hooks/window';
import './ProductCompare.css'; // Ensure to create this CSS file to handle the styles
import { useDispatch, useSelector } from 'react-redux';
import {
  RemoveAllProductFromCompare,
  RemoveProductFromCompare,
  productGetCompareListApi,
  productRemoveFromCompareApi
} from '../../store/Slices/productSlice';
import useSweetAlert from '../../hooks/sweetalert';
import { countableItemApi } from '../../store/Slices/categorySlice';
import { motion } from 'framer-motion';
import emptyCart from '../../assets/images/emptyCart.png';
import { Link } from 'react-router-dom';

const ProductCompare = () => {
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const isToken = useSelector(state => state.authuser.isToken);
  const isComparedProduct = useSelector(state => state.product.isComparedProduct);
  const { showAlert } = useSweetAlert();
  const CompareProducts =
    isComparedProduct && isComparedProduct?.products && isComparedProduct?.products?.length > 0
      ? isComparedProduct?.products
      : [];
  const related = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    arrows: width > 992 ? true : false
  };

  useEffect(() => {
    dispatch(productGetCompareListApi({ isToken }));
  }, []);

  return (
    <div className='container-fluid'>
      <div className='relatedProducts homeProductsRow2 pt-5 pb-4'>
        <div className='d-flex justify-content-between'>
          <h2 className='hd mb-0 mt-0'>Compare products</h2>
          <button
            className='btn btn-danger btn-sm'
            onClick={() => {
              showAlert(
                'warning',
                'Warning',
                'Are you sure want to remove all Product from Comparison',
                true,
                'YES'
              ).then(result => {
                if (result.isConfirmed) {
                  dispatch(productRemoveFromCompareApi({ isToken: isToken, remove_all: 1 })).then(action => {
                    if (action?.meta?.requestStatus == 'fulfilled') {
                      dispatch(RemoveAllProductFromCompare());
                      dispatch(countableItemApi({ isToken })).then(() => {
                        showAlert('success', 'Success', 'Product removed from Comparison', false, 'OK');
                      });
                    }
                  });
                }
              });
            }}
          >
            Remove All
          </button>
        </div>
        <br className='res-hide' />
        {CompareProducts && CompareProducts.length > 0 ? (
          <div className='product-compare-container'>
            <div className='fixed-header'>
              <table className='table'>
                <thead>
                  <tr style={{ height: '117.4px' }}>
                    <th>Image</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Title</td>
                  </tr>
                  <tr>
                    <td>Price</td>
                  </tr>
                  <tr>
                    <td>Old Price</td>
                  </tr>
                  <tr>
                    <td>Available Sizes</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                  </tr>
                  <tr>
                    <td>Brand</td>
                  </tr>
                  <tr>
                    <td>Sub-Category</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='scrollable-content'>
              <Slider {...related} className='prodSlider'>
                {CompareProducts &&
                  CompareProducts.length > 0 &&
                  CompareProducts.map((item, index) => {
                    const product = item?.product;
                    return (
                      <div className='item table-wrapper position-relative' key={index}>
                        <div className='position-absolute' style={{ top: '4px', right: '40px' }}>
                          <i
                            className='fa fa-times cursor-pointer'
                            onClick={() => {
                              dispatch(productRemoveFromCompareApi({ isToken, product_id: product?.id })).then(
                                action => {
                                  if (action?.meta?.requestStatus == 'fulfilled') {
                                    dispatch(countableItemApi({ isToken })).then(() => {
                                      showAlert('success', 'Success', 'Product removed from Comparison', false, 'OK');
                                      dispatch(RemoveProductFromCompare({ id: product?.id }));
                                    });
                                  }
                                }
                              );
                            }}
                          ></i>
                        </div>
                        <table className='table table-striped'>
                          <thead>
                            <tr>
                              <th>
                                <img
                                  src={product?.thumbnail_url}
                                  style={{ height: '100px', width: '100px', objectFit: 'cover' }}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {product?.title?.length > 29 ? `${product.title.slice(0, 29)}...` : product.title}
                              </td>
                            </tr>
                            <tr>
                              <td>{product.price}</td>
                            </tr>
                            <tr>
                              <td>{product.old_price}</td>
                            </tr>
                            <tr>
                              <td>
                                {product?.weights?.length > 0 ? product?.weights?.map(item => item.size + ', ') : ''}
                              </td>
                            </tr>
                            <tr>
                              <td>{product?.category?.title}</td>
                            </tr>
                            <tr>
                              <td>{product?.brand?.title}</td>
                            </tr>
                            <tr>
                              <td>{product?.sub_category?.title}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        ) : (
          <motion.div
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '20px',
              paddingTop: '20px'
            }}
          >
            <div className='card' style={{ maxWidth: '500px', textAlign: 'center' }}>
              <div className='d-flex justify-content-center'>
              <img
                style={{
                  width: '150px',
                  borderRadius: '8px',
                  marginBottom: '16px',
                  marginTop: '16px'
                }}
                src={emptyCart}
                className='card-img-top'
                alt='emptyCart'
              />
              </div>
              <div className='card-body'>
                <h1
                  style={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    marginBottom: '8px'
                  }}
                  className='card-title'
                >
                  Your Comparison Product looks lonely.
                </h1>
                <p
                  style={{
                    fontSize: '0.875rem',
                    color: '#555',
                    marginBottom: '16px'
                  }}
                  className='card-text'
                >
                  Your have no product to compare. Give it purpose - fill it with books, electronics, videos, etc. and
                  make it happy.
                </p>
                <Link to='/'>
                  <button
                    style={{
                      backgroundColor: '#007bff',
                      borderRadius: '4px',
                      padding: '8px 16px',
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '1rem',
                      color: '#fff',
                      border: 'none',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s'
                    }}
                    className='btn btn-primary'
                    onMouseEnter={e => {
                      e.target.style.backgroundColor = '#0056b3';
                    }}
                    onMouseLeave={e => {
                      e.target.style.backgroundColor = '#007bff';
                    }}
                  >
                    Home
                  </button>
                </Link>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ProductCompare;
