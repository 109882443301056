import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import Slider from 'react-slick';
import { useRef } from 'react';
import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import moment from 'moment';
import Product from '../../components/product';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { productAddCompareApi, productDetailApi } from '../../store/Slices/productSlice';
import useWindowWidth from '../../hooks/window';
import ProductRating from './ProductRating';
import { cartStoreApi } from '../../store/Slices/cartSlice';
import { countableItemApi } from '../../store/Slices/categorySlice';
import useSweetAlert from '../../hooks/sweetalert';
import { addProductToWishListApi } from '../../store/Slices/profileSlice';

const DetailsPage = props => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const isProductDetail = useSelector(state => state.product.isProductDetail);
  const product = isProductDetail && isProductDetail?.product ? isProductDetail?.product : '';
  const isToken = useSelector(state => state.authuser.isToken);
  const isApiStatus = useSelector(state => state.product.isApiStatus);
  const { showAlert } = useSweetAlert();
  const [mainImage, setMainImage] = useState(`${product?.thumbnail_url}/${product?.thumbnail_image}`);
  const [bigImageSize, setBigImageSize] = useState([1500, 1500]);
  const [smlImageSize, setSmlImageSize] = useState([150, 150]);
  const [activeSize, setActiveSize] = useState(product?.weights?.length > 0 ? product?.weights[0]?.id : 0);
  const [inputValue, setinputValue] = useState(1);
  const [activeTabs, setActiveTabs] = useState(0);
  const zoomSliderBig = useRef();
  const zoomSlider = useRef();

  var settings2 = {
    dots: false,
    infinite: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    arrows: false
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    fade: false,
    arrows: width > 992 ? true : false
  };

  var related = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    fade: false,
    arrows: width > 992 ? true : false
  };

  const goto = index => {
    zoomSlider.current.slickGoTo(index);
    zoomSliderBig.current.slickGoTo(index);
    setMainImage(`${product?.images[index]?.storage_url}/${product?.images[index]?.image}?im=Resize=(${bigImageSize[0]},${bigImageSize[1]})`);
  };

  const isActive = (id) => {
    setActiveSize(id);
  };

  const plus = () => {
    setinputValue(inputValue + 1);
  };

  const minus = () => {
    if (inputValue !== 1) {
      setinputValue(inputValue - 1);
    }
  };

  useEffect(() => {
    if (!product || product?.id !== id) {
      dispatch(productDetailApi({ id: id, isToken: isToken }));
    }else{
      setMainImage(`${product?.thumbnail_url}/${product?.thumbnail_image}?im=Resize=(${bigImageSize[0]},${bigImageSize[1]})`);
    }
  }, []);

  

  return (
    <>
      {width < 992 && (
        <Button className={`btn-g btn-lg w-100 filterBtn `} onClick={()=>{
          dispatch(cartStoreApi({ isToken, product_id: product?.id, quantity:1 })).then(action => {
            dispatch(countableItemApi({ isToken }))
            if (action?.meta?.requestStatus === 'fulfilled') {
              showAlert(
                'success',
                'Success',
                'Product successfully added to cart',
                false,
                'OK'
              );
            }
          });
        }}>
          <ShoppingCartOutlinedIcon />
          Add To Cart
        </Button>
      )}

      <section className='detailsPage mb-5'>
        {width > 992 && (
          <div className='breadcrumbWrapper mb-4'>
            <div className='container-fluid'>
              <ul className='breadcrumb breadcrumb2 mb-0'>
                <li>
                  <Link>Home</Link>{' '}
                </li>
                <li>
                  <Link className='text-capitalize'>{product?.category?.title}</Link>{' '}
                </li>

                <li>
                  <Link
                    to={`/cat/${product?.category?.title.toLowerCase()}/${product?.category?.title
                      .replace(/\s/g, '-')
                      .toLowerCase()}`}
                    onClick={() => {}}
                    className='text-capitalize'
                  >
                    {product?.category?.title}
                  </Link>
                </li>
                <li>{product?.title}</li>
              </ul>
            </div>
          </div>
        )}

        <div className='container detailsContainer pt-3 pb-3'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='productZoom'>
                <Slider {...settings2} className='zoomSliderBig' ref={zoomSliderBig}>
                  <div className='item'>
                    <InnerImageZoom
                      zoomType='hover'
                      zoomScale={1}
                      src={`${mainImage}?im=Resize=(${bigImageSize[0]},${bigImageSize[1]})`}
                    />
                  </div>
                </Slider>
              </div>

              <Slider {...settings} className='zoomSlider' ref={zoomSlider}>
                {product?.images?.length > 0 &&
                  product?.images?.map((item, index) => {
                    return (
                      <div className='item'>
                        <img
                          src={`${item?.storage_url}/${item?.image}?im=Resize=(${smlImageSize[0]},${smlImageSize[1]})`}
                          className='w-100'
                          onClick={() => goto(index)}
                        />
                      </div>
                    );
                  })}
              </Slider>
            </div>
            <div className='col-md-7 productInfo'>
              <h1>{product?.title}</h1>
              <div className='d-flex align-items-center mb-4 mt-3'>
                <Rating name='half-rating-read' value={parseFloat(3)} precision={0.5} readOnly />
                <span className='text-light ml-2'>(32 reviews)</span>
              </div>

              <div className='priceSec d-flex align-items-center mb-3'>
                <span className='text-g priceLarge'>Rs {product?.price}</span>
                <div className='ml-3 d-flex flex-column'>
                  <span className='text-org'>
                    {product.product_discount}
                    {product?.product_discount_in == '%' ? '%' : '$'} Off
                  </span>
                  <span className='text-light oldPrice'>Rs {product?.old_price}</span>
                </div>
              </div>

              <p>{product?.description}</p>

              {product?.weights !== undefined && product?.weights?.length !== 0 && (
                <div className='productSize d-flex align-items-center'>
                  <span>Size / Weight:</span>
                  <ul className='list list-inline mb-0 pl-4'>
                    {product?.weights.map((item, index) => {
                      return (
                        <li className='list-inline-item'>
                          <a className={`tag ${activeSize === item?.id ? 'active' : ''}`} onClick={() => isActive(item?.id)}>
                            {item?.size}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              <div className='d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                  {width > 992 && (
                    <Button
                      className={`btn-g btn-lg addtocartbtn`}
                      onClick={() => {
                        dispatch(cartStoreApi({ isToken, product_id: product?.id, quantity:1,size_id:activeSize })).then(action => {
                          dispatch(countableItemApi({ isToken }))
                          if (action?.meta?.requestStatus === 'fulfilled') {
                            showAlert(
                              'success',
                              'Success',
                              'Product successfully added to cart',
                              false,
                              'OK'
                            );
                          }
                        });
                      }}
                    >
                      <ShoppingCartOutlinedIcon />
                      Add To Cart
                    </Button>
                  )}
                  <Button className=' btn-lg addtocartbtn  ml-3  wishlist btn-border' onClick={()=>{
                    dispatch(addProductToWishListApi({isToken,product_id:product?.id})).then((action)=>{
                      if(action?.meta?.requestStatus == "fulfilled"){
                        if(action?.payload?.already_added){
                          showAlert('warning', 'Oops...', 'Product already added to wishlist', false, 'OK');
                        }else{
                          dispatch(countableItemApi({ isToken })).then(()=>{
                            showAlert('success', 'Success', 'Product Added To WishList', false, 'OK');
                          });
                        }
                      }
                    });
                  }}>
                    <FavoriteBorderOutlinedIcon />
                  </Button>
                  <Button className=' btn-lg addtocartbtn ml-3 btn-border' onClick={()=>{
                    dispatch(productAddCompareApi({isToken,id:product?.id})).then((action)=>{
                      if(action?.meta?.requestStatus == "fulfilled"){
                        if(action?.payload?.already_added){
                          showAlert('warning', 'Oops...', 'Product already added to compare', false, 'OK');
                        }else{
                          dispatch(countableItemApi({ isToken })).then(()=>{
                            showAlert('success', 'Success', 'Product Added To Comparison', false, 'OK');
                          });
                        }
                      }
                    });
                  }}>
                    <CompareArrowsIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className='card mt-5 p-5 detailsPageTabs'>
            <div className='customTabs'>
              <ul className='list list-inline'>
                <li className='list-inline-item'>
                  <Button
                    className={`${activeTabs === 0 && 'active'}`}
                    onClick={() => {
                      setActiveTabs(0);
                    }}
                  >
                    Description
                  </Button>
                </li>
                <li className='list-inline-item'>
                  <Button
                    className={`${activeTabs === 1 && 'active'}`}
                    onClick={() => {
                      setActiveTabs(1);
                    }}
                  >
                    Sipping And Returns
                  </Button>
                </li>
                <li className='list-inline-item'>
                  <Button
                    className={`${activeTabs === 2 && 'active'}`}
                    onClick={() => {
                      setActiveTabs(2);
                      // showReviews();
                    }}
                  >
                    Reviews (3)
                  </Button>
                </li>
              </ul>

              <br />

              {activeTabs === 0 && (
                <div className='tabContent'>
                  <p>{product?.description}</p>
                </div>
              )}

              {activeTabs === 1 && (
                <div className='tabContent'>
                  <p>{product?.shipping_returns}</p>
                </div>
              )}

              {activeTabs === 2 && (
                <div className='tabContent'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <h3>Customer questions & answers</h3>
                      {product?.rating_reviews && product?.rating_reviews?.length > 0
                        ? product?.rating_reviews?.map((items, index) => {
                            return (
                              <div className='card p-4 reviewsCard flex-row'>
                                <div className='image'>
                                  <div className='rounded-circle'>
                                    <img src={items?.user?.profile_url} />
                                  </div>
                                  <Typography>
                                    {`${items?.user?.first_name} ${items?.user?.last_name}`.length > 11
                                      ? `${`${items?.user?.first_name} ${items?.user?.last_name}`.substring(0, 11)}...`
                                      : `${items?.user?.first_name} ${items?.user?.last_name}`}
                                  </Typography>
                                </div>

                                <div className='info pl-5'>
                                  <div className='d-flex align-items-center w-100'>
                                    <h5 className='text-light'>{items?.created_at ? moment(items?.created_at).format('Do MMMM YYYY') : ''}</h5>
                                    <div className='ml-auto'>
                                      <Rating
                                        name='half-rating-read'
                                        value={items?.rates ?? 1}
                                        precision={0.5}
                                        readOnly
                                      />
                                    </div>
                                  </div>

                                  <p>{items?.notes}</p>
                                </div>
                              </div>
                            );
                          })
                        : ''}

                      <ProductRating product={product} />
                    </div>

                    <div className='col-md-4 pl-5 reviewBox'>
                      <h4>Customer reviews</h4>

                      <div className='d-flex align-items-center mt-2'>
                        <Rating name='half-rating-read' defaultValue={4.5} precision={0.5} readOnly />
                        <strong className='ml-3'>4.8 out of 5</strong>
                      </div>

                      <br />

                      <div className='progressBarBox d-flex align-items-center'>
                        <span className='mr-3'>5 star</span>
                        <div class='progress' style={{ width: '85%', height: '20px' }}>
                          <div class='progress-bar bg-success' style={{ width: '75%', height: '20px' }}>
                            75%
                          </div>
                        </div>
                      </div>

                      <div className='progressBarBox d-flex align-items-center'>
                        <span className='mr-3'>4 star</span>
                        <div class='progress' style={{ width: '85%', height: '20px' }}>
                          <div class='progress-bar bg-success' style={{ width: '50%', height: '20px' }}>
                            50%
                          </div>
                        </div>
                      </div>

                      <div className='progressBarBox d-flex align-items-center'>
                        <span className='mr-3'>3 star</span>
                        <div class='progress' style={{ width: '85%', height: '20px' }}>
                          <div class='progress-bar bg-success' style={{ width: '55%', height: '20px' }}>
                            55%
                          </div>
                        </div>
                      </div>

                      <div className='progressBarBox d-flex align-items-center'>
                        <span className='mr-3'>2 star</span>
                        <div class='progress' style={{ width: '85%', height: '20px' }}>
                          <div class='progress-bar bg-success' style={{ width: '35%', height: '20px' }}>
                            35%
                          </div>
                        </div>
                      </div>

                      <div className='progressBarBox d-flex align-items-center'>
                        <span className='mr-3'>1 star</span>
                        <div class='progress' style={{ width: '85%', height: '20px' }}>
                          <div class='progress-bar bg-success' style={{ width: '25%', height: '20px' }}>
                            25%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <br />

          <div className='relatedProducts homeProductsRow2  pt-5 pb-4'>
            <h2 class='hd mb-0 mt-0'>Related products</h2>
            <br className='res-hide' />
            <Slider {...related} className='prodSlider'>
              {product?.related_products &&
                product?.related_products?.length !== 0 &&
                product?.related_products.map((product, index) => {
                  return (
                    <div className='item' key={index}>
                      <Product tag={product.type} item={product} />
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsPage;
