import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './Slices/authSlice.js';
import categoryReducer from './Slices/categorySlice.js';
import cartReducer from './Slices/cartSlice.js';
import profileReducer from './Slices/profileSlice.js';
import productReducer from './Slices/productSlice.js';

const reducer = combineReducers({
  authuser: persistReducer(
    {
      key: 'auth',
      storage,
      keyPrefix: 'emarketings-',
      debug: false,
      whitelist: ['isToken', 'user', 'isLoggedIn'],
      timeout: 20000
    },
    authReducer
  ),
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'emarketings-',
      debug: false,
      timeout: 20000
    },
    cartReducer
  ),
  category: persistReducer(
    {
      key: 'category',
      storage,
      keyPrefix: 'emarketings-',
      whitelist: ['isPopularProductTabData'],
      debug: false,
      timeout: 20000
    },
    categoryReducer
  ),
  profile: persistReducer(
    {
      key: 'category',
      storage,
      keyPrefix: 'emarketings-',
      debug: false,
      timeout: 20000
    },
    profileReducer
  ),
  product: persistReducer(
    {
      key: 'product',
      storage,
      keyPrefix: 'emarketings-',
      debug: false,
      timeout: 20000
    },
    productReducer
  )
});

export default reducer;
