import React, { useMemo, useState } from 'react';
import { Avatar, Box, Button, Card, CircularProgress, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { color } from 'framer-motion';
import { profileUpdateApi } from '../../../store/Slices/profileSlice';
import { toast } from 'react-toastify';
import { userUpdate } from '../../../store/Slices/authSlice';
import useSweetAlert from '../../../hooks/sweetalert';

const ProfileUpdate = () => {
  const {showAlert} = useSweetAlert();
  const isToken = useSelector(state => state.authuser.isToken);
  const user = useSelector(state => state.authuser.user);
  const isApiStatus = useSelector(state => state.profile.isApiStatus);
  const formSubmitLoading = isApiStatus && isApiStatus?.profileUpdateApi == 'loading';
  const dispatch = useDispatch();
  // Define Yup schema for validation
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    phone_number: Yup.string().required('Phone Number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    profile_image: Yup.mixed()
  });

  const initialValues = useMemo(() => ({
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    phone_number: user?.phone_number || '',
    email: user?.email || '',
    profile_image: '',
  }), [user]);

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      dispatch(profileUpdateApi({...values,isToken})).then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          if(action?.payload?.user){
            const user = action?.payload?.user ?? {};
            dispatch(userUpdate(user));
          }
          toast.success('Profile updated Successfully');
        } else if (action.meta.requestStatus == 'rejected') {
          const errors = action?.payload?.errors ?? {};
          const message = action?.payload?.message ?? '';
          const status = action?.payload?.status ?? '';
          if (status == 422) {
            const formErrors = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0]; // Assuming you want to display the first error message for each field
              return acc;
            }, {});
            setErrors(formErrors);
          } else if (status == 410) {
            toast.error(message);
          } else if (status == 403){
            showAlert('warning', 'Oops...', message, false, 'OK');
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleImageChange = (event, form) => {
    const file = event.currentTarget.files[0];
    form.setFieldValue('profile_image', file);

    // Optionally, update the avatar preview immediately
    const reader = new FileReader();
    reader.onload = () => {
      form.setFieldValue('avatarPreview', reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant='h4' sx={{ margin: '25px 0px' }}>
        My Profile
      </Typography>
      <Paper elevation={5} >
      <Card variant='outlined' sx={{ padding:'3rem' }}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ errors, touched, setFieldValue,values }) => (
          <Form>
            <Grid container direction='row' justifyContent='center' alignItems='center' sx={{ marginBottom: '25px' }}>
            <Grid item>
                <Avatar alt='Avatar' component={Paper} elevation={5} src={values?.profile_image ? URL.createObjectURL(values?.profile_image) : `${user?.storage_url}/${user?.profile_image}`} sx={{ width: 120, height: 120,border:'1px solid #3bb77e',background:"#f9f6f4" }} />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} xs={12}>
                <Field
                  name='first_name'
                  as={TextField}
                  label='First Name'
                  error={touched.first_name && !!errors.first_name}
                  helperText={touched.first_name && errors.first_name}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Field
                  name='last_name'
                  as={TextField}
                  label='Last Name'
                  error={touched.last_name && !!errors.last_name}
                  helperText={touched.last_name && errors.last_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='phone_number'
                  as={TextField}
                  label='Phone Number'
                  error={touched.phone_number && !!errors.phone_number}
                  helperText={touched.phone_number && errors.phone_number}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='email'
                  type='email'
                  as={TextField}
                  label='Email'
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
              <Field name='profile_image'>
                  {({ field, form }) => (
                    <TextField
                      fullWidth
                      type='file'
                      label='Profile Image'
                      error={form.touched.profile_image && !!form.errors.profile_image}
                      helperText={form.touched.profile_image && form.errors.profile_image}
                      onChange={(event) => handleImageChange(event, form)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputProps: {
                          accept: 'image/*' // Restrict to image files
                        }
                      }}
                    />
                  )}
                </Field>
              </Grid>
                
             
              <Grid item xs={12}>
                <Button type='submit' variant='contained' color='secondary' size="large">
                  {formSubmitLoading && <CircularProgress color='inherit' size={20} sx={{ marginRight:1 }}/>} Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      </Card>
      </Paper>
    </Box>
  );
};

export default ProfileUpdate;
