import React, { useEffect, useState } from 'react';
import '../selectDrop/select.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { selectedCategoryGet } from '../../store/Slices/productSlice';
import { ClickAwayListener } from '@mui/material';

const SelectCommon = ({ data, placeholder, icon }) => {
    const dispatch = useDispatch();
    const [isOpenSelect, setisOpenSelect] = useState(false);
    const [selectedIndex, setselectedIndex] = useState(-1);
    const [selectedItem, setselectedItem] = useState(placeholder);
    const [listData, setListData] = useState(data);

    useEffect(() => {
        if (data) {
            setListData(data);
        }
    }, [data]);

    const openSelect = () => {
        setisOpenSelect(!isOpenSelect);
    };

    const closeSelect = (index, name) => {
        setselectedIndex(index);
        setselectedItem(name);
        setisOpenSelect(false);
        dispatch(selectedCategoryGet({ title: name }));
    };

    const filterList = (e) => {
        const keyword = e.target.value.toLowerCase();
        const list = data.filter((item) => item.toLowerCase().includes(keyword));
        setListData(list);
    };

    return (
        <ClickAwayListener onClickAway={() => setisOpenSelect(false)}>
            <div className="selectDropWrapper cursor position-relative">
                {icon}
                <span className="openSelect" onClick={openSelect}>
                    {selectedItem.length > 14 ? `${selectedItem.substr(0, 14)}...` : selectedItem}
                    <KeyboardArrowDownIcon className="arrow" />
                </span>
                {isOpenSelect && (
                    <div className="selectDrop">
                        <div className="searchField">
                            <input type="text" placeholder="Search here..." onChange={filterList} />
                        </div>
                        <ul className="searchResults">
                            <li
                                key={0}
                                onClick={() => closeSelect(0, placeholder)}
                                className={`${selectedIndex === 0 ? 'active' : ''}`}
                            >
                                {placeholder}
                            </li>
                            {listData.map((item, index) => (
                                <li
                                    key={index + 1}
                                    onClick={() => closeSelect(index + 1, item)}
                                    className={`${selectedIndex === index + 1 ? 'active' : ''}`}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default SelectCommon;
