import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileApiController from "../../services/profile.service";


export const profileUpdateApi = createAsyncThunk('profile/update', async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.profileupdate(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});


export const addProductToWishListApi = createAsyncThunk("product/addtowishlist", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.wishliststore(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const userWishListApi = createAsyncThunk("product/userwishlist", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.wishlistview(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const removeProductFromWishListApi = createAsyncThunk("product/removewishlist", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.wishlistremove(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = response && response.data && response.data.message || "Error occurred";
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = error.response && error.response.data && error.response.data.message || error.message || error.toString();
    throw new Error(errorMessage);
  }
});

export const countryDropdownApi = createAsyncThunk("product/countries", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.countriesdropdown(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const userAddressListsApi = createAsyncThunk("product/addresslists", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.addressview(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const userAddressDetailApi = createAsyncThunk("profile/address/detail", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.addressdetail(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const userAddressCreateApi = createAsyncThunk('profile/address/create', async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.addresscreate(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});


export const userAddressUpdateApi = createAsyncThunk("profile/address/update", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.addressupdate(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const myOrdersApi = createAsyncThunk("myorder/myorderslist", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.myorder(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const myOrderDetailApi = createAsyncThunk("myorder/myorders/detail", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.myordersdetail(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});





export const userAddressDeleteApi = createAsyncThunk("product/addressdelete", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.addressdelete(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = response && response.data && response.data.message || "Error occurred";
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = error.response && error.response.data && error.response.data.message || error.message || error.toString();
    throw new Error(errorMessage);
  }
});

export const userAddressSelectDropdownApi = createAsyncThunk("product/addressselections", async (formValues, thunkAPI) => {
  try {
    const response = await profileApiController.addressselections(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = response && response.data && response.data.message || "Error occurred";
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = error.response && error.response.data && error.response.data.message || error.message || error.toString();
    throw new Error(errorMessage);
  }
});





const initialState = {
  isProfileTab: 0,
  isOpenModal: false,
  isModalDescription: "",
  isModalTitle: "",
  question: false,
  modal_id: "",
  page: null,
  isProductWishList: [],
  isUserWishList: [],
  isCountryDropdown: [],
  isAddressLists: [],
  isAddressDetail: "",
  isMyOrders: '',
  isMyOrderDetail: "",
  isOrderDetailData:"",
  isCustomerAddressTab:"list",
  isOpenThankYouModal:false,
  isOpenOrderDetailModal:false,
  isApiStatus: {
    addProductToWishListApi: "",
    userWishListApi: "",
    removeProductFromWishListApi: "",
    userAddressCreateApi: "",
    userAddressListsApi: "",
    userAddressDetailApi: "",
    userAddressUpdateApi: "",
    userAddressDeleteApi: '',
    userAddressSelectDropdownApi: "",
    myOrdersApi: "",
    myOrderDetailApi: "",
    profileUpdateApi:""
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    reset: () => initialState,
    ProfileTab: (state, action) => {
      state.isProfileTab = action.payload;
    },
    OpenModalTab: (state, action) => {
      state.isOpenModal = action.payload.OpenModal;
      state.isModalDescription = action.payload.description;
      state.isModalTitle = action.payload.title;
      state.question = action.payload.question;
      state.page = action.payload.page;
      state.modal_id = action.payload && action.payload.modal_id ? action.payload.modal_id : "";
    },
    UserAddressTab: (state, action) => {
      state.isAddressTab = action.payload;
    },
    orderDetailData: (state, action) => {
      state.isOrderDetailData = action.payload;
    },
    RemoveSingleWishList: (state, action) => {
      const productIdToRemove = action.payload.id;
      if (state.isUserWishList && state.isUserWishList.wishlist && state.isUserWishList.wishlist.data) {
        state.isUserWishList.wishlist.data = state.isUserWishList.wishlist.data.filter(item => item.product_id !== productIdToRemove);
      }
    },
    CustomerAddressTab: (state, action) => {
      state.isCustomerAddressTab = action.payload;
    },
    OpenThankYouModal: (state, action) => {
      state.isOpenThankYouModal = action.payload;
    },
    OrderDetailModal: (state, action) => {
      state.isOpenOrderDetailModal = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(profileUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, profileUpdateApi: "loading" };
      })
      .addCase(profileUpdateApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, profileUpdateApi: "succeeded" };
      })
      .addCase(profileUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, profileUpdateApi: "failed" };
      })
      .addCase(myOrderDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, myOrderDetailApi: "loading" };
      })
      .addCase(myOrderDetailApi.fulfilled, (state, action) => {
        state.isMyOrderDetail = action.payload;
        state.isApiStatus = { ...state.isApiStatus, myOrderDetailApi: "succeeded" };
      })
      .addCase(myOrderDetailApi.rejected, (state) => {
        state.isMyOrderDetail = "";
        state.isApiStatus = { ...state.isApiStatus, myOrderDetailApi: "failed" };
      })
      .addCase(myOrdersApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, myOrdersApi: "loading" };
      })
      .addCase(myOrdersApi.fulfilled, (state, action) => {
        state.isMyOrders = action.payload;
        state.isApiStatus = { ...state.isApiStatus, myOrdersApi: "succeeded" };
      })
      .addCase(myOrdersApi.rejected, (state) => {
        state.isMyOrders = '';
        state.isApiStatus = { ...state.isApiStatus, myOrdersApi: "failed" };
      })
      .addCase(userAddressSelectDropdownApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressSelectDropdownApi: "loading" };
      })
      .addCase(userAddressSelectDropdownApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressSelectDropdownApi: "succeeded" };
      })
      .addCase(userAddressSelectDropdownApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressSelectDropdownApi: "failed" };
      })
      .addCase(userAddressDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userWishListApi: "loading" };
      })
      .addCase(userAddressDeleteApi.fulfilled, (state, action) => {
        const removedAddressId = parseInt(action.payload.id);
        if ((state.isAddressLists && state.isAddressLists.addresses && state.isAddressLists.addresses.length > 0) && (removedAddressId)) {
          state.isAddressLists.addresses = state.isAddressLists.addresses.filter((item) => item.id !== removedAddressId);
        }
        state.isApiStatus = { ...state.isApiStatus, userAddressDeleteApi: "succeeded" };
      })
      .addCase(userAddressDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressDeleteApi: "failed" };
      })
      .addCase(removeProductFromWishListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userWishListApi: "loading" };
      })
      .addCase(removeProductFromWishListApi.fulfilled, (state, action) => {
        const removedProductId = parseInt(action.payload.id);
        if ((state.isUserWishList && state.isUserWishList.wishlist && state.isUserWishList.wishlist.length > 0) && (removedProductId)) {
          state.isUserWishList.wishlist = state.isUserWishList.wishlist.filter(
            (item) => item.id !== removedProductId
          );
        }
        state.isApiStatus = { ...state.isApiStatus, userWishListApi: "succeeded", removeProductFromWishListApi: "succeeded" };
      })
      .addCase(removeProductFromWishListApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, removeProductFromWishListApi: "failed" };
      })
      .addCase(userAddressDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressDetailApi: "loading" };
      })
      .addCase(userAddressDetailApi.fulfilled, (state, action) => {
        state.isAddressDetail = action.payload;
        state.isApiStatus = { ...state.isApiStatus, userAddressDetailApi: "succeeded" };
      })
      .addCase(userAddressDetailApi.rejected, (state) => {
        state.isAddressDetail = "";
        state.isApiStatus = { ...state.isApiStatus, userAddressDetailApi: "failed" };
      })
      .addCase(userAddressListsApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressListsApi: "loading" };
      })
      .addCase(userAddressListsApi.fulfilled, (state, action) => {
        state.isAddressLists = action.payload;
        state.isApiStatus = { ...state.isApiStatus, userAddressListsApi: "succeeded" };
      })
      .addCase(userAddressListsApi.rejected, (state) => {
        state.isAddressLists = [];
        state.isApiStatus = { ...state.isApiStatus, userAddressListsApi: "failed" };
      })
      .addCase(userWishListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userWishListApi: "loading" };
      })
      .addCase(userWishListApi.fulfilled, (state, action) => {
        state.isUserWishList = action.payload;
        state.isApiStatus = { ...state.isApiStatus, userWishListApi: "succeeded" };
      })
      .addCase(userWishListApi.rejected, (state) => {
        state.isUserWishList = [];
        state.isApiStatus = { ...state.isApiStatus, userWishListApi: "failed" };
      })
      .addCase(userAddressCreateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressCreateApi: "loading" };
      })
      .addCase(userAddressCreateApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressCreateApi: "succeeded" };
      })
      .addCase(userAddressCreateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressCreateApi: "failed" };
      })
      .addCase(countryDropdownApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, countryDropdownApi: "loading" };
      })
      .addCase(countryDropdownApi.fulfilled, (state, action) => {
        state.isCountryDropdown = action.payload;
        state.isApiStatus = { ...state.isApiStatus, countryDropdownApi: "succeeded" };
      })
      .addCase(countryDropdownApi.rejected, (state) => {
        state.isCountryDropdown = [];
        state.isApiStatus = { ...state.isApiStatus, countryDropdownApi: "failed" };
      })
      .addCase(userAddressUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressUpdateApi: "loading" };
      })
      .addCase(userAddressUpdateApi.fulfilled, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressUpdateApi: "succeeded" };
      })
      .addCase(userAddressUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, userAddressUpdateApi: "failed" };
      })
      .addCase(addProductToWishListApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, addProductToWishListApi: "loading" };
      })
      .addCase(addProductToWishListApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, addProductToWishListApi: "succeeded" };
      })
      .addCase(addProductToWishListApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, addProductToWishListApi: "failed" };
      });
  },
});



export const { reset, ProfileTab, OpenModalTab, UserAddressTab,MyOrderTab,orderDetailData,RemoveSingleWishList,CustomerAddressTab,OpenThankYouModal,OrderDetailModal } = profileSlice.actions;
export default profileSlice.reducer;
