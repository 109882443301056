import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Button, Grid, Typography, IconButton, InputAdornment, Alert, AlertTitle } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { authLoginApi } from '../../store/Slices/authSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copiedPassword, setCopiedPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isApiStatus = useSelector(state => state.authuser.isApiStatus);
  const formLoading = isApiStatus && isApiStatus?.authLoginApi == 'loading';

  // Initial form values
  const initialValues = {
    email: '',
    password: ''
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      dispatch(authLoginApi(values)).then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          toast.success('Login Successful');
          navigate('/'); // Navigate to the desired route after successful login
        } else if (action.meta.requestStatus === 'rejected') {
          const errors = action?.payload?.errors ?? {};
          const message = action?.payload?.message ?? '';
          const status = action?.payload?.status ?? '';
          if (status === 422) {
            const formErrors = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0]; // Display the first error message for each field
              return acc;
            }, {});
            setErrors(formErrors);
          } else if (status === 410) {
            toast.error(message);
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText('demoApp@demo.com').then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handlePasswordCopy = () => {
    navigator.clipboard.writeText('123456789').then(() => {
      setCopiedPassword(true);
      setTimeout(() => setCopiedPassword(false), 2000);
    });
  };

  return (
    <Box>
      <div className='breadcrumbWrapper'>
        <div className='container-fluid'>
          <ul className='breadcrumb breadcrumb2 mb-0'>
            <li>Sign In</li>
            <li>
              <Link to='/signUp'>Sign Up</Link>{' '}
            </li>
          </ul>
        </div>
      </div>
      <Grid container spacing={3} justifyContent='center' alignItems='center' sx={{ minHeight: '100vh' }}>
        <Grid item xs={11} md={6} lg={4} xl={3}>
          <Box sx={{ padding: 5, borderRadius: 2, boxShadow: 3, backgroundColor: 'background.paper' }}>
            <Typography variant='h5' align='center' gutterBottom>
              Sign In
            </Typography>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <Box mb={2}>
                    <Alert severity='warning' icon={false} sx={{ borderRadius: 4 }}>
                      <AlertTitle>Signup or enter follow credential to start your adventures 🚀</AlertTitle>
                      <Typography>
                        Email : demoApp@demo.com
                        <IconButton onClick={handleCopy} sx={{ marginLeft: 1 }}>
                          {copied ? <CheckCircleOutlineIcon color='success' /> : <FileCopyOutlinedIcon />}
                        </IconButton>
                      </Typography>
                      <Typography>
                        Password : 123456789
                        <IconButton onClick={handlePasswordCopy} sx={{ marginLeft: 1 }}>
                          {copiedPassword ? <CheckCircleOutlineIcon color='success' /> : <FileCopyOutlinedIcon />}
                        </IconButton>
                      </Typography>
                    </Alert>
                  </Box>
                  <Box mb={2}>
                    <Field
                      as={TextField}
                      name='email'
                      label='Email'
                      type='email'
                      fullWidth
                      variant='outlined'
                      error={touched.email && Boolean(errors.email)}
                      helperText={<ErrorMessage name='email' />}
                    />
                  </Box>
                  <Box mb={3} position='relative'>
                    <Field
                      as={TextField}
                      name='password'
                      label='Password'
                      type={showPassword ? 'text' : 'password'}
                      fullWidth
                      variant='outlined'
                      error={touched.password && Boolean(errors.password)}
                      helperText={<ErrorMessage name='password' />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                  <Button
                    type='submit'
                    variant='outlined'
                    sx={{
                      background: '#3bb77e',
                      color: '#FFF',
                      borderColor: '#3bb77e',
                      marginRight: 2,
                      '&:hover': {
                        borderColor: '#3bb77e',
                        color: '#3bb77e'
                      }
                    }}
                    fullWidth
                  >
                    {formLoading && <CircularProgress color='inherit' size={20} sx={{ marginRight:1 }}/>}Sign in
                  </Button>
                  <Box mt={2} textAlign='center'>
                    <Typography variant='body2' sx={{ marginBottom:2 }}>
                      Don't have an account?
                      <Link to='/signup' style={{ marginLeft: 4 }}>
                        Sign Up
                      </Link>
                    </Typography>
                    <Typography variant='body2' sx={{ marginBottom:2 }}>
                      Forgot Password?
                      <Link to='/forgot-password' style={{ marginLeft: 4 }}>
                        Click here
                      </Link>
                    </Typography>
                    <Typography variant='body1'>
                      Want to check admin pannel i have made?
                      <a href={config?.ADMIN_URL} target='_blank' style={{ marginLeft: 4 }}>
                        Click Here
                      </a>
                    </Typography>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignIn;
