import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { productRatingStoreApi } from '../../store/Slices/productSlice';
import useSweetAlert from '../../hooks/sweetalert';

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color='error' sx={{ fontSize: '3.5rem' }} />,
    label: 'Very Dissatisfied'
  },
  2: {
    icon: <SentimentDissatisfiedIcon color='error' sx={{ fontSize: '3.5rem' }} />,
    label: 'Dissatisfied'
  },
  3: {
    icon: <SentimentSatisfiedIcon color='warning' sx={{ fontSize: '3.5rem' }} />,
    label: 'Neutral'
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color='success' sx={{ fontSize: '3.5rem' }} />,
    label: 'Satisfied'
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color='success' sx={{ fontSize: '3.5rem' }} />,
    label: 'Very Satisfied'
  }
};

const CustomRating = ({ value, onChange }) => {
  const [hover, setHover] = useState(-1);

  return (
    <div style={{ display: 'flex', justifyContent: 'start', gap: '10px' }}>
      {[1, 2, 3, 4, 5].map(index => (
        <span
          key={index}
          style={{
            cursor: 'pointer',
            fontSize: '3.5rem'
          }}
          onMouseEnter={() => setHover(index)}
          onMouseLeave={() => setHover(-1)}
          onClick={() => onChange(index)}
        >
          {index <= (hover === -1 ? value : hover) ? (
            customIcons[index].icon
          ) : (
            <SentimentVeryDissatisfiedIcon color='disabled' sx={{ fontSize: '3.5rem' }} />
          )}
        </span>
      ))}
    </div>
  );
};

const ProductRating = ({ product }) => {
  const dispatch = useDispatch();
  const { showAlert } = useSweetAlert();
  const isToken = useSelector(state => state.authuser.isToken);
  const isApiStatus = useSelector(state => state.product.isApiStatus);
  const formSubmitting = isApiStatus && isApiStatus?.productRatingStoreApi === 'loading';

  const validationSchema = Yup.object().shape({
    product_id: Yup.string().required('Product ID is required'),
    rates: Yup.number().min(1).max(5).required('Rating is required'),
    notes: Yup.string().required('Review notes are required')
  });

  const initialValues = useMemo(
    () => ({
      product_id: product?.id || '',
      rates: 1,
      notes: ''
    }),
    [product]
  );

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      dispatch(productRatingStoreApi({ ...values, isToken })).then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          showAlert('success', 'Success', 'Review submitted successfully', false, 'OK');
        } else if (action.meta.requestStatus === 'rejected') {
          const errors = action?.payload?.errors ?? {};
          const message = action?.payload?.message ?? '';
          const status = action?.payload?.status ?? '';
          if (status === 422) {
            const formErrors = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0]; // Assuming you want to display the first error message for each field
              return acc;
            }, {});
            setErrors(formErrors);
          } else if (status === 410) {
            showAlert('warning', 'Warning', message, false, 'OK');
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, setFieldValue, values }) => {
          return (
            <Form>
              <Typography variant='h4' sx={{ margin : '1.5rem 0rem' }}>Add a review</Typography>
              <div>
                <Typography variant='h6' sx={{ margin : '1rem 0rem 0rem 0rem' }}>How satisfied are you with this product</Typography>
                <div className='form-group'>
                  <CustomRating
                    value={values.rates}
                    onChange={newValue => {
                      setFieldValue('rates', newValue);
                    }}
                  />
                  <ErrorMessage name='rates' component='div' className='text-danger' />
                </div>
              </div>
              <Box sx={{ marginBottom: '100px' }}>
                <Field
                  as={TextField}
                  id='outlined-multiline-static'
                  label='Write a Review'
                  multiline
                  rows={4}
                  className='form-control'
                  name='notes'
                  variant='outlined'
                  fullWidth
                />
              </Box>
              <ErrorMessage name='notes' component='div' className='text-danger' style={{ marginBottom: '2rem' }} />
              <div className='form-group'>
                <Button type='submit' className='btn-g btn-lg'>
                  {formSubmitting && <CircularProgress color='inherit' size={20} sx={{ marginRight: 1 }} />}Submit
                  Review
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

ProductRating.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductRating;
