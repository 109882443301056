import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import categoryApiController from '../../services/category.service.js';
import reducer from '../reducer.js';

export const headerCategoryApi = createAsyncThunk('user/categoriesHeader', async (formValues, thunkAPI) => {
  try {
    const response = await categoryApiController.headerCategory(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      const token = response.data.token;
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const countableItemApi = createAsyncThunk('home/countable', async (formValues, thunkAPI) => {
  try {
    const response = await categoryApiController.countable(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      const token = response.data.token;
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const aboutUsApi = createAsyncThunk('aboutus/view', async (formValues, thunkAPI) => {
  try {
    const response = await categoryApiController.aboutus(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      const token = response.data.token;
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const addressDropDownApi = createAsyncThunk('home/country/address/dropdown', async (formValues, thunkAPI) => {
  try {
    const response = await categoryApiController.addresslists(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      const token = response.data.token;
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const blogApi = createAsyncThunk('blogs', async (formValues, thunkAPI) => {
  try {
    const response = await categoryApiController.blogs(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      const token = response.data.token;
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});
export const blogDetailApi = createAsyncThunk('blog/detail', async (formValues, thunkAPI) => {
  try {
    const response = await categoryApiController.blogdetail(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      const token = response.data.token;
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});
export const privacyPolicyApi = createAsyncThunk('privacy-policies', async (formValues, thunkAPI) => {
  try {
    const response = await categoryApiController.policies(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      const token = response.data.token;
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});
export const termAndConditionApi = createAsyncThunk('terms-conditions', async (formValues, thunkAPI) => {
  try {
    const response = await categoryApiController.termsConditions(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      const token = response.data.token;
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});
export const deliveryInformationApi = createAsyncThunk('delivery-informations', async (formValues, thunkAPI) => {
  try {
    const response = await categoryApiController.deliveryInformations(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      const token = response.data.token;
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

const initialState = {
  isHeaderCategoryList: [],
  isPopularProductTab: 0,
  isPopularProductTabData: [],
  isAddressList:"",
  isCountableItem:"",
  isOpenAddressSelectModal:false,
  isOpenCategoryFilter:false,
  isOpenFilter:false,
  isOpenBlogDetailModal:false,
  isBlogData:'',
  isaboutUs:"",
  isBlogViewType:'grid',
  isBlogDetailData:'',
  isPrivacyPolicyData:'',
  isTermAndConditionData:'',
  isDeliveryInformationData:'',
  isApiStatus: {
    headerCategoryApi: '',
    countableItemApi:"",
    aboutUsApi:'',
    blogApi:'',
    blogDetailApi:"",
    privacyPolicyApi:'',
    termAndConditionApi:'',
    deliveryInformationApi:''
  }
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    reset: () => initialState,
    PopularProductTab: (state, action) => {
      state.isPopularProductTab = action.payload.tab;
      state.isPopularProductTabData = action.payload.data;
    },
    AddressSelectModal: (state, action) => {
      state.isOpenAddressSelectModal = action.payload;
    },
    CurrentActiveAddress: (state, action) => {
      state.isCurrentActiveAddress = action.payload;
    },
    OpenCategoryFilter: (state) => {
      state.isOpenCategoryFilter = !state.isOpenCategoryFilter;
    },
    OpenFilter: (state) => {
      state.isOpenFilter = !state.isOpenFilter;
    },
    BlogViewType: (state,action) => {
      state.isBlogViewType = action.payload;
    },
    OpenBlogDetailModal: (state,action) => {
      state.isOpenBlogDetailModal = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(deliveryInformationApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, deliveryInformationApi: 'loading' };
      })
      .addCase(deliveryInformationApi.fulfilled, (state, action) => {
        state.isDeliveryInformationData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, deliveryInformationApi: 'succeeded' };
      })
      .addCase(deliveryInformationApi.rejected, state => {
        state.isDeliveryInformationData = "";
        state.isApiStatus = { ...state.isApiStatus, deliveryInformationApi: 'failed' };
      })
      .addCase(termAndConditionApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, termAndConditionApi: 'loading' };
      })
      .addCase(termAndConditionApi.fulfilled, (state, action) => {
        state.isTermAndConditionData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, termAndConditionApi: 'succeeded' };
      })
      .addCase(termAndConditionApi.rejected, state => {
        state.isTermAndConditionData = "";
        state.isApiStatus = { ...state.isApiStatus, termAndConditionApi: 'failed' };
      })
      .addCase(privacyPolicyApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, privacyPolicyApi: 'loading' };
      })
      .addCase(privacyPolicyApi.fulfilled, (state, action) => {
        state.isPrivacyPolicyData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, privacyPolicyApi: 'succeeded' };
      })
      .addCase(privacyPolicyApi.rejected, state => {
        state.isPrivacyPolicyData = "";
        state.isApiStatus = { ...state.isApiStatus, privacyPolicyApi: 'failed' };
      })
      .addCase(blogDetailApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, blogDetailApi: 'loading' };
      })
      .addCase(blogDetailApi.fulfilled, (state, action) => {
        state.isBlogDetailData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, blogDetailApi: 'succeeded' };
      })
      .addCase(blogDetailApi.rejected, state => {
        state.isBlogDetailData = "";
        state.isApiStatus = { ...state.isApiStatus, blogDetailApi: 'failed' };
      })
      .addCase(blogApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, blogApi: 'loading' };
      })
      .addCase(blogApi.fulfilled, (state, action) => {
        state.isBlogData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, blogApi: 'succeeded' };
      })
      .addCase(blogApi.rejected, state => {
        state.isBlogData = "";
        state.isApiStatus = { ...state.isApiStatus, blogApi: 'failed' };
      })
      .addCase(aboutUsApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, aboutUsApi: 'loading' };
      })
      .addCase(aboutUsApi.fulfilled, (state, action) => {
        state.isaboutUs = action.payload;
        state.isApiStatus = { ...state.isApiStatus, aboutUsApi: 'succeeded' };
      })
      .addCase(aboutUsApi.rejected, state => {
        state.isaboutUs = "";
        state.isApiStatus = { ...state.isApiStatus, aboutUsApi: 'failed' };
      })
      .addCase(addressDropDownApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, addressDropDownApi: 'loading' };
      })
      .addCase(addressDropDownApi.fulfilled, (state, action) => {
        state.isAddressList = action.payload;
        state.isApiStatus = { ...state.isApiStatus, addressDropDownApi: 'succeeded' };
      })
      .addCase(addressDropDownApi.rejected, state => {
        state.isAddressList = "";
        state.isApiStatus = { ...state.isApiStatus, addressDropDownApi: 'failed' };
      })
      .addCase(countableItemApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, countableItemApi: 'loading' };
      })
      .addCase(countableItemApi.fulfilled, (state, action) => {
        state.isCountableItem = action.payload;
        state.isApiStatus = { ...state.isApiStatus, countableItemApi: 'succeeded' };
      })
      .addCase(countableItemApi.rejected, state => {
        state.isCountableItem = "";
        state.isApiStatus = { ...state.isApiStatus, countableItemApi: 'failed' };
      })
      .addCase(headerCategoryApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, headerCategoryApi: 'loading' };
      })
      .addCase(headerCategoryApi.fulfilled, (state, action) => {
        state.isHeaderCategoryList = action.payload;
        state.isApiStatus = { ...state.isApiStatus, headerCategoryApi: 'succeeded' };
      })
      .addCase(headerCategoryApi.rejected, state => {
        state.isHeaderCategoryList = [];
        state.isApiStatus = { ...state.isApiStatus, headerCategoryApi: 'failed' };
      });
  }
});

export const { reset, PopularProductTab,AddressSelectModal,CurrentActiveAddress,OpenCategoryFilter,OpenFilter,BlogViewType,OpenBlogDetailModal } = categorySlice.actions;
export default categorySlice.reducer;
