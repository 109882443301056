import React, { useState, useEffect, useRef } from 'react';
import SliderBanner from './slider/index';
import CatSlider from '../../components/catSlider';

import Banners from '../../components/banners';

import './style.css';
import Product from '../../components/product';
import Banner4 from '../../assets/images/banner4.jpg';

import Slider from 'react-slick';
import TopProducts from './TopProducts';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { PopularProductTab } from '../../store/Slices/categorySlice';
import useWindowWidth from '../../hooks/window';

const Home = props => {
  const width = useWindowWidth();

  const [catArray, setcatArray] = useState([]);
  const [activeTab, setactiveTab] = useState();
  const [activeTabIndex, setactiveTabIndex] = useState(0);
  const [activeTabData, setActiveTabData] = useState([]);

  const [bestSells, setBestSells] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const dispatch = useDispatch();
  const productRow = useRef();
  const isHeaderCategoryList = useSelector(state => state.category.isHeaderCategoryList);
  const isPopularProductActiveTab = useSelector(state => state.category.isPopularProductTab);
  const isPopularProductTabData = useSelector(state => state.category.isPopularProductTabData);
  const subcategories =
    isHeaderCategoryList && isHeaderCategoryList?.popularProducts && isHeaderCategoryList?.popularProducts?.length > 0
      ? isHeaderCategoryList?.popularProducts
      : [];
  const bestSellers =
    isHeaderCategoryList &&
    isHeaderCategoryList?.bestSells &&
    isHeaderCategoryList?.bestSells?.products &&
    isHeaderCategoryList?.bestSells?.products.length > 0
      ? isHeaderCategoryList?.bestSells?.products
      : [];
  const topSellingProduct =
    isHeaderCategoryList &&
    isHeaderCategoryList?.topSellingProduct &&
    isHeaderCategoryList?.topSellingProduct?.products &&
    isHeaderCategoryList?.topSellingProduct?.products.length > 0
      ? isHeaderCategoryList?.topSellingProduct?.products
      : [];
  const topTrendingProduct =
    isHeaderCategoryList &&
    isHeaderCategoryList?.topTrendingProduct &&
    isHeaderCategoryList?.topTrendingProduct?.products &&
    isHeaderCategoryList?.topTrendingProduct?.products.length > 0
      ? isHeaderCategoryList?.topTrendingProduct?.products
      : [];
  const topRecentProduct =
    isHeaderCategoryList &&
    isHeaderCategoryList?.topRecentProduct &&
    isHeaderCategoryList?.topRecentProduct?.products &&
    isHeaderCategoryList?.topRecentProduct?.products.length > 0
      ? isHeaderCategoryList?.topRecentProduct?.products
      : [];
  const topRatedProduct =
    isHeaderCategoryList &&
    isHeaderCategoryList?.topRatedProduct &&
    isHeaderCategoryList?.topRatedProduct?.products &&
    isHeaderCategoryList?.topRatedProduct?.products?.length > 0
      ? isHeaderCategoryList?.topRatedProduct?.products
      : [];
  const isApiStatus = useSelector(state => state.category.isApiStatus);
  const isLoading = isApiStatus && isApiStatus.headerCategoryApi == 'loading';

  var settings = {
    dots: false,
    infinite: width < 992 ? false : true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    arrows: width < 992 ? false : true
  };

  

  useEffect(() => {
    if (isPopularProductTabData.length === 0 && subcategories.length !== 0) {
      let firstSubcategoryIndex = 0;
      for (let i = 0; i < subcategories.length; i++) {
        if (subcategories[i].products && subcategories[i].products.length > 0) {
          firstSubcategoryIndex = i;
          break;
        }
      }
      const firstSubcategory = subcategories[firstSubcategoryIndex];
      const firstSubcategoryProducts = firstSubcategory.products || [];
      dispatch(PopularProductTab({ tab: firstSubcategoryIndex, data: firstSubcategoryProducts }));
    }
  }, [isPopularProductTabData, subcategories, dispatch]);

  const bestSellsArr = [];

  useEffect(() => {
    // prodData.length !== 0 &&
    //   prodData.map(item => {
    //     if (item.cat_name === 'Electronics') {
    //       item.items.length !== 0 &&
    //         item.items.map(item_ => {
    //           item_.products.length !== 0 &&
    //             item_.products.map((product, index) => {
    //               bestSellsArr.push(product);
    //             });
    //         });
    //     }
    //   });

    setBestSells(bestSellsArr);
  }, []);

  return (
    <div style={{ display: 'block' }}>
      <SliderBanner />
      <CatSlider />
      <Banners />
      <section className='homeProducts homeProductWrapper'>
        {isLoading ? (
          <div className='mt-0 pt-0'>
            <div className='d-flex flex-wrap'>
              {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton
                  key={index}
                  height={400}
                  width={500}
                  count={1}
                  highlightColor='#def9ec'
                  baseColor='#f9f6f4'
                  style={{ margin: '2rem' }}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className='container-fluid'>
            <div className='d-flex align-items-center homeProductsTitleWrap'>
              <h2 className='hd mb-0 mt-0 res-full'>Popular Products</h2>
              <ul className='list list-inline ml-auto filterTab mb-0 res-full'>
                {subcategories.length !== 0 &&
                  subcategories.map((cat, index) => {
                    return (
                      <li className='list list-inline-item'>
                        <a
                          className={`cursor text-capitalize 
                                                  ${isPopularProductActiveTab === index ? 'act' : ''}`}
                          onClick={() => {
                            dispatch(PopularProductTab({ tab: index, data: cat?.products }));
                            productRow.current.scrollLeft = 0;
                          }}
                        >
                          {cat?.title}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className={`productRow ${isLoadingProducts === true && 'loading'}`} ref={productRow}>
              {isPopularProductTabData &&
                isPopularProductTabData.length !== 0 &&
                isPopularProductTabData.map((item, index) => {
                  return (
                    <div className='item' key={index}>
                      <Product tag={item.type} item={item} />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </section>
      <section className='homeProducts homeProductsRow2 pt-0'>
        <div className='container-fluid'>
          <div className='d-flex align-items-center'>
            <h2 className='hd mb-0 mt-0'>Daily Best Sells</h2>
          </div>

          <br className='res-hide' />
          <br className='res-hide' />
          <div className='row'>
            <div className='col-md-3 pr-5 res-hide'>
              <img src={Banner4} className='w-100' />
            </div>

            <div className='col-md-9'>
              <Slider {...settings} className='prodSlider'>
                {bestSellers.length !== 0 &&
                  bestSellers.map((item, index) => {
                    return (
                      <div className='item' key={index}>
                        <Product tag={item.type} item={item} />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className='topProductsSection'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col'>
              <div className='topSelling_box'>
                <h3>Top Selling</h3>
                {topSellingProduct.length > 0
                  ? topSellingProduct.map((item, i) => {
                      return (
                        <React.Fragment>
                          <TopProducts item={item} />
                        </React.Fragment>
                      );
                    })
                  : ''}
              </div>
            </div>
            <div className='col'>
              <div className='topSelling_box'>
                <h3>Trending Products</h3>
                {topTrendingProduct.length > 0
                  ? topTrendingProduct.map((item, i) => {
                      return (
                        <React.Fragment>
                          <TopProducts item={item} />
                        </React.Fragment>
                      );
                    })
                  : ''}
              </div>
            </div>
            <div className='col'>
              <div className='topSelling_box'>
                <h3>Recently added</h3>
                {topRecentProduct.length > 0
                  ? topRecentProduct.map((item, i) => {
                      return (
                        <React.Fragment>
                          <TopProducts item={item} />
                        </React.Fragment>
                      );
                    })
                  : ''}
              </div>
            </div>
            <div className='col'>
              <div className='topSelling_box'>
                <h3>Top Rated</h3>
                {topRatedProduct.length > 0
                  ? topRatedProduct.map((item, i) => {
                      return (
                        <React.Fragment>
                          <TopProducts item={item} />
                        </React.Fragment>
                      );
                    })
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
