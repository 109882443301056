import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderDetailModal, myOrderDetailApi, myOrdersApi } from '../../../store/Slices/profileSlice';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  AvatarGroup,
  Pagination,
  Button,
  Stack,
  CircularProgress
} from '@mui/material';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import OrderDetail from './OrderDetail';
import notFound from '../../../assets/images/404.png';
import './order.css';

const OrderList = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState({});
  const isToken = useSelector(state => state.authuser.isToken);
  const isMyOrders = useSelector(state => state.profile.isMyOrders);
  const ordersData = (isMyOrders && isMyOrders.orders) || {};
  const orders = ordersData.data || [];
  const currentPage = ordersData.current_page || 1;
  const totalPages = ordersData.last_page || 1;
  const isApiStatus = useSelector(state => state.profile.isApiStatus);
  const isLoading = isApiStatus && isApiStatus?.myOrdersApi === 'loading';
  const orderDetailLoading = isApiStatus && isApiStatus?.myOrderDetailApi !== 'loading';
  const isOpenOrderDetailModal = useSelector(state => state.profile.isOpenOrderDetailModal);

  useEffect(() => {
    dispatch(myOrdersApi({ isToken, page: currentPage }));
  }, [dispatch, isToken, currentPage]);

  const handlePageChange = (event, value) => {
    dispatch(myOrdersApi({ isToken, page: value }));
  };

  return (
    <Box sx={{ padding: '20px' }} id='orderlist-view'>
      <Typography variant='h4' gutterBottom>
        My Orders
      </Typography>
      {isLoading ? (
        <Table sx={{ width: '100%' }} aria-label='cart table'>
          <TableHead sx={{ background: '#f4f6f8' }}>
            <TableRow>
              <TableCell sx={{ color: '#828f9b' }}>Order ID</TableCell>
              <TableCell sx={{ color: '#828f9b' }}>Items</TableCell>
              <TableCell sx={{ color: '#828f9b' }}>Total</TableCell>
              <TableCell sx={{ color: '#828f9b' }}>Discount</TableCell>
              <TableCell sx={{ color: '#828f9b' }}>Shipping Charge</TableCell>
              <TableCell sx={{ color: '#828f9b' }}>Grand Total</TableCell>
              <TableCell sx={{ color: '#828f9b' }}>Date</TableCell>
              <TableCell sx={{ color: '#828f9b' }}></TableCell>
            </TableRow>
          </TableHead>
          {Array.from(Array(5).keys()).map((_, index) => (
            <TableRow>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Stack spacing={1} display={'flex'} direction={'row'} alignItems={'center'}>
                  <Skeleton circle width={40} height={40} />
                  <Skeleton circle width={40} height={40} />
                  <Skeleton circle width={40} height={40} />
                  <Skeleton circle width={40} height={40} />
                </Stack>
              </TableCell>
              <TableCell>
                <Stack spacing={1} display={'flex'} direction={'row'} alignItems={'center'}>
                  <Skeleton width={40} />
                </Stack>
              </TableCell>
              <TableCell>
                <Skeleton width={100} />
              </TableCell>
              <TableCell>
                <Skeleton width={100} />
              </TableCell>
              <TableCell>
                <Skeleton width={40} height={40} />
              </TableCell>
              <TableCell>
                <Skeleton width={40} height={40} />
              </TableCell>
              <TableCell>
                <Skeleton width={40} height={40} circle />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      ) : (
        <TableContainer component={Paper} elevation={5}>
          <Table sx={{ minWidth: 650 }} aria-label='order table'>
            <TableHead>
              <TableRow sx={{ background: '#f4f6f8' }}>
                <TableCell sx={{ color: '#828f9b' }}>Order ID</TableCell>
                <TableCell sx={{ color: '#828f9b' }}>Items</TableCell>
                <TableCell sx={{ color: '#828f9b' }}>Total</TableCell>
                <TableCell sx={{ color: '#828f9b' }}>Discount</TableCell>
                <TableCell sx={{ color: '#828f9b' }}>Shipping Charge</TableCell>
                <TableCell sx={{ color: '#828f9b' }}>Grand Total</TableCell>
                <TableCell sx={{ color: '#828f9b' }}>Date</TableCell>
                <TableCell sx={{ color: '#828f9b' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.length > 0 ? (
                orders.map((order, index) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'start' }}>
                        <AvatarGroup max={6}>
                          {order.items &&
                            order.items.map(item => (
                              <Avatar
                                key={item.id}
                                alt={item.product.title}
                                src={`${item.product.storage_url}/${item.product.thumbnail_image}`}
                              />
                            ))}
                        </AvatarGroup>
                      </Box>
                    </TableCell>
                    <TableCell>${order.total}</TableCell>
                    <TableCell>${order.discount || 0}</TableCell>
                    <TableCell>${order.shipping_charge}</TableCell>
                    <TableCell>${order.grand_total}</TableCell>
                    <TableCell>{new Date(order.created_at).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Avatar
                        sx={{ background: `${loader[index] ? '#FFF' : '#3bb77e'}` }}
                        onClick={() => {
                          setLoader(prevLoader => ({ ...prevLoader, [index]: true }));
                          dispatch(myOrderDetailApi({isToken,order_id:order?.id})).then(()=>{
                            setLoader(prevLoader => ({ ...prevLoader, [index]: false }));
                            dispatch(OrderDetailModal(true));
                          })
                        }}
                      >
                        {loader[index] ? <CircularProgress size={20} color='secondary'/> : <RemoveRedEyeIcon />}
                      </Avatar>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <motion.tr
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                style={{ height: '300px', width: '100%' }}
              >
                <TableCell colSpan={8} sx={{ padding: 0 }}>
                  <Stack
                    display='flex'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    sx={{ height: '100%', width: '100%' }}
                  >
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Avatar src={notFound} sx={{ width: '150px', height: '150px' }} />
                    </motion.div>
                    <Typography variant='h6' textAlign='center' sx={{ mt: 2 }}>
                    Your order is currently empty. Please add items to your cart before proceeding.
                    </Typography>
                  </Stack>
                </TableCell>
              </motion.tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalPages > 1 && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            color='secondary'
            page={currentPage}
            onChange={(event, page) => handlePageChange(event,page)}
          />
        </Box>
      )}
      {isOpenOrderDetailModal && <OrderDetail />}
    </Box>
  );
};

export default OrderList;
