import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  CustomerAddressTab,
  profileUpdateApi,
  userAddressCreateApi,
  userAddressListsApi
} from '../../../store/Slices/profileSlice';
import { userUpdate } from '../../../store/Slices/authSlice';

const StyledSelect = styled('select')({
  font: 'inherit',
  letterSpacing: 'inherit',
  color: 'currentColor',
  padding: '16.5px 0px 16.5px 0px',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  boxSizing: 'content-box',
  background: 'none',
  height: 'auto',
  margin: '0',
  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  minWidth: '0',
  width: '100%',
  WebkitAnimationName: 'mui-auto-fill-cancel',
  animationName: 'mui-auto-fill-cancel',
  WebkitAnimationDuration: '10ms',
  animationDuration: '10ms',
  '&:focus': {
    borderColor: '#3f51b5',
    outline: 'none',
    boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)'
  }
});

const CreateAddress = () => {
  const isToken = useSelector(state => state.authuser.isToken);
  const dispatch = useDispatch();
  const isCountryDropdown = useSelector(state => state.profile.isCountryDropdown);
  const countries = isCountryDropdown && isCountryDropdown?.countries?.length > 0 ? isCountryDropdown?.countries : [];
  const isApiStatus = useSelector((state)=>state.profile.isApiStatus);
  const formLoading = isApiStatus && isApiStatus?.userAddressCreateApi == 'loading';

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobile: Yup.string().required('Mobile is required'),
    address: Yup.string().required('Address is required'),
    apartment: Yup.string(),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string().required('ZIP is required'),
    country_id: Yup.number().required('Country is required'),
    is_active: Yup.boolean().required('Active status is required')
  });

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    address: '',
    apartment: '',
    city: '',
    state: '',
    zip: '',
    country_id: '',
    is_active: 0
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      dispatch(userAddressCreateApi({ ...values, isToken })).then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          dispatch(userAddressListsApi({ isToken })).then(() => {
            dispatch(CustomerAddressTab('list'));
            toast.success('Address created Successfully');
          });
        } else if (action.meta.requestStatus == 'rejected') {
          const errors = action?.payload?.errors ?? {};
          const message = action?.payload?.message ?? '';
          const status = action?.payload?.status ?? '';
          if (status == 422) {
            const formErrors = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0]; // Assuming you want to display the first error message for each field
              return acc;
            }, {});
            setErrors(formErrors);
          } else if (status == 410) {
            toast.error(message);
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h4' sx={{ margin: '25px 0px' }}>
          Create Address
        </Typography>
        <Button
          variant='contained'
          sx={{ background: '#233446', paddingLeft: '4rem', paddingRight: '4rem' }}
          onClick={() => dispatch(CustomerAddressTab('list'))}
        >
          Back
        </Button>
      </Box>
      <Paper elevation={5} sx={{ marginBottom: 10 }}>
        <Card variant='outlined' sx={{ marginBottom: 10, padding: '3rem' }}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ errors, touched, values, setFieldValue }) => {
              return (
                <Form>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name='first_name'
                        as={TextField}
                        label='First Name'
                        error={touched.first_name && !!errors.first_name}
                        helperText={touched.first_name && errors.first_name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name='last_name'
                        as={TextField}
                        label='Last Name'
                        error={touched.last_name && !!errors.last_name}
                        helperText={touched.last_name && errors.last_name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='email'
                        type='email'
                        as={TextField}
                        label='Email'
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='mobile'
                        as={TextField}
                        label='Mobile'
                        error={touched.mobile && !!errors.mobile}
                        helperText={touched.mobile && errors.mobile}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='address'
                        as={TextField}
                        label='Address'
                        error={touched.address && !!errors.address}
                        helperText={touched.address && errors.address}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='apartment'
                        as={TextField}
                        label='Apartment'
                        error={touched.apartment && !!errors.apartment}
                        helperText={touched.apartment && errors.apartment}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='city'
                        as={TextField}
                        label='City'
                        error={touched.city && !!errors.city}
                        helperText={touched.city && errors.city}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='state'
                        as={TextField}
                        label='State'
                        error={touched.state && !!errors.state}
                        helperText={touched.state && errors.state}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='zip'
                        as={TextField}
                        label='ZIP'
                        error={touched.zip && !!errors.zip}
                        helperText={touched.zip && errors.zip}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledSelect
                        name='country_id'
                        value={values.country_id}
                        onChange={e => setFieldValue('country_id', e.target.value)}
                        className={touched.country_id && errors.country_id ? 'error' : ''}
                      >
                        <option value='' disabled>
                          Select a country
                        </option>
                        {countries.map(country => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </StyledSelect>
                      {touched.country_id && errors.country_id && (
                        <Typography variant='caption' color='error'>
                          {errors.country_id}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Field
                              type='checkbox'
                              as={Switch}
                              name='is_active'
                              color='primary'
                              checked={values.is_active === 1}
                              onChange={() => setFieldValue('is_active', values.is_active === 1 ? 0 : 1)}
                            />
                          }
                          label='Set as primary address'
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      sx={{ paddingLeft: '4rem', paddingRight: '4rem', position: 'relative' }}
                    >
                       {formLoading && <CircularProgress color='inherit' size={20} sx={{ marginRight:1 }}/>}Submit
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Paper>
    </Box>
  );
};

export default CreateAddress;
