import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './responsive.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Home from './pages/Home/index';
import About from './pages/About/index';
import Listing from './pages/Listing';
import NotFound from './pages/NotFound';
import DetailsPage from './pages/Details';
import Blogs from './pages/Blogs';
import Checkout from './pages/checkout';
import Cart from './pages/cart';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import PublicRoute from './Guards/PublickRoute';
import PrivateRoute from './Guards/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import ProductCompare from './pages/ProductCompare';
import Profile from './pages/Profile';
import ContactUs from './pages/contactus';
import TermsAndCondition from './pages/terms';
import DeliveryInformation from './pages/deliveryInformation';
import PrivacyPolicy from './pages/privacyPolicy';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <div>
        <Routes>
          <Route
            exact
            path='/signIn'
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route
            exact
            path='/signUp'
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          />
          <Route
            exact
            path='/forgot-password'
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path='/*'
            element={
              <>
                <Header />
                <Routes>
                  <Route
                    exact
                    path='/'
                    element={
                      <PrivateRoute>
                        <Home />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/cat/:id'
                    element={
                      <PrivateRoute>
                        <Listing single={true} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/cat/:id/:id'
                    element={
                      <PrivateRoute>
                        <Listing single={false} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/product/:id'
                    element={
                      <PrivateRoute>
                        <DetailsPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/compare'
                    element={
                      <PrivateRoute>
                        <ProductCompare />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/cart'
                    element={
                      <PrivateRoute>
                        <Cart />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/checkout'
                    element={
                      <PrivateRoute>
                        <Checkout />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/profile'
                    element={
                      <PrivateRoute>
                        <Profile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/contact-us'
                    element={
                      <PrivateRoute>
                        <ContactUs />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/about-us'
                    element={
                      <PrivateRoute>
                        <About />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/blogs'
                    element={
                      <PrivateRoute>
                        <Blogs />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/privacy-policies'
                    element={
                      <PrivateRoute>
                        <PrivacyPolicy />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/terms-condition'
                    element={
                      <PrivateRoute>
                        <TermsAndCondition />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    exact
                    path='/delivery-informations'
                    element={
                      <PrivateRoute>
                        <DeliveryInformation />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    exact
                    path='*'
                    element={
                      <PrivateRoute>
                        <NotFound />
                      </PrivateRoute>
                    }
                  />
                </Routes>
                <Footer />
              </>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
