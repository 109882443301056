import axios from 'axios';
import config from '../config.js';

const API_URL = config.API_URL;

const headerCategory = values => {
  const token = values?.isToken;
  const action = 'afterlogin/home/headers';

  return axios.get(`${API_URL}${action}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const countable = values => {
  const token = values?.isToken;
  const action = 'afterlogin/home/countable';

  return axios.get(`${API_URL}${action}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const aboutus = values => {
  const token = values?.isToken;
  const action = 'afterlogin/home/about-us';

  return axios.get(`${API_URL}${action}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const addresslists = (values) => {
  const action = `afterlogin/account/address/address-dropdown`;
  const formData = new FormData();
  for (let value in values) {
      formData.append(value, values[value]);
  }
  return axios.post(API_URL + action, formData, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${values.isToken}`
      },
  });
};

const blogs = (values) => {
  const action = `afterlogin/blogs`;
  const formData = new FormData();
  for (let value in values) {
      formData.append(value, values[value]);
  }
  return axios.post(API_URL + action, formData, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${values.isToken}`
      },
  });
};

const blogdetail = (values) => {
  const action = `afterlogin/blogs/detail`;
  const formData = new FormData();
  for (let value in values) {
      formData.append(value, values[value]);
  }
  return axios.post(API_URL + action, formData, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${values.isToken}`
      },
  });
};

const policies = (values) => {
  const action = `afterlogin/privacy-policies`;
  const formData = new FormData();
  for (let value in values) {
      formData.append(value, values[value]);
  }
  return axios.post(API_URL + action, formData, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${values.isToken}`
      },
  });
};

const termsConditions = (values) => {
  const action = `afterlogin/terms-conditions`;
  const formData = new FormData();
  for (let value in values) {
      formData.append(value, values[value]);
  }
  return axios.post(API_URL + action, formData, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${values.isToken}`
      },
  });
};

const deliveryInformations = (values) => {
  const action = `afterlogin/delivery-informations`;
  const formData = new FormData();
  for (let value in values) {
      formData.append(value, values[value]);
  }
  return axios.post(API_URL + action, formData, {
      headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${values.isToken}`
      },
  });
};

const categoryApiController = {
  headerCategory,
  blogs,
  deliveryInformations,
  policies,
  blogdetail,
  termsConditions,
  countable,
  addresslists,
  aboutus
};

export default categoryApiController;
