import React, { useState } from 'react';
import { Box, Button, Card, Grid, Paper, TextField, Typography, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { authPasswordChangeApi } from '../../../store/Slices/authSlice';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useSweetAlert from '../../../hooks/sweetalert';

const PasswordChange = () => {
  const dispatch = useDispatch();
  const {showAlert} = useSweetAlert();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isToken = useSelector(state => state.authuser.isToken);
  const isApiStatus = useSelector(state => state.authuser.isApiStatus);
  const formLoading = isApiStatus && isApiStatus?.authPasswordChangeApi == 'loading';
  // Define Yup schema for validation
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const initialValues = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    try {
      dispatch(authPasswordChangeApi({ ...values, isToken })).then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          toast.success('Password updated successfully');
          resetForm();
        } else if (action.meta.requestStatus === 'rejected') {
          const errors = action?.payload?.errors ?? {};
          const message = action?.payload?.message ?? '';
          const status = action?.payload?.status ?? '';
          if (status === 422) {
            const formErrors = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0]; // Assuming you want to display the first error message for each field
              return acc;
            }, {});
            setErrors(formErrors);
          } else if (status === 410) {
            toast.error(message);
          } else if (status == 403){
            showAlert('warning', 'Oops...', message, false, 'OK');
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant='h4' sx={{ margin: '25px 0px' }}>
        Change Password
      </Typography>
      <Paper elevation={5}>
        <Card variant='outlined' sx={{ padding: '3rem' }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      name='old_password'
                      as={TextField}
                      type={showOldPassword ? 'text' : 'password'}
                      label='Old Password'
                      error={touched.old_password && !!errors.old_password}
                      helperText={touched.old_password && errors.old_password}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowOldPassword(!showOldPassword)}
                              edge="end"
                            >
                              {showOldPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name='new_password'
                      as={TextField}
                      type={showNewPassword ? 'text' : 'password'}
                      label='New Password'
                      error={touched.new_password && !!errors.new_password}
                      helperText={touched.new_password && errors.new_password}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowNewPassword(!showNewPassword)}
                              edge="end"
                            >
                              {showNewPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name='confirm_password'
                      as={TextField}
                      type={showConfirmPassword ? 'text' : 'password'}
                      label='Confirm Password'
                      error={touched.confirm_password && !!errors.confirm_password}
                      helperText={touched.confirm_password && errors.confirm_password}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type='submit' variant='contained' color='secondary' size="large">
                    {formLoading && <CircularProgress color='inherit' size={20} sx={{ marginRight:1 }}/>}Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      </Paper>
    </Box>
  );
};

export default PasswordChange;
