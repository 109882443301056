import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import { Box, Button, Divider } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import bannerImg from '../../assets/images/banner1.jpg';
import { Link, useParams } from 'react-router-dom';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { shopPageApi } from '../../store/Slices/productSlice';
import { OpenCategoryFilter, OpenFilter } from '../../store/Slices/categorySlice';
import useWindowWidth from '../../hooks/window';

function valuetext(value) {
  return `${value}°C`;
}
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Sidebar = props => {
  const width = useWindowWidth();
  const [value, setValue] = useState([100, 60000]);
  const [value2, setValue2] = useState(0);
  const [brandFilters, setBrandFilters] = React.useState([]);
  const [ratingsArr, setRatings] = React.useState([]);
  const [totalLength, setTotalLength] = useState([]);
  const brandings = props && props.brands ? props.brands : [];

  const dispatch = useDispatch();
  const isOpenFilter = useSelector(state => state.category.isOpenFilter);
  let { id } = useParams();
  const isToken = useSelector(state => state.authuser.isToken);
  const isOpenFilters = false;
  var brands = [];
  var ratings = [];

  var catLength = 0;
  var lengthArr = [];
  useEffect(() => {
    props.data.length !== 0 &&
      props.data.map((item, index) => {
        // item.items.length !== 0 &&
        //     item.items.map((item_) => {
        //         catLength += item_.products.length
        //     })
        lengthArr.push(item?.product_count);
        catLength = 0;
      });

    const list = lengthArr.filter((item, index) => lengthArr.indexOf(item) === index);
    setTotalLength(list);
  }, []);

  const filterByBrand = keyword => {
    dispatch(shopPageApi({ isToken, brand_id: keyword?.id }));
    // props.filterByBrand(keyword);
  };

  useEffect(() => {
    filterByPrice(value[0], value[1]);
  }, [value]);

  const filterByPrice = (minValue, maxValue) => {
    setTimeout(() => {
      dispatch(shopPageApi({ isToken, range_slider: [minValue, maxValue] }));
    }, 500);
    // props.filterByPrice(minValue, maxValue);
  };

  return (
    <>
      <div className={`sidebar ${isOpenFilter ? 'open' : ''}`}>
        <div className='card border-0 shadow res-hide'>
          <h3>Category</h3>
          <div className='catList'>
            {props.data.length !== 0 &&
              props.data.map((item, index) => {
                return (
                  <div
                    className='catItem d-flex align-items-center'
                    onClick={() => {
                      dispatch(shopPageApi({ isToken, category_id: item?.id }));
                    }}
                  >
                    <span className='img'>
                      <img
                        src='https://wp.alithemes.com/html/nest/demo/assets/imgs/theme/icons/category-1.svg'
                        width={30}
                      />
                    </span>
                    <h4 className='mb-0 ml-3 mr-3 text-capitalize'>{item.title}</h4>
                    <span className='d-flex align-items-center justify-content-center rounded-circle ml-auto'>
                      {item?.product_count}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>

        <div className={`card border-0 shadow `}>
          {width <= 992 ? (
            <Box display={'flex'} justifyContent={'center'}>
              <div
                style={{ borderBottom: '8px solid #EEE', width: '200px', cursor: 'pointer' }}
                onClick={() => dispatch(OpenFilter())}
              ></div>
            </Box>
          ) : null}
          <h3>Fill by price</h3>
          <RangeSlider value={value} onInput={setValue} min={100} max={60000} step={10} />

          <div className='d-flex pt-2 pb-2 priceRange'>
            <span>
              From: <strong className='text-success'>Rs: {value[0]}</strong>
            </span>
            <span className='ml-auto'>
              From: <strong className='text-success'>Rs: {value[1]}</strong>
            </span>
          </div>

          <div className='filters pt-5'>
            <h5>Filter By Brand</h5>

            <ul className='mb-0'>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='female'
                name='radio-buttons-group'
              >
                {brandings.length !== 0 &&
                  brandings.map((item, index) => {
                    return (
                      <li key={index}>
                        {' '}
                        <FormControlLabel
                          value={item?.id}
                          control={<Radio onChange={() => filterByBrand(item)} />}
                          label={item?.title}
                        />
                      </li>
                    );
                  })}
              </RadioGroup>
            </ul>
          </div>

          <div className='filters pt-0'>
            <h5>Filter By Ratings</h5>
            <ul>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='female'
                name='radio-buttons-group'
              >
                {[1, 2, 3, 4, 5].map((item, index) => {
                  return (
                    <li key={index}>
                      {' '}
                      <FormControlLabel
                        value={item}
                        control={<Radio  />}
                        label={item}
                      />
                    </li>
                  );
                })}
              </RadioGroup>
            </ul>
          </div>
          
          <div className='d-flex filterWrapper'>
            <Button
              className='btn btn-g w-100'
              onClick={() => {
                dispatch(OpenCategoryFilter());
                dispatch(OpenFilter());
              }}
            >
              <FilterAltOutlinedIcon /> Filter
            </Button>
          </div>
          {width <= 992 ? (
            <Box display={'flex'} justifyContent={'center'}>
            <Button variant='contained' color='error' fullWidth sx={{ marginTop:3,padding:'0.9rem 2rem' }} onClick={() => dispatch(OpenFilter())}>Close</Button>
            </Box>
          ) : null}
        </div>

        <img src={bannerImg} className='w-100' />
      </div>
    </>
  );
};

export default Sidebar;
