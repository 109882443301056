import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import useWindowWidth from '../../hooks/window';
import { contactUsApi } from '../../store/Slices/authSlice';
import useSweetAlert from '../../hooks/sweetalert';
import { useDispatch, useSelector } from 'react-redux';

const ContactUs = () => {
  const dispatch = useDispatch();
  const isToken = useSelector(state => state.authuser.isToken);
  const isApiStatus = useSelector((state)=>state.authuser.isApiStatus);
  const formSubmitLoading = isApiStatus && isApiStatus?.contactUsApi == 'loading';
  const { showAlert } = useSweetAlert();
  const width = useWindowWidth();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone_number: Yup.number(),
    message: Yup.string().required('Message is required')
  });

  const initialValues = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    message: ''
  };

  const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    try {
      dispatch(contactUsApi({ ...values, isToken })).then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          showAlert('success', 'Success', 'Message send successfully.', false, 'Ok');
          resetForm();
        } else if (action.meta.requestStatus == 'rejected') {
          const errors = action?.payload?.errors ?? {};
          const message = action?.payload?.message ?? '';
          const status = action?.payload?.status ?? '';
          if (status == 422) {
            const formErrors = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0];
              return acc;
            }, {});
            setErrors(formErrors);
          } else if (status == 410) {
            toast.error(message);
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ margin: width > 992 ? '270px 45px 40px 45px' : '120px 45px 40px 45px' }}>
      <Grid container spacing={4} marginBottom={10}>
        <Grid item xs={12} md={6}>
          <Box mb={4}>
            <Typography variant='h4' component='h1' gutterBottom>
              Contact Us
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2 }} component={Paper} elevation={2}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box
                      component={Paper}
                      elevation={3}
                      sx={{
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#3bb77e'
                      }}
                    >
                      <PhoneIcon sx={{ color: '#FFF' }} />
                    </Box>
                    <Typography component='div' variant='h5'>
                      +123456789
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2 }} component={Paper} elevation={2}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box
                      component={Paper}
                      elevation={3}
                      sx={{
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#3bb77e'
                      }}
                    >
                      <EmailIcon sx={{ color: '#FFF' }} />
                    </Box>
                    <Typography component='div' variant='h5'>
                      developer@test.com
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2 }} component={Paper} elevation={2}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box
                      component={Paper}
                      elevation={3}
                      sx={{
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#3bb77e'
                      }}
                    >
                      <WorkIcon sx={{ color: '#FFF' }} />
                    </Box>
                    <Typography component='div' variant='h5'>
                      09:00AM - 07:00PM
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2 }} component={Paper} elevation={2}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box
                      component={Paper}
                      elevation={3}
                      sx={{
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#3bb77e'
                      }}
                    >
                      <FmdGoodIcon sx={{ color: '#FFF' }} />
                    </Box>
                    <Typography component='div' variant='h5'>
                      123 berry street india
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card component={Paper} variant='outlined' elevation={5} sx={{ padding: '24px', borderRadius: '16px' }}>
            <Box padding={2}>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ errors, touched, values, setFieldValue }) => {
                  return (
                    <Form>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name='first_name'
                            as={TextField}
                            label='First Name'
                            error={touched.first_name && !!errors.first_name}
                            helperText={touched.first_name && errors.first_name}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name='last_name'
                            as={TextField}
                            label='Last Name'
                            error={touched.last_name && !!errors.last_name}
                            helperText={touched.last_name && errors.last_name}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name='email'
                            type='email'
                            as={TextField}
                            label='Email'
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name='phone_number'
                            as={TextField}
                            type={'number'}
                            label='Mobile'
                            error={touched.phone_number && !!errors.phone_number}
                            helperText={touched.phone_number && errors.phone_number}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name='message'
                            label='Message'
                            multiline
                            rows={4}
                            fullWidth
                            variant='outlined'
                            error={touched.message && Boolean(errors.message)}
                            helperText={touched.message && errors.message}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type='submit'
                            variant='outlined'
                            fullWidth
                            sx={{
                              background: '#3bb77e',
                              color: '#FFF',
                              borderColor: '#3bb77e',
                              marginRight: 2,
                              '&:hover': {
                                borderColor: '#3bb77e',
                                color: '#3bb77e'
                              }
                            }}
                          >
                            {formSubmitLoading && <CircularProgress color='inherit' size={20} sx={{ marginRight:1 }}/>}Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={4} marginTop={7} marginBottom={10}>
        <Grid item xs={12} md={6}>
          <Box component={Paper} elevation={5} sx={{ padding: '10px 10px 2px 10px', borderRadius: '24px' }}>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d29375.999017312555!2d72.575886855542!3d23.02377672717336!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1721727895149!5m2!1sen!2sin'
              width='100%'
              height='820'
              style={{ border: 0, outline: 0, borderRadius: '24px', boxShadow: 'rgba(0, 0, 0, 0.2) 4px 4px 6px' }}
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant='h5' gutterBottom>
            Find Us Here
          </Typography>
          <Typography variant='body1' paragraph>
            We are located in the heart of the city. Come visit us at our main office for any inquiries or support. Our
            team is ready to assist you with any questions or concerns you might have.
          </Typography>
          <Typography variant='body1' paragraph>
            <strong>Office Address:</strong>
            <br />
            EMARKET APP Headquarters,
            <br />
            123 Main Street,
            <br />
            Downtown City, 12345
          </Typography>
          <Typography variant='body1' paragraph>
            <strong>Office Hours:</strong>
            <br />
            Monday to Friday: 9:00 AM - 6:00 PM
            <br />
            Saturday: 10:00 AM - 4:00 PM
            <br />
            Sunday: Closed
          </Typography>
          <Typography variant='body1' paragraph>
            <strong>Contact Information:</strong>
            <br />
            Phone: (123) 456-7890
            <br />
            Email: support@emarketapp.com
          </Typography>
          <Typography variant='body1'>
            Feel free to reach out to us through phone or email during our office hours. We strive to respond to all
            inquiries within 24 hours.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
