import React, { useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Divider,
  CircularProgress
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { useDispatch } from 'react-redux';
import { OpenBlogDetailModal, blogDetailApi } from '../../store/Slices/categorySlice';
import { motion } from 'framer-motion';

const BlogListDensed = ({ isBlogData, isToken }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState({});

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {isBlogData?.blogs?.data?.map((blog, index) => (
        <motion.div whileHover={{ scale: 1,boxShadow:'0px 4px 4px 4px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' }} key={blog?.id}>
          <ListItem
            alignItems='center'
            sx={{ border: '1px solid #EEE', marginBottom: '8px', padding: '16px', position: 'relative' }}
          >
            <ListItemAvatar>
              <Avatar
                variant='square'
                src={`${blog.blogs_url}`}
                alt={blog.title}
                sx={{ width: 100, height: 100, objectFit: 'contain', marginRight: '16px' }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant='h6'
                  component='h2'
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {blog.title}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.primary'>
                    Author: {`${blog.first_name} ${blog.last_name}`}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {blog.sort_description}
                  </Typography>
                </React.Fragment>
              }
            />
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
              <IconButton
                aria-label='view'
                sx={{ marginRight: 1 }}
                onClick={() => {
                  setLoader(prevLoader => ({ ...prevLoader, [index]: true }));
                  dispatch(blogDetailApi({ isToken, id: blog?.id })).then(() => {
                    setLoader(prevLoader => ({ ...prevLoader, [index]: false }));
                    dispatch(OpenBlogDetailModal(true));
                  });
                }}
              >
                {loader[index] ? (
                  <CircularProgress size={24} color='warning' />
                ) : (
                  <RemoveRedEyeIcon color='secondary' />
                )}
              </IconButton>
              <IconButton aria-label='favorite' sx={{ marginRight: 1 }}>
                <FavoriteIcon sx={{ color: '#E32020' }} />
              </IconButton>
              <IconButton aria-label='share'>
                <ShareIcon sx={{ color: '#0085FF' }} />
              </IconButton>
            </Box>
          </ListItem>
        </motion.div>
      ))}
    </List>
  );
};

export default BlogListDensed;
