import React from 'react';
import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material';
import useWindowWidth from '../../hooks/window';
import { motion } from 'framer-motion';
import ProfileUpdate from './Account/ProfileUpdate';
import WishList from './Wishlist/WishList';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileTab, removeProductFromWishListApi, userWishListApi } from '../../store/Slices/profileSlice';
import useSweetAlert from '../../hooks/sweetalert';
import { countableItemApi } from '../../store/Slices/categorySlice';

import './profile.css';
import Address from './Address/Address';
import OrderList from './MyOrder/OrderList';
import PasswordChange from './PasswordChange/PasswordChange';

const Profile = () => {
  const { showAlert } = useSweetAlert();
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const isProfileTab = useSelector(state => state.profile.isProfileTab);
  const isToken = useSelector(state => state.authuser.isToken);
  const isUserWishList = useSelector(state => state.profile.isUserWishList);
  const wishlistData = isUserWishList?.wishlist || {};
  const wishlist = wishlistData?.data || [];

  const handleChange = (event, newValue) => {
    dispatch(ProfileTab(newValue));
  };

  return (
    <div style={{ marginTop: width < 991 ? '115px' : '0px' }}>
      <div className='container-fluid mt-5'>
        {width < 991 ? <Divider /> : null}
        <Box
          sx={{
            display: 'flex',
            flexDirection: width < 991 ? 'column' : 'row',
            height: width < 991 ? 'auto' : '100vh'
          }}
        >
          <Tabs
            orientation={width < 991 ? 'horizontal' : 'vertical'}
            variant='scrollable'
            value={isProfileTab}
            onChange={handleChange}
            aria-label='Profile tabs'
            sx={{
              borderRight: width < 991 ? 'none' : 1,
              borderBottom: width < 991 ? 1 : 'none',
              borderColor: 'divider',
              width: width < 991 ? '100%' : '200px',
              height: '100%'
            }}
          >
            <Tab label='My Profile' />
            <Tab label='My Address' />
            <Tab label='My Orders' />
            <Tab label='My Wishlist' />
            <Tab label='Change Password' />
          </Tabs>
          <Box sx={{ flexGrow: 1, p: 3 }}>
            {isProfileTab === 0 && (
              <motion.div
                key='profile'
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                style={{ width: '100%' }}
              >
                <Box>{isProfileTab === 0 && <ProfileUpdate />}</Box>
              </motion.div>
            )}
            {isProfileTab === 1 && (
              <motion.div
                key='address'
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                style={{ width: '100%' }}
              >
                <Box>{isProfileTab === 1 && <Address />}</Box>
              </motion.div>
            )}
            {isProfileTab === 2 && (
              <motion.div
                key='orders'
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                style={{ width: '100%' }}
              >
                <Box>{isProfileTab === 2 && <OrderList />}</Box>
              </motion.div>
            )}
            {isProfileTab === 3 && (
              <motion.div
                key='wishlist'
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                style={{ width: '100%' }}
              >
                <Box>
                  {wishlist.length > 0 ? (
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography variant='h4'>My Wishlist</Typography>
                      <Button
                        variant='contained'
                        color='error'
                        onClick={() => {
                          showAlert(
                            'warning',
                            'Warning',
                            'Are you sure you want to remove all product from wishlist.',
                            true,
                            'YES'
                          ).then(result => {
                            if (result.isConfirmed) {
                              dispatch(removeProductFromWishListApi({ isToken, remove_all: 1 })).then(action => {
                                if (action?.meta?.requestStatus === 'fulfilled') {
                                  dispatch(countableItemApi({ isToken })).then(() => {
                                    dispatch(userWishListApi({ isToken })).then(() => {
                                      showAlert('success', 'Success', 'All Product Removed From WishList', false, 'OK');
                                    });
                                  });
                                }
                              });
                            }
                          });
                        }}
                      >
                        Remove All
                      </Button>
                    </Box>
                  ) : null}
                  {isProfileTab === 3 && <WishList />}
                </Box>
              </motion.div>
            )}
            {isProfileTab === 4 && (
                  <motion.div
                    key='orders'
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4 }}
                    style={{ width: '100%' }}
                  >
                    <Box>{isProfileTab === 4 && <PasswordChange />}</Box>
                  </motion.div>
                )}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Profile;
