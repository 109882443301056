import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import productApiController from '../../services/product.service.js';

export const productDetailApi = createAsyncThunk('product/detail', async (formValues, thunkAPI) => {
  try {
    const response = await productApiController.detail(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const shopPageApi = createAsyncThunk('product/shop', async (formValues, thunkAPI) => {
  try {
    const response = await productApiController.shop(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const productSearchApi = createAsyncThunk('product/search', async (formValues, thunkAPI) => {
  try {
    const response = await productApiController.search(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});
export const productAddCompareApi = createAsyncThunk('product/addToCompare', async (formValues, thunkAPI) => {
  try {
    const response = await productApiController.addcompare(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const productGetCompareListApi = createAsyncThunk('product/getCompare', async (formValues, thunkAPI) => {
  try {
    const response = await productApiController.getcompare(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const productRemoveFromCompareApi = createAsyncThunk('product/removeFromCompare', async (formValues, thunkAPI) => {
  try {
    const response = await productApiController.removecompare(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const productRatingStoreApi = createAsyncThunk('product/rate/store', async (formValues, thunkAPI) => {
  try {
    const response = await productApiController.ratestore(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

const initialState = {
  isProductDetail: '',
  isShopProducts:[],
  isProductSearch:[],
  isComparedProduct:[],
  isCategoryName:{},
  isApiStatus: {
    productDetailApi: '',
    productSearchApi:[],
    shopPageApi:"",
    productAddCompareApi:"",
    productGetCompareListApi:"",
    productRatingStoreApi:"",
    productRemoveFromCompareApi:""
  }
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    reset: () => initialState,
    ProductCompare: (state, action) => {
      const existingIndex = state.isComparedProduct.findIndex(item => item.id === action.payload.id);
      if (existingIndex !== -1) {
        state.isComparedProduct[existingIndex] = action.payload;
      } else {
        state.isComparedProduct.push(action.payload);
      }
    },
    RemoveProductFromCompare: (state, action) => {
      const productIdToRemove = action.payload.id;
      state.isComparedProduct.products = state?.isComparedProduct?.products.filter(item => item.product_id !== productIdToRemove);
    },
    RemoveAllProductFromCompare: (state, action) => {
      state.isComparedProduct.products = [];
    },
    selectedCategoryGet: (state, action) => {
      state.isCategoryName = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(productRatingStoreApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, productRatingStoreApi: 'loading' };
      })
      .addCase(productRatingStoreApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, productRatingStoreApi: 'succeeded' };
      })
      .addCase(productRatingStoreApi.rejected, state => {
        state.isApiStatus = { ...state.isApiStatus, productRatingStoreApi: 'failed' };
      })
      .addCase(productGetCompareListApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, productGetCompareListApi: 'loading' };
      })
      .addCase(productGetCompareListApi.fulfilled, (state, action) => {
        state.isComparedProduct = action.payload;
        state.isApiStatus = { ...state.isApiStatus, productGetCompareListApi: 'succeeded' };
      })
      .addCase(productGetCompareListApi.rejected, state => {
        state.isComparedProduct = [];
        state.isApiStatus = { ...state.isApiStatus, productGetCompareListApi: 'failed' };
      })
      .addCase(productRemoveFromCompareApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, productRemoveFromCompareApi: 'loading' };
      })
      .addCase(productRemoveFromCompareApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, productRemoveFromCompareApi: 'succeeded' };
      })
      .addCase(productRemoveFromCompareApi.rejected, state => {
        state.isApiStatus = { ...state.isApiStatus, productRemoveFromCompareApi: 'failed' };
      })
      .addCase(productAddCompareApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, productAddCompareApi: 'loading' };
      })
      .addCase(productAddCompareApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, productAddCompareApi: 'succeeded' };
      })
      .addCase(productAddCompareApi.rejected, state => {
        state.isApiStatus = { ...state.isApiStatus, productAddCompareApi: 'failed' };
      })
      .addCase(productSearchApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, productSearchApi: 'loading' };
      })
      .addCase(productSearchApi.fulfilled, (state, action) => {
        state.isProductSearch = action.payload;
        state.isApiStatus = { ...state.isApiStatus, productSearchApi: 'succeeded' };
      })
      .addCase(productSearchApi.rejected, state => {
        state.isProductSearch = [];
        state.isApiStatus = { ...state.isApiStatus, productSearchApi: 'failed' };
      })
      .addCase(shopPageApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, shopPageApi: 'loading' };
      })
      .addCase(shopPageApi.fulfilled, (state, action) => {
        state.isShopProducts = action.payload;
        state.isApiStatus = { ...state.isApiStatus, shopPageApi: 'succeeded' };
      })
      .addCase(shopPageApi.rejected, state => {
        state.isShopProducts = [];
        state.isApiStatus = { ...state.isApiStatus, shopPageApi: 'failed' };
      })
      .addCase(productDetailApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, productDetailApi: 'loading' };
      })
      .addCase(productDetailApi.fulfilled, (state, action) => {
        state.isProductDetail = action.payload;
        state.isApiStatus = { ...state.isApiStatus, productDetailApi: 'succeeded' };
      })
      .addCase(productDetailApi.rejected, state => {
        state.isProductDetail = '';
        state.isApiStatus = { ...state.isApiStatus, productDetailApi: 'failed' };
      });
  }
});

export const { reset, ProductCompare, RemoveProductFromCompare, RemoveAllProductFromCompare,selectedCategoryGet } = productSlice.actions;
export default productSlice.reducer;
