import axios from 'axios';
import config from '../config.js';

const API_URL = config.API_URL;

const detail = values => {
  const token = values?.isToken;
  const action = `afterlogin/product/details/${values?.id}`;

  return axios.get(`${API_URL}${action}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const shop = values => {
  const token = values?.isToken;
  const action = `afterlogin/shop`;
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    if (['range_slider'].includes(key)) {
      formData.append(key, JSON.stringify(values[key]));
    } else {
      formData.append(key, values[key]);
    }
  });

  return axios.post(`${API_URL}${action}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};

const search = values => {
  const token = values?.isToken;
  const action = `afterlogin/product/search`;
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  return axios.post(`${API_URL}${action}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};
const addcompare = values => {
  const token = values?.isToken;
  const action = `afterlogin/product/add-to-compare/${values?.id}`;
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  return axios.post(`${API_URL}${action}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};
const getcompare = values => {
  const token = values?.isToken;
  const action = `afterlogin/product/compare-list`;
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  return axios.post(`${API_URL}${action}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};
const removecompare = values => {
  const token = values?.isToken;
  const action = `afterlogin/product/remove-compare`;
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  return axios.post(`${API_URL}${action}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};

const ratestore = values => {
  const token = values?.isToken;
  const action = `afterlogin/product/rate/store`;
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });

  return axios.post(`${API_URL}${action}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    }
  });
};

const productApiController = {
  ratestore,
  detail,
  shop,
  search,
  addcompare,
  getcompare,
  removecompare
};

export default productApiController;
