import React, { useEffect } from 'react';
import './nav.css';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GridViewIcon from '@mui/icons-material/GridView';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { aboutUsApi, countableItemApi, headerCategoryApi } from '../../../store/Slices/categorySlice';
import Skeleton from 'react-loading-skeleton';
import { shopPageApi } from '../../../store/Slices/productSlice';
import useWindowWidth from '../../../hooks/window';

const Nav = props => {
  const dispatch = useDispatch();
  const [navData, setNavData] = useState([]);
  const width = useWindowWidth();
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [openDropdownMenu, setDropdownMenu] = useState(false);
  const [openDropdownMenuIndex, setDropdownMenuIndex] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [openMegaMenu, setOpenMegaMenu] = useState(false);
  const isToken = useSelector(state => state.authuser.isToken);
  const user = useSelector(state => state.authuser.user);
  const isHeaderCategoryList = useSelector(state => state.category.isHeaderCategoryList);
  const category =
    isHeaderCategoryList && isHeaderCategoryList?.categories && isHeaderCategoryList?.categories?.length > 0
      ? isHeaderCategoryList?.categories
      : [];
  const isApiStatus = useSelector(state => state.category.isApiStatus);
  const isLoading = isApiStatus && isApiStatus.headerCategoryApi == 'loading';

  useEffect(() => {
    setNavData(props.data);
    if (isToken) {
      dispatch(headerCategoryApi({ isToken }));
      dispatch(countableItemApi({ isToken }));
    }
  }, [isToken]);

  useEffect(() => {
    setIsOpenNav(props.openNav);
  }, [props.openNav]);

  const closeNav = () => {
    props.closeNav();
  };

  const openDropdownFun = index => {
    setDropdownMenu(!openDropdownMenu);
    setDropdownMenuIndex(index);
  };

  return (
    <>
      {isDropdownOpen === true && <div 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000
    }}
       onClick={()=>setIsDropdownOpen(false)}></div>}
      {isOpenNav === true && <div className='navbarOverlay' onClick={props.closeNav}></div>}
      <div className={`nav d-flex align-items-center ${isOpenNav === true && 'click'}`}>
        <div className='container-fluid'>
          <div className='row position-relative'>
            <div className='position-relative d-flex align-items-center'>
              <div>
                <Button className='bg-g text-white catTab res-hide' onClick={toggleDropdown}>
                  <GridViewIcon /> &nbsp;Browse All Categories <KeyboardArrowDownIcon />
                </Button>
              </div>
              {isDropdownOpen && (
                <div className='position-absolute dropdown-wrapper'>
                  <ul>
                    {category.length > 0 && !isLoading ? (
                      category.map((item, index) => (
                        <li key={index} onClick={() => {
                          dispatch(shopPageApi({ isToken, category_id: item?.id })).then(() => {
                            setIsDropdownOpen(false);
                            navigate(`/cat/${item.title.toLowerCase().replace(/\s+/g, '-')}`);
                          });
                        }}>
                          <a href='javascript:void(0)'>{item.title}</a>
                        </li>
                      ))
                    ) : (
                      <li>Loading...</li>
                    )}
                  </ul>
                </div>
              )}
            </div>
            <div className='col-sm-8 part2 position-static'>
              <nav className={isOpenNav === true ? 'open' : ''}>
                <ul className='list list-inline mb-0 scroll-container'>
                  {!isLoading ? (
                    <li className='list-inline-item'>
                      <Button>
                        <Link to={'/'} onClick={props.closeNav}>
                          Home
                        </Link>
                      </Button>
                    </li>
                  ) : (
                    ''
                  )}
                  {category.length > 0 && !isLoading ? (
                    category.map((item, index) => {
                      const subcategories =
                        item?.subcategories && item?.subcategories?.length > 0 ? item?.subcategories : [];
                      return (
                        <li className={`${width > 992 ? 'list-inline-item' : ''}`} key={index}>
                          <Button onClick={() => openDropdownFun(index)}>
                            <a
                              href='javascript:void(0)'
                              onClick={() => {
                                if (width > 992) {
                                  dispatch(shopPageApi({ isToken, category_id: item?.id })).then(() => {
                                    navigate(`/cat/${item.title.toLowerCase()}`);
                                  });
                                }
                              }}
                            >
                              {item.title}
                              <KeyboardArrowDownIcon
                                className={`${
                                  openDropdownMenu === true && openDropdownMenuIndex === index && 'rotateIcon'
                                }`}
                              />
                            </a>
                          </Button>
                          {subcategories.length !== 0 && (
                            <div
                              className={`dropdown_menu ${
                                openDropdownMenu === true && openDropdownMenuIndex === index && 'open'
                              }`}
                            >
                              <ul>
                                {subcategories.map((item_, index_) => {
                                  return (
                                    <li key={index_}>
                                      <Button onClick={props.closeNav}>
                                        <a
                                          href='javascript:void(0)'
                                          onClick={() => {
                                            dispatch(shopPageApi({ isToken, sub_category_id: item_?.id })).then(() => {
                                              navigate(
                                                `/cat/${item.title.toLowerCase()}/${item_.title
                                                  .replace(/\s/g, '-')
                                                  .toLowerCase()}`
                                              );
                                            });
                                          }}
                                        >
                                          {item_.title}
                                        </a>
                                      </Button>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </li>
                      );
                    })
                  ) : (
                    <div className='mt-0 pt-0'>
                      <Skeleton height={50} count={1} highlightColor='#def9ec' baseColor='#f9f6f4' />
                    </div>
                  )}
                  {!isLoading ? (
                    <>
                      <li className='list-inline-item cursor-pointer' onClick={()=>{
                         props.closeNav();
                         navigate('/about-us');
                      }}>
                        <a style={{ padding:'25px' }}>About</a>
                      </li>

                      <li className='list-inline-item position-static'>
                        <Button onClick={() => setOpenMegaMenu(!openMegaMenu)}>
                          <Link>
                            Shop <KeyboardArrowDownIcon className={`${openMegaMenu === true && 'rotateIcon'}`} />
                          </Link>
                        </Button>
                        <div className={`dropdown_menu megaMenu w-100 ${openMegaMenu === true && 'open'}`}>
                          <div className='row'>
                            {category.length !== 0 &&
                              category.map((item, index) => {
                                return (
                                  <div className='col'>
                                    <a
                                      href='javascript:void(0)'
                                      onClick={() => {
                                        dispatch(shopPageApi({ isToken, category_id: item?.id })).then(() => {
                                          navigate(`/cat/${item.title.toLowerCase()}`);
                                        });
                                      }}
                                    >
                                      <h4 className='text-g text-capitalize'>{item.title}</h4>
                                    </a>
                                    {item.subcategories.length !== 0 && (
                                      <ul className='mt-4 mb-0'>
                                        {item.subcategories &&
                                          item.subcategories.length > 0 &&
                                          item.subcategories.map((item_, index) => {
                                            return (
                                              <li>
                                                <a
                                                  className='cursor-pointer'
                                                  onClick={() => {
                                                    dispatch(shopPageApi({ isToken, sub_category_id: item_?.id })).then(
                                                      () => {
                                                        navigate(
                                                          `/cat/${item.title.toLowerCase()}/${item_.title
                                                            .replace(/\s/g, '-')
                                                            .toLowerCase()}`
                                                        );
                                                        props.closeNav();
                                                      }
                                                    );
                                                  }}
                                                >
                                                  {item_.title}
                                                </a>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    )}
                                  </div>
                                );
                              })}
                            <div className='col'>
                              <img
                                src='https://wp.alithemes.com/html/nest/demo/assets/imgs/banner/banner-menu.png'
                                className='w-100'
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className='list-inline-item'>
                        <Button>
                          <Link to={'/blogs'}>Blog</Link>
                        </Button>
                      </li>
                      <li className='list-inline-item' onClick={()=>{
                        navigate('/contact-us')
                      }}>
                          <a style={{ padding:'25px' }}>Contact</a>
                      </li>
                    </>
                  ) : (
                    ''
                  )}
                </ul>

                {width < 992 && (
                  <>
                    {!user && (
                      <div className='pl-3 pr-3'>
                        <br />
                        <Link to={'/signIn'}>
                          <Button className='btn btn-g btn-lg w-100' onClick={closeNav}>
                            Sign In
                          </Button>
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </nav>
            </div>
            {width > 1263 ? (
              <div className='col-sm-2 part3 d-flex align-items-center'>
                <div className='phNo d-flex align-items-center ml-auto'>
                  <span>
                    <HeadphonesOutlinedIcon />
                  </span>
                  <div className='info ml-3'>
                    <h3 className='text-g mb-0'>1900 - 888</h3>
                    <p className='mb-0'>24/7 Support Center</p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
