import React, { useEffect, useRef } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch, useSelector } from 'react-redux';
import { OrderDetailModal } from '../../../store/Slices/profileSlice';
import Slide from '@mui/material/Slide';
import useWindowWidth from '../../../hooks/window';
import ReactToPrint from 'react-to-print';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const PrintableInvoice = React.forwardRef(({ order, dummyAddress, windowWidth }, ref) => (
  <div ref={ref}>
    <Grid container justifyContent='center' sx={{ marginTop: '90px' }}>
      <Grid item xs={12} md={8}>
        <Paper elevation={windowWidth > 900 ? 5 : 0} sx={{ borderRadius: 2, padding: '24px', marginBottom: 8 }}>
          <Stack display={'flex'} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
            <Box>
              <Avatar src={order?.user?.profile_url} sx={{ width: 80, height: 80 }} />
            </Box>
            <Box>
              <Typography variant='body1'>
                Order Status:{' '}
                {order?.order_status === 'pending' && <Chip label='Pending' color='primary' size='small' />}
                {order?.order_status === 'shipped' && <Chip label='Shipped' color='secondary' size='small' />}
                {order?.order_status === 'delivered' && <Chip label='Delivered' color='success' size='small' />}
                {order?.order_status === 'cancelled' && <Chip label='Cancelled' color='error' size='small' />}
              </Typography>
            </Box>
          </Stack>
          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item xs={12} md={6}>
              <Typography variant='h6'>Invoice To</Typography>
              <Stack spacing={1}>
                <Typography variant='body1'>
                  {order?.customer_address?.first_name} {order?.customer_address?.last_name}
                </Typography>
                <Typography variant='body2'>{order?.customer_address?.address}</Typography>
                <Typography variant='body2'>{order?.customer_address?.apartment}</Typography>
                <Typography variant='body2'>
                  {order?.customer_address?.city}, {order?.customer_address?.state}, {order?.customer_address?.zip}
                </Typography>
                <Typography variant='body2'>Email: {order?.customer_address?.email}</Typography>
                <Typography variant='body2'>Mobile: {order?.customer_address?.mobile}</Typography>
                <Typography variant='body1' gutterBottom>
                  Order Date: {new Date(order?.created_at).toLocaleString()}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: 'end' }}>
              <Typography variant='h6'>Invoice From</Typography>
              <Stack spacing={1}>
                <Typography variant='body1'>{dummyAddress?.name}</Typography>
                <Typography variant='body2'>{dummyAddress?.address}</Typography>
                <Typography variant='body2'>{dummyAddress?.apartment}</Typography>
                <Typography variant='body2'>
                  {dummyAddress?.city}, {dummyAddress?.state}, {dummyAddress?.zip}
                </Typography>
                <Typography variant='body2'>Email: {dummyAddress?.email}</Typography>
                <Typography variant='body2'>Mobile: {dummyAddress?.mobile}</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Typography variant='h6' sx={{ marginTop: '24px' }} gutterBottom>
            Ordered Items
          </Typography>
          <TableContainer component={Paper} sx={{ margin: '16px 0px 40px 0px' }}>
            <Table>
              <TableHead sx={{ background: '#f4f6f8' }}>
                <TableRow>
                  <TableCell sx={{ color: '#828f9b' }}>Image</TableCell>
                  <TableCell sx={{ color: '#828f9b' }}>Product Name</TableCell>
                  <TableCell sx={{ color: '#828f9b' }}>Price</TableCell>
                  <TableCell sx={{ color: '#828f9b' }}>Quantity</TableCell>
                  <TableCell sx={{ color: '#828f9b' }}>Total Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.items?.map((item, i) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      borderBottom: i !== order.items.length - 1 ? '3px dashed rgba(145, 158, 171, 0.2)' : ''
                    }}
                  >
                    <TableCell
                      sx={{
                        borderBottom: i !== order.items.length - 1 ? '3px dashed rgba(145, 158, 171, 0.2)' : ''
                      }}
                    >
                      <Avatar
                        alt={item?.product?.title}
                        src={`${item?.product?.storage_url}/${item?.product?.thumbnail_image}`}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: i !== order.items.length - 1 ? '3px dashed rgba(145, 158, 171, 0.2)' : ''
                      }}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: i !== order.items.length - 1 ? '3px dashed rgba(145, 158, 171, 0.2)' : ''
                      }}
                    >
                      ${item.price}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: i !== order.items.length - 1 ? '3px dashed rgba(145, 158, 171, 0.2)' : ''
                      }}
                    >
                      {item.quantity}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: i !== order.items.length - 1 ? '3px dashed rgba(145, 158, 171, 0.2)' : ''
                      }}
                    >
                      ${item.total_price}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justifyContent='flex-end' marginBottom={4}>
            <Grid item>
              <Stack spacing={3} alignItems='flex-end'>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  width='200px'
                  sx={{ marginTop: '0px !important' }}
                >
                  <Typography variant='subtitle1'>Total Product</Typography>
                  <Typography>{order?.items && order?.items?.length > 0 ? order?.items?.length : 0}</Typography>
                </Stack>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  width='200px'
                  sx={{ marginTop: '0px !important' }}
                >
                  <Typography variant='subtitle1'>Total</Typography>
                  <Typography>${order?.total ?? 0}</Typography>
                </Stack>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  width='200px'
                  sx={{ marginTop: '0px !important' }}
                >
                  <Typography variant='subtitle1'>Shipping Charge</Typography>
                  <Typography>${order?.shipping_charge}</Typography>
                </Stack>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  width='200px'
                  sx={{ marginTop: '0px !important' }}
                >
                  <Typography variant='subtitle1'>Discount</Typography>
                  <Typography>${order?.discount ?? 0}</Typography>
                </Stack>

                <Stack
                  direction='row'
                  justifyContent='space-between'
                  width='200px'
                  sx={{ marginTop: '0px !important' }}
                >
                  <Typography variant='subtitle1'>Total Price</Typography>
                  <Typography>${order?.grand_total ?? 0}</Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ marginBottom: 4 }} />
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} md={8}>
              <Typography variant='subtitle1'>
                {order?.notes
                  ? order?.notes
                  : `
            We truly appreciate your business and are thrilled that you've chosen to shop with us. Your order is
            being processed, and we will notify you once it’s on its way. If you have any questions or need
            assistance, feel free to contact our support team. We look forward to serving you again soon!
          `}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} container justifyContent='flex-end'>
              <Typography variant='subtitle1'>EMARKET DEMO APP</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </div>
));

const OrderDetail = () => {
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const isOpenOrderDetailModal = useSelector(state => state.profile.isOpenOrderDetailModal);
  const isMyOrderDetail = useSelector(state => state.profile.isMyOrderDetail);
  const order = isMyOrderDetail?.order ? isMyOrderDetail?.order : '';
  const dummyAddress = {
    name: 'EMARKET DEMO APP',
    address: '123 Market Street',
    apartment: 'Apt 101',
    city: 'Demo City',
    state: 'Demo State',
    zip: '12345',
    email: 'demo@example.com',
    mobile: '123-456-7890'
  };

  const printRef = useRef();

  useEffect(() => {
    const navElement = document.querySelector('nav.MuiPagination-root');
    if (navElement) {
      navElement.style.display = 'none';
    }

    // Revert the changes when the component is unmounted
    return () => {
      if (navElement) {
        navElement.style.display = 'block';
      }
    };
  }, [isOpenOrderDetailModal]);
  

  return (
    <Dialog
      fullScreen
      open={isOpenOrderDetailModal}
      onClose={() => dispatch(OrderDetailModal(false))}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ background: '#FFF', color: '#000', margin: '0px', padding: '0px' }}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={() => dispatch(OrderDetailModal(false))} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Order Detail
          </Typography>

          <ReactToPrint
            trigger={() => (
              <Button
                variant='outlined'
                sx={{
                  background: '#3bb77e',
                  color: '#FFF',
                  borderColor: '#3bb77e',
                  marginRight: 2,
                  '&:hover': {
                    borderColor: '#3bb77e',
                    color: '#3bb77e'
                  }
                }}
                startIcon={<PrintIcon />}
              >
                Print
              </Button>
            )}
            content={() => printRef.current}
          />

          <Button
            variant='outlined'
            sx={{
              background: '#1C252E',
              color: '#FFF',
              borderColor: '#1C252E',
              '&:hover': {
                borderColor: '#1C252E',
                color: '#1C252E'
              }
            }}
            startIcon={<CloseIcon />}
            onClick={() => dispatch(OrderDetailModal(false))}
          >
            Close
          </Button>
        </Toolbar>
      </AppBar>

      <PrintableInvoice ref={printRef} order={order} dummyAddress={dummyAddress} windowWidth={width} />
    </Dialog>
  );
};

export default OrderDetail;
