import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './style.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { authRegisterApi, userUpdate } from '../../store/Slices/authSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../assets/images/loading.gif';

const SignUp = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isApiStatus = useSelector(state => state.authuser.isApiStatus);
  const formLoading = isApiStatus && isApiStatus?.authRegisterApi == 'loading';
  // Initial form values
  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone_number: '',
    profile_image: null
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
    phone_number: Yup.string().required('Phone Number is required'),
    profile_image: Yup.mixed()
      .required('Profile image is required')
      .test('fileType', 'Unsupported file format', value => {
        if (!value) return true; // If no file is selected, skip the validation
        return value && ['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif'].includes(value.type);
      })
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      dispatch(authRegisterApi(values)).then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          if(action?.payload?.user && action?.payload?.storage_url){
            const user = {...action?.payload?.user,storage_url:action?.payload?.storage_url};
            dispatch(userUpdate(user));
            toast.success('Register Successfull');
          }
        } else if (action.meta.requestStatus == 'rejected') {
          const errors = action?.payload?.errors ?? {};
          const message = action?.payload?.message ?? '';
          const status = action?.payload?.status ?? '';
          if (status == 422) {
            const formErrors = Object.keys(errors).reduce((acc, key) => {
              acc[key] = errors[key][0];
              return acc;
            }, {});
            setErrors(formErrors);
          } else if (status == 410) {
            toast.error(message);
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <section className='signUp mb-5'>
        <div className='breadcrumbWrapper'>
          <div className='container-fluid'>
            <ul className='breadcrumb breadcrumb2 mb-0'>
              <li>
                <Link to='/signIn'>Sign In</Link>{' '}
              </li>
              <li>Sign Up</li>
            </ul>
          </div>
        </div>
        <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className='card shadow p-5 mt-5' style={{ maxWidth: '600px', width: '100%', height: '100%' }}>
            {showLoader ? (
              <div className='loader'>
                <img src={Loader} />
              </div>
            ) : (
              ''
            )}
            <h3 className='text-center'>Sign Up</h3>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {formik => {
                return (
                  <Form className='mt-4'>
                    <Grid container columnSpacing={2} rowSpacing={0} justifyContent='center' alignItems='center'>
                      <Grid item xs={12} style={{ textAlign: 'center' }} marginBottom={'20px'}>
                        {formik.values.profile_image && (
                          <img
                            src={URL.createObjectURL(formik.values.profile_image)}
                            alt='Preview'
                            style={{
                              width: '120px',
                              height: '120px',
                              objectFit: 'cover',
                              borderRadius: '50%',
                              background: '#d9d9d9',
                              border: '4px solid #FFF',
                              outline: '1px solid #d9d9d9'
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <div className='form-group mb-4'>
                          <Field
                            as={TextField}
                            type='text'
                            id='first_name'
                            name='first_name'
                            label='First Name'
                            fullWidth
                            variant='outlined'
                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                          />
                          <ErrorMessage name='first_name' component='div' className='text-danger' />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} paddingTop={0}>
                        <div className='form-group mb-4'>
                          <Field
                            as={TextField}
                            type='text'
                            id='last_name'
                            name='last_name'
                            label='Last Name'
                            fullWidth
                            variant='outlined'
                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                          />
                          <ErrorMessage name='last_name' component='div' className='text-danger' />
                        </div>
                      </Grid>
                    </Grid>
                    <div className='form-group mb-4'>
                      <Field
                        as={TextField}
                        type='email'
                        id='email'
                        name='email'
                        label='Email'
                        fullWidth
                        variant='outlined'
                        error={formik.touched.email && Boolean(formik.errors.email)}
                      />
                      <ErrorMessage name='email' component='div' className='text-danger' />
                    </div>
                    <div className='form-group mb-4'>
                      <Field
                        as={TextField}
                        type={showPassword ? 'text' : 'password'}
                        id='password'
                        name='password'
                        label='Password'
                        fullWidth
                        variant='outlined'
                        error={formik.touched.password && Boolean(formik.errors.password)}
                      />
                      <ErrorMessage name='password' component='div' className='text-danger' />
                    </div>
                    <div className='form-group mb-4'>
                      <Field
                        as={TextField}
                        type='tel'
                        id='phone_number'
                        name='phone_number'
                        label='Phone Number'
                        fullWidth
                        variant='outlined'
                        error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                      />
                      <ErrorMessage name='phone_number' component='div' className='text-danger' />
                    </div>
                    <div className='form-group mb-4'>
                      <Field name='profile_image' className='form-control'>
                        {({ field, form }) => (
                          <div className='form-group mb-4'>
                            <TextField
                              type='file'
                              id='profile_image'
                              name='profile_image'
                              fullWidth
                              variant='outlined'
                              onChange={event => {
                                form.setFieldValue('profile_image', event.currentTarget.files[0]);
                              }}
                              onBlur={form.handleBlur}
                              error={formik.touched.profile_image && Boolean(formik.errors.profile_image)}
                            />
                            <ErrorMessage name='profile_image' component='div' className='text-danger' />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className='form-group mt-5 mb-4'>
                      <Button type='submit' className='btn btn-g btn-lg w-100' >
                      {formLoading && <CircularProgress color='inherit' size={20} sx={{ marginRight:1 }}/>}Sign Up
                      </Button>
                    </div>

                    <p className='text-center'>
                      Already have an account?
                        <Link to='/signIn' style={{ marginLeft:'6px' }}>Sign In</Link>
                    </p>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
