import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import cartApiController from '../../services/cart.service.js';

export const cartStoreApi = createAsyncThunk('cart/store', async (formValues, thunkAPI) => {
  try {
    const response = await cartApiController.store(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const cartUpdateApi = createAsyncThunk('cart/update', async (formValues, thunkAPI) => {
  try {
    const response = await cartApiController.update(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const cartItemRemoveApi = createAsyncThunk('cart/remove', async (formValues, thunkAPI) => {
  try {
    const response = await cartApiController.remove(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const cartListApi = createAsyncThunk('cart/list', async (formValues, thunkAPI) => {
  try {
    const response = await cartApiController.view(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const shippingChargeGetApi = createAsyncThunk('cart/shipping-charge/get', async (formValues, thunkAPI) => {
  try {
    const response = await cartApiController.shippingcharge(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const applyCouponApi = createAsyncThunk('cart/apply-coupon', async (formValues, thunkAPI) => {
  try {
    const response = await cartApiController.applycoupon(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const activeAddressApi = createAsyncThunk('cart/active-address', async (formValues, thunkAPI) => {
  try {
    const response = await cartApiController.activeaddress(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const orderCreateApi = createAsyncThunk('cart/checkout/order/create', async (formValues, thunkAPI) => {
  try {
    const response = await cartApiController.storeorder(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

const initialState = {
  isCartList: [],
  isCouponData: '',
  isShippingChargeData: '',
  isCheckOutData: '',
  isActiveAddressData: '',
  isOpenSizeDropDown:{},
  isApiStatus: {
    cartStoreApi: '',
    cartListApi: '',
    cartUpdateApi: '',
    applyCouponApi: '',
    shippingChargeGetApi: '',
    activeAddressApi: '',
    orderCreateApi:''
  }
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    reset: () => initialState,
    RemoveProductFromCart: (state, action) => {
      const productIdToRemove = action.payload.id;
      if (productIdToRemove) {
        state.isCartList.cartItems = state.isCartList.cartItems.filter(item => item.product_id !== productIdToRemove);
      }
    },
    RemoveCouponCode: (state, action) => {
      state.isCouponData = '';
    },
    CheckOutData: (state, action) => {
      state.isCheckOutData = action.payload;
    },
    OpenSizeDropDown: (state, action) => {
      const { id } = action.payload;
      const newState = { ...state.isOpenSizeDropDown };
      if (newState[id]) {
        newState[id] = false;
      } else {
        Object.keys(newState).forEach((key) => {
          newState[key] = false;
        });
        newState[id] = true;
      }
      state.isOpenSizeDropDown = newState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(orderCreateApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, orderCreateApi: 'loading' };
      })
      .addCase(orderCreateApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, orderCreateApi: 'succeeded' };
      })
      .addCase(orderCreateApi.rejected, state => {
        state.isApiStatus = { ...state.isApiStatus, orderCreateApi: 'failed' };
      })
      .addCase(activeAddressApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, activeAddressApi: 'loading' };
      })
      .addCase(activeAddressApi.fulfilled, (state, action) => {
        state.isActiveAddressData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, activeAddressApi: 'succeeded' };
      })
      .addCase(activeAddressApi.rejected, state => {
        state.isActiveAddressData = '';
        state.isApiStatus = { ...state.isApiStatus, activeAddressApi: 'failed' };
      })
      .addCase(shippingChargeGetApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, shippingChargeGetApi: 'loading' };
      })
      .addCase(shippingChargeGetApi.fulfilled, (state, action) => {
        state.isShippingChargeData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, shippingChargeGetApi: 'succeeded' };
      })
      .addCase(shippingChargeGetApi.rejected, state => {
        state.isShippingChargeData = '';
        state.isApiStatus = { ...state.isApiStatus, shippingChargeGetApi: 'failed' };
      })
      .addCase(applyCouponApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, applyCouponApi: 'loading' };
      })
      .addCase(applyCouponApi.fulfilled, (state, action) => {
        state.isCouponData = action.payload;
        state.isApiStatus = { ...state.isApiStatus, applyCouponApi: 'succeeded' };
      })
      .addCase(applyCouponApi.rejected, state => {
        state.isCouponData = '';
        state.isApiStatus = { ...state.isApiStatus, applyCouponApi: 'failed' };
      })
      .addCase(cartUpdateApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, cartUpdateApi: 'loading' };
      })
      .addCase(cartUpdateApi.fulfilled, (state, action) => {
        state.isCartList = action.payload;
        state.isApiStatus = { ...state.isApiStatus, cartUpdateApi: 'succeeded' };
      })
      .addCase(cartUpdateApi.rejected, state => {
        state.isCartList = '';
        state.isApiStatus = { ...state.isApiStatus, cartUpdateApi: 'failed' };
      })
      .addCase(cartListApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, cartListApi: 'loading' };
      })
      .addCase(cartListApi.fulfilled, (state, action) => {
        state.isCartList = action.payload;
        state.isApiStatus = { ...state.isApiStatus, cartListApi: 'succeeded' };
      })
      .addCase(cartListApi.rejected, state => {
        state.isCartList = [];
        state.isApiStatus = { ...state.isApiStatus, cartListApi: 'failed' };
      })
      .addCase(cartStoreApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, cartStoreApi: 'loading' };
      })
      .addCase(cartStoreApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, cartStoreApi: 'succeeded' };
      })
      .addCase(cartStoreApi.rejected, state => {
        state.isApiStatus = { ...state.isApiStatus, cartStoreApi: 'failed' };
      });
  }
});

export const { reset, RemoveProductFromCart, RemoveCouponCode, CheckOutData,OpenSizeDropDown } = cartSlice.actions;
export default cartSlice.reducer;
