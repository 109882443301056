import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authApiController from '../../services/auth.service.js';

export const authRegisterApi = createAsyncThunk('user/register', async (formValues, thunkAPI) => {
  try {
    const response = await authApiController.register(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});
export const authLoginApi = createAsyncThunk('user/login', async (formValues, thunkAPI) => {
  try {
    const response = await authApiController.login(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});
export const authLogOutApi = createAsyncThunk('user/logout', async (formValues, thunkAPI) => {
  try {
    const response = await authApiController.logout(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const authPasswordChangeApi = createAsyncThunk('user/changepassword', async (formValues, thunkAPI) => {
  try {
    const response = await authApiController.changepassword(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const forgotPasswordApi = createAsyncThunk('password/forgot', async (formValues, thunkAPI) => {
  try {
    const response = await authApiController.forgotpassword(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

export const contactUsApi = createAsyncThunk('user/contact-us', async (formValues, thunkAPI) => {
  try {
    const response = await authApiController.contactus(formValues, thunkAPI);
    if (response && response.data && response.data.status) {
      return response.data;
    } else {
      const errorMessage = (response && response.data && response.data.message) || 'Error occurred';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    const status = error?.response?.status ?? 410;
    const errors = error?.response?.data?.errors ?? '';
    return thunkAPI.rejectWithValue({ status: status, message: errorMessage, errors: errors });
  }
});

const initialState = {
  isLoggedIn: false,
  user: null,
  isToken: '',
  isApiStatus: {
    authRegisterApi: '',
    authLoginApi: '',
    authLogOutApi: '',
    authPasswordChangeApi: '',
    contactUsApi:'',
    forgotPasswordApi:''
  }
};

const authSlice = createSlice({
  name: 'authuser',
  initialState,
  reducers: {
    reset: () => initialState,
    loginSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    tokenSetUp: (state, action) => {
      state.isToken = action.payload;
    },
    logout: state => {
      state.isLoggedIn = false;
      state.user = null;
    },
    userUpdate: (state, action) => {
      state.user = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(forgotPasswordApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, forgotPasswordApi: 'loading' };
      })
      .addCase(forgotPasswordApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, forgotPasswordApi: 'succeeded' };
      })
      .addCase(forgotPasswordApi.rejected, state => {
        state.isApiStatus = { ...state.isApiStatus, forgotPasswordApi: 'failed' };
      })
      .addCase(contactUsApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, contactUsApi: 'loading' };
      })
      .addCase(contactUsApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, contactUsApi: 'succeeded' };
      })
      .addCase(contactUsApi.rejected, state => {
        state.isApiStatus = { ...state.isApiStatus, contactUsApi: 'failed' };
      })
      .addCase(authPasswordChangeApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, authPasswordChangeApi: 'loading' };
      })
      .addCase(authPasswordChangeApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, authPasswordChangeApi: 'succeeded' };
      })
      .addCase(authPasswordChangeApi.rejected, state => {
        state.isApiStatus = { ...state.isApiStatus, authPasswordChangeApi: 'failed' };
      })
      .addCase(authLogOutApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, authLogOutApi: 'loading' };
      })
      .addCase(authLogOutApi.fulfilled, (state, action) => {
        // state.isLoggedIn = false;
        // state.user = null;
        // state.isToken = '';
        state.isApiStatus = { ...state.isApiStatus, authLogOutApi: 'succeeded' };
      })
      .addCase(authLogOutApi.rejected, state => {
        state.isLoggedIn = false;
        state.user = null;
        state.isToken = '';
        state.isApiStatus = { ...state.isApiStatus, authLogOutApi: 'failed' };
      })
      .addCase(authRegisterApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, authRegisterApi: 'loading' };
      })
      .addCase(authRegisterApi.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        // state.user = action?.payload?.user;
        state.isToken = action.payload.token;
        state.isApiStatus = { ...state.isApiStatus, authRegisterApi: 'succeeded' };
      })
      .addCase(authRegisterApi.rejected, state => {
        state.isLoggedIn = false;
        state.user = null;
        state.isToken = '';
        state.isApiStatus = { ...state.isApiStatus, authRegisterApi: 'failed' };
      })
      .addCase(authLoginApi.pending, state => {
        state.isApiStatus = { ...state.isApiStatus, authLoginApi: 'loading' };
      })
      .addCase(authLoginApi.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload.user;
        state.isToken = action.payload.token;
        state.isApiStatus = { ...state.isApiStatus, authLoginApi: 'succeeded' };
      })
      .addCase(authLoginApi.rejected, state => {
        state.isLoggedIn = false;
        state.user = null;
        state.isToken = '';
        state.isApiStatus = { ...state.isApiStatus, authLoginApi: 'failed' };
      });
  }
});

export const { reset, loginSuccess, logout, tokenSetUp, userUpdate } = authSlice.actions;
export default authSlice.reducer;
