import { Box, Button, FormControl, InputLabel, Modal, Select, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OpenThankYouModal, ProfileTab } from '../../store/Slices/profileSlice';
import useWindowWidth from '../../hooks/window';
import { Home } from '@mui/icons-material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from 'react-router-dom';


const ThankYouModel = () => {
  const width = useWindowWidth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOpenThankYouModal = useSelector(state => state.profile.isOpenThankYouModal);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '',
    width: width > 768 ? 600 : width > 567 ? '80%' : '90%',
    boxShadow: 24,
    borderRadius: 4,
    p: 4
  };

  return (
    <div>
      <Modal
        open={isOpenThankYouModal}
       
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Order Purchased
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2, mb: 4 }}>
            Thank you for your purchase! Your order has been successfully placed. We appreciate your business and hope
            you enjoy your items.
          </Typography>
          <Box display={'flex'} justifyContent={'end'}>
            <Button
              variant='outlined'
              sx={{
                background: '#1C252E',
                color: '#FFF',
                borderColor: '#1C252E',
                marginRight: 2,
                '&:hover': {
                  borderColor: '#1C252E',
                  color: '#1C252E'
                }
              }}
              onClick={() => {
                navigate('/')
                dispatch(OpenThankYouModal(false))
              }}
              startIcon={<Home/>}
            >
              Home
            </Button>
            <Button
              variant='outlined'
              sx={{
                background: '#3bb77e',
                color: '#FFF',
                borderColor: '#3bb77e',
                marginRight: 2,
                '&:hover': {
                  borderColor: '#3bb77e',
                  color: '#3bb77e'
                }
              }}
              startIcon={<ShoppingCartIcon/>}
              onClick={() => {
                dispatch(ProfileTab(2));
                dispatch(OpenThankYouModal(false))
                navigate('/profile');
              }}
            >
              My Order
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ThankYouModel;
