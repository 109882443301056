import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Chip,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  Stack,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/system';
import {
  CheckOutData,
  OpenSizeDropDown,
  RemoveCouponCode,
  RemoveProductFromCart,
  activeAddressApi,
  applyCouponApi,
  cartItemRemoveApi,
  cartListApi,
  cartStoreApi,
  cartUpdateApi,
  shippingChargeGetApi
} from '../../store/Slices/cartSlice';
import useWindowWidth from '../../hooks/window';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import useSweetAlert from '../../hooks/sweetalert';
import { countableItemApi } from '../../store/Slices/categorySlice';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { countryDropdownApi, userAddressCreateApi } from '../../store/Slices/profileSlice';
import { useNavigate } from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place';
import emptyCart from '../../assets/images/emptyCart.png';

const TruncatedTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const validationSchema = Yup.object().shape({
  cardNumber: Yup.string().when('paymentMethod', {
    is: 'creditCard',
    then: Yup.string().required('Card number is required')
  }),
  expiryDate: Yup.string().when('paymentMethod', {
    is: 'creditCard',
    then: Yup.string().required('Expiry date is required')
  }),
  cvv: Yup.string().when('paymentMethod', {
    is: 'creditCard',
    then: Yup.string().required('CVV is required')
  }),
  product: Yup.array().of(
    Yup.object().shape({
      size_id: Yup.string().required('Size is required')
    })
  ),
  discountCoupon: Yup.string()
});

const StyledSelect = styled('select')({
  font: 'inherit',
  letterSpacing: 'inherit',
  color: 'currentColor',
  padding: '16.5px 0px 16.5px 0px',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  boxSizing: 'content-box',
  background: 'none',
  height: 'auto',
  margin: '0',
  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  minWidth: '200px',
  width: '100%',
  WebkitAnimationName: 'mui-auto-fill-cancel',
  animationName: 'mui-auto-fill-cancel',
  WebkitAnimationDuration: '10ms',
  animationDuration: '10ms',
  '&:focus': {
    borderColor: '#3f51b5',
    outline: 'none',
    boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)'
  }
});

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useWindowWidth();
  const { showAlert } = useSweetAlert();
  const [quantities, setQuantities] = useState({});
  const [cartUpdateLoader, setCartUpdateLoader] = useState({});
  const [totalCartCost, setTotalCartCost] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('cashOnDelivery');
  const isToken = useSelector(state => state.authuser.isToken);
  const isCouponData = useSelector(state => state.cart.isCouponData);
  const isShippingChargeData = useSelector(state => state.cart.isShippingChargeData);
  const charges = isShippingChargeData && isShippingChargeData?.charges ? isShippingChargeData?.charges : '';
  const coupon = isCouponData && isCouponData?.coupon ? isCouponData?.coupon : '';
  const isCartList = useSelector(state => state.cart.isCartList);
  const isApiStatus = useSelector(state => state.cart.isApiStatus);
  const profileApiStatus = useSelector(state => state.profile.isApiStatus);
  const cartToCheckoutLoading = (profileApiStatus?.countryDropdownApi === "loading") || (isApiStatus?.activeAddressApi === "loading");
  const isCartLoading = isApiStatus && isApiStatus?.cartListApi === 'loading';
  const cartProduct = isCartList?.cartItems || [];

  useEffect(() => {
    dispatch(cartListApi({ isToken }));
    dispatch(shippingChargeGetApi({ isToken }));
  }, [dispatch, isToken]);

  useEffect(() => {
    const initialQuantities = cartProduct.reduce((acc, item) => {
      acc[item.product_id] = item.quantity;
      return acc;
    }, {});
    setQuantities(initialQuantities);
  }, [cartProduct]);

  const handleQuantityChange = (productId, delta, cartId, index, size_id) => {
    setCartUpdateLoader(prevLoader => ({ ...prevLoader, [index]: true }));
    const cartType = delta === 1 ? 'sum' : 'deduct';
    setQuantities(prevQuantities => {
      const newQuantity = Math.max(1, (prevQuantities[productId] || 1) + delta);
      return {
        ...prevQuantities,
        [productId]: newQuantity
      };
    });
    dispatch(cartUpdateApi({ isToken, cart_id: cartId, cartType: cartType, size_id: size_id ? size_id : '' }))
      .then(() => {
        setCartUpdateLoader(prevLoader => ({ ...prevLoader, [index]: false }));
      })
      .catch(() => {
        setCartUpdateLoader(prevLoader => ({ ...prevLoader, [index]: false }));
      });
  };

  useEffect(() => {
    if (cartProduct.length > 0) {
      const totalCost = cartProduct.reduce((acc, item) => acc + item.product.price * item.quantity, 0);
      let finalCost = totalCost;

      if (coupon) {
        finalCost -= parseFloat(coupon?.amount);
      }

      if (charges && charges.amount) {
        finalCost += parseFloat(charges.amount);
      }

      setTotalCartCost(finalCost);
      setTotalAmount(totalCost);
    }
  }, [cartProduct, coupon, charges]);

  const initialValues = useMemo(() => {
    return {
      product: cartProduct,
      coupon_id: coupon?.id || '',
      discountCoupon: coupon?.code || '',
      discountAmount: coupon?.amount ? parseFloat(coupon?.amount) : 0,
      cardNumber: '',
      expiryDate: '',
      shippingCharge: charges?.amount ? parseFloat(charges.amount) : 0,
      country_id: charges?.country_id || '',
      cvv: '',
      total: totalAmount ?? 0,
      grand_total: totalCartCost ?? 0
    };
  }, [cartProduct, coupon, charges, totalAmount, totalCartCost]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      if (values && values?.product && values?.product?.length > 0) {
        dispatch(CheckOutData(values));
        dispatch(activeAddressApi({ isToken })).then(() => {
          dispatch(countryDropdownApi({ isToken })).then(() => {
            navigate('/checkout');
          });
        });
      } else {
        showAlert(
          'warning',
          'Warning',
          'Your cart looks empty. Give it meaning by filling it with books, groceries, clothes, etc.',
          false,
          'Ok'
        );
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ margin: width > 992 ? '270px 45px 40px 45px' : '120px 45px 40px 45px' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, setFieldValue, values, setErrors, validateField, setFieldTouched, isSubmitting }) => {

          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <TableContainer component={Paper} elevation={3}>
                    {isCartLoading ? (
                      <Table sx={{ width: '100%' }} aria-label='cart table'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell sx={{ width: '200px' }}>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Total Price</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        {Array.from(Array(5).keys()).map((_, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Skeleton variant='circular' width={70} height={70} />
                            </TableCell>
                            <TableCell>
                              <Skeleton width={100} />
                            </TableCell>
                            <TableCell>
                              <Stack spacing={1} display={'flex'} direction={'row'} alignItems={'center'}>
                                <Skeleton width={40} />
                                <Skeleton variant='rectangular' width={30} height={30} />
                                <Skeleton width={40} />
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Skeleton width={100} />
                            </TableCell>
                            <TableCell>
                              <Skeleton width={100} />
                            </TableCell>
                            <TableCell>
                              <Skeleton width={40} height={40} circle />
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    ) : (
                      <Table sx={{ width: '100%' }} aria-label='cart table'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Size</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Total Price</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cartProduct && cartProduct.length > 0 ? (
                            cartProduct.map((item, index) => (
                              <TableRow key={item.id}>
                                <TableCell>
                                  <Stack direction='row' alignItems='center' spacing={1}>
                                    <Avatar
                                      variant='rounded'
                                      src={item?.product?.thumbnail_url}
                                      alt='Product Image'
                                      sx={{
                                        width: 70,
                                        height: 70,
                                        border: '8px solid #FFF',
                                        outline: '2px solid #d9d9d9',
                                        objectFit: 'cover'
                                      }}
                                    />
                                    <Box>
                                      <Typography>{item?.product?.category?.title}</Typography>
                                      <Typography>{item?.product?.brand?.title}</Typography>
                                    </Box>
                                  </Stack>
                                </TableCell>
                                <TableCell>
                                  <TruncatedTypography>{item?.product?.title}</TruncatedTypography>
                                </TableCell>
                                <TableCell sx={{ width: '200px' }}>
                                  <Stack>
                                    <Box>
                                      <StyledSelect
                                        name={`product[${index}].size_id`}
                                        value={values.product[index].size_id}
                                        onChange={e => {
                                          setCartUpdateLoader(prevLoader => ({ ...prevLoader, [index]: true }));
                                          dispatch(
                                            cartUpdateApi({
                                              isToken,
                                              cart_id: item?.id,
                                              cartType: 'size_change',
                                              size_id: e?.target?.value ? e?.target?.value : ''
                                            })
                                          )
                                            .then(() => {
                                              setCartUpdateLoader(prevLoader => ({ ...prevLoader, [index]: false }));
                                            })
                                            .catch(() => {
                                              setCartUpdateLoader(prevLoader => ({ ...prevLoader, [index]: false }));
                                            });
                                          setFieldValue(`product[${index}].size_id`, e.target.value);
                                        }}
                                        className={
                                          touched.product &&
                                          touched.product[index] &&
                                          touched.product[index].size_id &&
                                          errors.product &&
                                          errors.product[index] &&
                                          errors.product[index].size_id
                                            ? 'error'
                                            : ''
                                        }
                                      >
                                        <option value='' selected>
                                          Size
                                        </option>
                                        {item?.product?.weights &&
                                          item?.product?.weights.length > 0 &&
                                          item.product.weights.map(size => (
                                            <option key={size.id} value={size.id}>
                                              {size.size}
                                            </option>
                                          ))}
                                      </StyledSelect>
                                    </Box>
                                    <Box>
                                      {touched.product &&
                                        touched.product[index] &&
                                        touched.product[index].size_id &&
                                        errors.product &&
                                        errors.product[index] &&
                                        errors.product[index].size_id && (
                                          <Typography variant='caption' color='error'>
                                            {errors.product[index].size_id}
                                          </Typography>
                                        )}
                                    </Box>
                                  </Stack>
                                </TableCell>
                                <TableCell>
                                  <Stack direction='row' alignItems='center' spacing={1}>
                                    <IconButton
                                      color='primary'
                                      onClick={() =>
                                        handleQuantityChange(
                                          item?.product?.id,
                                          -1,
                                          item?.id,
                                          index,
                                          values?.product[index]?.size_id
                                        )
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                    <Chip label={quantities[item?.product?.id] || 1} />
                                    <IconButton
                                      color='primary'
                                      onClick={() => handleQuantityChange(item?.product.id, 1, item?.id, index)}
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                                <TableCell>
                                  <Typography>${parseFloat(item?.product.price)}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    ${(item.quantity * parseFloat(item?.product.price)).toFixed(2)}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {cartUpdateLoader[index] ? (
                                    <CircularProgress size={20} sx={{ marginLeft: 1, color: '#000' }} />
                                  ) : (
                                    <Avatar
                                      onClick={() => {
                                        showAlert(
                                          'warning',
                                          'Warning',
                                          'Are you sure you want to remove this product from cart?',
                                          true,
                                          'YES'
                                        ).then(result => {
                                          if (result.isConfirmed) {
                                            dispatch(cartItemRemoveApi({ isToken, product_id: item?.product.id })).then(
                                              action => {
                                                if (action?.meta?.requestStatus == 'fulfilled') {
                                                  dispatch(countableItemApi({ isToken })).then(() => {
                                                    setFieldValue(
                                                      'product',
                                                      values.product.filter(prod => prod.product_id !== item.product.id)
                                                    );
                                                    dispatch(RemoveProductFromCart({ id: item?.product_id }));
                                                  });
                                                }
                                              }
                                            );
                                          }
                                        });
                                      }}
                                    >
                                      <CloseIcon />
                                    </Avatar>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <motion.tr
                              initial={{ opacity: 0, scale: 0.8 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.5 }}
                              style={{ height: '300px', width: '100%' }}
                            >
                              <TableCell colSpan={7} sx={{ padding: 0 }}>
                                <Stack
                                  display='flex'
                                  direction='column'
                                  justifyContent='center'
                                  alignItems='center'
                                  sx={{ height: '100%', width: '100%' }}
                                >
                                  <motion.div
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                                  >
                                    <Avatar src={emptyCart} sx={{ width: '150px', height: '150px' }} />
                                  </motion.div>
                                  <Typography variant='h6' textAlign='center' sx={{ mt: 2 }}>
                                    Your cart looks empty. Give it meaning by filling it with books, groceries, clothes,
                                    etc.
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </motion.tr>
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card sx={{ marginBottom: 4 }} component={Paper} elevation={5}>
                    <CardContent>
                      <Field
                        as={TextField}
                        name='discountCoupon'
                        label='Discount Coupon'
                        error={touched.discountCoupon && !!errors.discountCoupon}
                        helperText={touched.discountCoupon && errors.discountCoupon}
                        sx={{ width: '100%', marginBottom: 2 }}
                      />
                      <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={12} md={4}>
                          <Button
                            variant='outlined'
                            sx={{
                              background: '#FDA92D',
                              color: '#FFF',
                              borderColor: '#FDA92D',
                              marginRight: 2,
                              '&:hover': {
                                borderColor: '#FDA92D',
                                color: '#FDA92D'
                              }
                            }}
                            fullWidth
                            onClick={async () => {
                              await setFieldTouched('discountCoupon', true, true);
                              await validateField('discountCoupon');
                              if (!values.discountCoupon) {
                                setErrors({ discountCoupon: 'Please enter a discount coupon' });
                              } else {
                                dispatch(applyCouponApi({ isToken, code: values.discountCoupon }));
                              }
                            }}
                          >
                            Apply Coupon
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {coupon ? (
                            <Button
                              variant='outlined'
                              sx={{
                                background: '#1C252E',
                                color: '#FFF',
                                borderColor: '#1C252E',
                                marginRight: 2,
                                '&:hover': {
                                  borderColor: '#1C252E',
                                  color: '#1C252E'
                                }
                              }}
                              fullWidth
                              onClick={() => {
                                dispatch(RemoveCouponCode());
                                setFieldValue('discountCoupon', '');
                              }}
                            >
                              Remove Coupon
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Card component={Paper} elevation={5}>
                    <CardContent>
                      <List>
                        <ListItem>
                          <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                            <ListItemText primary='Total :' />
                            <ListItemText primary={`$${totalAmount ?? 0}`} sx={{ textAlign: 'end' }} />
                          </Stack>
                        </ListItem>
                        <Divider component='li' />
                        <ListItem>
                          <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                            <ListItemText primary='Total Product :' />
                            <ListItemText primary={cartProduct?.length ?? 0} sx={{ textAlign: 'end' }} />
                          </Stack>
                        </ListItem>
                        <Divider component='li' />
                        <ListItem>
                          <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                            <ListItemText primary='Discount :' />
                            <ListItemText  primary={`- $${coupon?.amount ?? 0}`} sx={{ textAlign: 'end',color:'#3bb77e' }} />
                          </Stack>
                        </ListItem>
                        <Divider component='li' />
                        <ListItem>
                          <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                            <ListItemText primary='Shipping Charge :' />
                            <ListItemText primary={`+ $${values?.shippingCharge ?? 0}`} sx={{ textAlign: 'end',color:'#d91515' }} />
                          </Stack>
                        </ListItem>
                        <Divider component='li' />
                        <ListItem>
                          <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                            <ListItemText primary='Total Price :' />
                            <ListItemText primary={`$${totalCartCost.toFixed(2)}`} sx={{ textAlign: 'end' }} />
                          </Stack>
                        </ListItem>
                        <Divider component='li' />
                        <ListItem>
                          <FormGroup>
                            <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={paymentMethod === 'cashOnDelivery'}
                                    onChange={() => setPaymentMethod('cashOnDelivery')}
                                  />
                                }
                                label='Cash on delivery'
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={paymentMethod === 'creditCard'}
                                    onChange={() => setPaymentMethod('creditCard')}
                                  />
                                }
                                label='Credit Card'
                              />
                            </Stack>
                          </FormGroup>
                        </ListItem>
                        {paymentMethod === 'creditCard' && (
                          <>
                            <ListItem>
                              <TextField
                                name='cardNumber'
                                label='Card Number'
                                fullWidth
                                error={Boolean(errors.cardNumber && touched.cardNumber)}
                                helperText={errors.cardNumber && touched.cardNumber ? errors.cardNumber : ''}
                                value={values.cardNumber}
                                onChange={e => setFieldValue('cardNumber', e.target.value)}
                              />
                            </ListItem>
                            <ListItem>
                              <TextField
                                name='expiryDate'
                                label='Expiry Date'
                                fullWidth
                                error={Boolean(errors.expiryDate && touched.expiryDate)}
                                helperText={errors.expiryDate && touched.expiryDate ? errors.expiryDate : ''}
                                value={values.expiryDate}
                                onChange={e => setFieldValue('expiryDate', e.target.value)}
                              />
                            </ListItem>
                            <ListItem>
                              <TextField
                                name='cvv'
                                label='CVV'
                                fullWidth
                                error={Boolean(errors.cvv && touched.cvv)}
                                helperText={errors.cvv && touched.cvv ? errors.cvv : ''}
                                value={values.cvv}
                                onChange={e => setFieldValue('cvv', e.target.value)}
                              />
                            </ListItem>
                          </>
                        )}
                      </List>
                      <Button
                        variant='outlined'
                        sx={{
                          background: '#3bb77e',
                          color: '#FFF',
                          borderColor: '#3bb77e',
                          marginRight: 2,
                          '&:hover': {
                            borderColor: '#3bb77e',
                            color: '#3bb77e'
                          }
                        }}
                        fullWidth
                        type='submit'
                      >
                        {cartToCheckoutLoading && <CircularProgress color='inherit' size={20} sx={{ marginRight:1 }}/>}Proceed to Checkout
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Cart;
