import axios from "axios";
import config from "../config.js";

const API_URL = config.API_URL;

const wishliststore = (values) => {
    const action = `afterlogin/account/wishlist/create`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values?.isToken}`
        },
    });
};

const wishlistview = (values) => {
    const action = `afterlogin/account/wishlist/view`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};

const wishlistremove = (values) => {
    const action = `afterlogin/account/wishlist/delete`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};

const countriesdropdown = (values) => {
    const action = `afterlogin/account/address/countries`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};

const addressview = (values) => {
    const action = `afterlogin/account/address/view`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};

const addressdetail = (values) => {
    const action = `afterlogin/account/address/detail`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};

const addresscreate = (values) => {
    const action = `afterlogin/account/address/create`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};

const addressupdate = (values) => {
    const action = `afterlogin/account/address/update`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};



const addresslists = (values) => {
    const action = `/profile/address/view`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};



const addressdelete = (values) => {
    const action = `/profile/address/delete`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};

const addressselections = (values) => {
    const action = `/profile/address/active`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};


const countrieslists = (values) => {
    const action = `/countries/options`;

    return axios.get(API_URL + action, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values?.isToken}`
        },
    });
};

const myorder = (values) => {
    const action = `afterlogin/order/view`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};

const myordersdetail = (values) => {
    const action = `afterlogin/order/detail`;
    const formData = new FormData();
    for (let value in values) {
        formData.append(value, values[value]);
    }
    return axios.post(API_URL + action, formData, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${values.isToken}`
        },
    });
};

const profileupdate = values => {
    const action = 'afterlogin/account/profile/update';
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      if (key === 'profile_image') {
        if (values[key]) {
          formData.append(key, values[key]);
        }
      } else {
        formData.append(key, values[key]);
      }
    });
    return axios.post(API_URL + action, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${values.isToken}`
      }
    });
  };

const profileApiController = {
    countriesdropdown,
    myorder,
    addressdetail,
    profileupdate,
    wishliststore,
    wishlistview,
    wishlistremove,
    countrieslists,
    addresscreate,
    addressview,
    addresslists,
    addressupdate,
    addressdelete,
    addressselections,
    myordersdetail
};

export default profileApiController;