import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Product from '../../components/product';
import { Button } from '@mui/material';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { shopPageApi } from '../../store/Slices/productSlice';
import Skeleton from 'react-loading-skeleton';
import useWindowWidth from '../../hooks/window';
import { OpenFilter } from '../../store/Slices/categorySlice';

const Listing = props => {
  const [isOpenDropDown, setisOpenDropDown] = useState(false);
  const [isOpenDropDown2, setisOpenDropDown2] = useState(false);
  const [filterType, setFilterType] = useState('Descending');
  const [showPerPage, setHhowPerPage] = useState(3);
  const [data, setData] = useState([]);
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const isToken = useSelector(state => state.authuser.isToken);
  const isShopProducts = useSelector(state => state.product.isShopProducts);
  const isApiStatus = useSelector(state => state.product.isApiStatus);
  const isOpenFilter = useSelector(state => state.category.isOpenFilter);
  const ShopProducts =
    isShopProducts && isShopProducts?.products && isShopProducts?.products.length > 0 ? isShopProducts?.products : [];
  const categories =
    isShopProducts && isShopProducts?.categories && isShopProducts?.categories.length > 0
      ? isShopProducts?.categories
      : [];
  const brands =
    isShopProducts && isShopProducts?.brands && isShopProducts?.brands.length > 0 ? isShopProducts?.brands : [];
  const productLoading = isApiStatus && isApiStatus.shopPageApi == 'loading';
  const [currentId, setCurrentId] = useState();

  let { id } = useParams();

  return (
    <>
      {width < 992 && (
        <>
            <Button className='btn-g btn-lg w-100 filterBtn' onClick={() => dispatch(OpenFilter())}>
              Filters
            </Button>
        </>
      )}

      <section className='listingPage'>
        <div className='container-fluid'>
          {
            <div className='breadcrumb flex-column'>
              <h1 className='text-capitalize'>{id.split('-').join(' ')}</h1>
              <ul className='list list-inline mb-0'>
                <li className='list-inline-item'>
                  <Link to={''}>Home </Link>
                </li>
                <li className='list-inline-item'>
                  <Link to={`/cat/${sessionStorage.getItem('cat')}`} className='text-capitalize'>
                    {sessionStorage.getItem('cat')}{' '}
                  </Link>
                </li>
                {props.single === false && (
                  <li className='list-inline-item'>
                    <Link to={''} class='text-capitalize'>
                      {id.split('-').join(' ')}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          }

          <div className='listingData'>
            <div className='row'>
              <div className={`col-md-3 sidebarWrapper ${isOpenFilter && 'click'}`}>
              <Sidebar
                    data={categories}
                    brands={brands}
                    currentCatData={data}
                  />
              </div>
              <div className='col-md-9 rightContent homeProducts pt-0'>
                <div className='topStrip d-flex align-items-center'>
                  <p className='mb-0'>
                    We found <span className='text-success'>{ShopProducts.length}</span> items for you!
                  </p>
                  <div className='ml-auto d-flex align-items-center'>
                    <div className='tab_ ml-3 position-relative'>
                      <Button className='btn_' onClick={() => setisOpenDropDown2(!isOpenDropDown2)}>
                        <FilterListOutlinedIcon /> Sort by: {filterType ?? ''}
                      </Button>
                      {isOpenDropDown2 ? (
                        <ul className='dropdownMenu'>
                          <li>
                            <Button className='align-items-center' onClick={() => {
                              dispatch(shopPageApi({ isToken,filter_type:'DESC' })).then(()=>{
                                setFilterType('Descending');
                                setisOpenDropDown2(false);
                              })}}>
                              Descending
                            </Button>
                          </li>
                          <li>
                            <Button className='align-items-center' onClick={() => {
                              dispatch(shopPageApi({ isToken,filter_type:'ASC' })).then(()=>{
                                setFilterType('Ascending');
                                setisOpenDropDown2(false);
                              })}}>
                              {' '}
                              Ascending
                            </Button>
                          </li>
                          <li>
                            <Button className='align-items-center' onClick={() => {
                              dispatch(shopPageApi({ isToken,filter_type:'price_low_hi' })).then(()=>{
                                setFilterType('Low to High');
                                setisOpenDropDown2(false);
                              })}}>
                              {' '}
                              Price: Low to High
                            </Button>
                          </li>
                          <li>
                            <Button className='align-items-center' onClick={() => {
                              dispatch(shopPageApi({ isToken,filter_type:'price_hi_low' })).then(()=>{
                                setFilterType('High to Low');
                                setisOpenDropDown2(false);
                              })}}>
                              {' '}
                              Price: High to Low
                            </Button>
                          </li>
                         
                          
                        </ul>
                      ) : ''}
                    </div>
                  </div>
                </div>

                <div className='productRow pl-4 pr-3'>
                  {ShopProducts.length !== 0 && !productLoading ? (
                    ShopProducts.map((item, index) => {
                      return (
                        <div className='item' key={index}>
                          <Product tag={item.type} item={item} />
                        </div>
                      );
                    })
                  ) : (
                    <div className='mt-0 pt-0'>
                      <div className='d-flex flex-wrap'>
                        {Array.from({ length: 16 }).map((_, index) => (
                          <Skeleton
                            key={index}
                            height={400}
                            width={400}
                            count={1}
                            highlightColor='#def9ec'
                            baseColor='#f9f6f4'
                            style={{ margin: '2rem' }}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Listing;
