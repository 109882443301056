import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeProductFromWishListApi, userWishListApi } from '../../../store/Slices/profileSlice';
import {
  Typography,
  Card,
  Box,
  Chip,
  Stack,
  Divider,
  Avatar,
  Button,
  Pagination,
  IconButton,
  TextField,
  Paper,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Grid,
  styled
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { cartStoreApi } from '../../../store/Slices/cartSlice';
import useSweetAlert from '../../../hooks/sweetalert';
import { countableItemApi } from '../../../store/Slices/categorySlice';
import { motion } from 'framer-motion';
import './Wishlist.css';
import { productDetailApi } from '../../../store/Slices/productSlice';
import { useNavigate } from 'react-router-dom';
import emptyCart from '../../../assets/images/404.png';
import HomeIcon from '@mui/icons-material/Home';
import useWindowWidth from '../../../hooks/window';

const WishList = () => {
  const dispatch = useDispatch();
  const { showAlert } = useSweetAlert();
  const navigate = useNavigate();
  const width = useWindowWidth();
  const isUserWishList = useSelector(state => state.profile.isUserWishList);
  const wishlistData = isUserWishList?.wishlist || {};
  console.log('wishlistData: ', JSON.stringify(wishlistData, null, 2));
  const wishlist = wishlistData?.data || [];
  const currentPage = wishlistData.current_page || 1;
  const totalPages = wishlistData.last_page || 1;
  const isToken = useSelector(state => state.authuser.isToken);

  const [quantities, setQuantities] = useState({});
  const [selectedSizes, setSelectedSizes] = useState({});
  console.log('selectedSizes: ', selectedSizes);

  const StyledSelect = styled('select')({
    font: 'inherit',
    letterSpacing: 'inherit',
    color: 'currentColor',
    padding: '16.5px 0px 16.5px 0px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    boxSizing: 'content-box',
    background: 'none',
    height: 'auto',
    margin: '0',
    WebkitTapHighlightColor: 'transparent',
    display: 'block',
    minWidth: '200px',
    width: '100%',
    WebkitAnimationName: 'mui-auto-fill-cancel',
    animationName: 'mui-auto-fill-cancel',
    WebkitAnimationDuration: '10ms',
    animationDuration: '10ms',
    '&:focus': {
      borderColor: '#3f51b5',
      outline: 'none',
      boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)'
    }
  });

  useEffect(() => {
    if (isToken) {
      dispatch(userWishListApi({ isToken }));
    }
  }, [dispatch, isToken]);

  const handlePageChange = page => {
    dispatch(userWishListApi({ isToken, page }));
  };

  const handleQuantityChange = (productId, delta) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: Math.max(1, (prevQuantities[productId] || 1) + delta)
    }));
  };

  const handleSizeChange = (productId, sizeId) => {
    setSelectedSizes(prevSelectedSizes => ({
      ...prevSelectedSizes,
      [productId]: sizeId
    }));
  };

  const handleAddToCart = productId => {
    const quantity = quantities[productId] || 1;
    const size_id = selectedSizes[productId] || '';
    dispatch(cartStoreApi({ isToken, product_id: productId, quantity,size_id:size_id })).then(action => {
      if (action?.meta?.requestStatus === 'fulfilled') {
        showAlert(
          'success',
          'Success',
          'Product Added To Cart Do you want to remove This product From WishList',
          true,
          'YES'
        ).then(result => {
          if (result.isConfirmed) {
            dispatch(removeProductFromWishListApi({ isToken, product_id: productId })).then(action => {
              if (action?.meta?.requestStatus === 'fulfilled') {
                dispatch(countableItemApi({ isToken })).then(() => {
                  dispatch(userWishListApi({ isToken })).then(() => {
                    showAlert('success', 'Success', 'Product Removed From WishList', false, 'OK');
                  });
                });
              }
            });
          }else{
            dispatch(countableItemApi({ isToken }));
          }
        });
      }
    });
  };

  return (
    <Box sx={{ marginTop: '25px', paddingBottom: '100px' }} id='wishlist-view'>
      {wishlist.length > 0 ? (
        <motion.div
          initial='hidden'
          animate='visible'
          variants={{
            visible: { transition: { staggerChildren: 0.1 } },
            hidden: {}
          }}
        >
          {wishlist.map((item, index) => {
            const product = item?.product;
            return (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                style={{ marginBottom: '16px' }}
              >
                <Paper variant='outlined'>
                  <Card variant='outlined'>
                    <Box sx={{ p: 2 }}>
                      <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Box display={'flex'}>
                          <Avatar alt='Product Thumbnail' src={product?.thumbnail_url} sx={{ width: 56, height: 56 }} />
                          <Box sx={{ marginLeft: 2 }}>
                            <Typography gutterBottom variant='h5' component='div'>
                              {product?.title}
                              <Stack direction='row' spacing={1}>
                                <Chip label={product?.brand?.title} size='small' />
                                <Chip label={product?.category?.title} size='small' />
                              </Stack>
                            </Typography>
                            <Typography gutterBottom variant='h6' component='div'>
                              ${product.price}
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                      <Typography color='text.secondary' variant='body2'>
                        {product.description}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                      <Grid container spacing={1} alignItems='center' justifyContent={'start'}>
                        <Grid item xs={12}>
                          <Typography>Select QUANTITY</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <IconButton color='primary' onClick={() => handleQuantityChange(product.id, -1)}>
                              <RemoveIcon />
                            </IconButton>
                            <TextField
                              value={quantities[product.id] || 1}
                              variant='outlined'
                              size='small'
                              sx={{ minWidth: 200, maxWidth: 200 }}
                              inputProps={{ readOnly: true }}
                            />
                            <IconButton color='primary' onClick={() => handleQuantityChange(product.id, 1)}>
                              <AddIcon />
                            </IconButton>
                            <Box>
                              <StyledSelect
                                value={selectedSizes[product.id] || ''}
                                onChange={e => handleSizeChange(product.id, e.target.value)}
                              >
                                <option value=''>Select Size</option>
                                {product?.weights &&
                                  product.weights.map(size => (
                                    <option key={size.id} value={size.id}>
                                      {size.size}
                                    </option>
                                  ))}
                              </StyledSelect>
                            </Box>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Stack direction='row' alignItems='center' spacing={1}>
                            <Button variant='contained' onClick={() => handleAddToCart(product.id)}>
                              Add To Cart
                            </Button>
                            <Button
                              variant='outlined'
                              color='error'
                              onClick={() => {
                                dispatch(removeProductFromWishListApi({ isToken, product_id: product.id })).then(
                                  action => {
                                    if (action?.meta?.requestStatus === 'fulfilled') {
                                      dispatch(countableItemApi({ isToken })).then(() => {
                                        dispatch(userWishListApi({ isToken })).then(() => {
                                          showAlert('success', 'Success', 'Product Removed From WishList', false, 'OK');
                                        });
                                      });
                                    }
                                  }
                                );
                              }}
                            >
                              Remove From WishList
                            </Button>
                            <Button
                              variant='text'
                              color='secondary'
                              onClick={() => {
                                dispatch(productDetailApi({ id: product?.id, isToken: isToken })).then(() => {
                                  navigate(`/product/${product?.id}`);
                                });
                              }}
                            >
                              <RemoveRedEyeIcon />
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </Paper>
              </motion.div>
            );
          })}
        </motion.div>
      ) : (
        <Paper variant='outlined' sx={{ p: 2, textAlign: 'center', maxWidth: 400, mx: 'auto' }}>
          <Card variant='outlined' component={Paper} elevation={15}>
            <Box sx={{ p: 4 }}>
              <Avatar alt='Empty Wishlist' src={emptyCart} sx={{ width: 150, height: 150, mx: 'auto' }} />
              <Typography color='text.primary' variant='h6' sx={{ mt: 2 }}>
                Your wishlist is empty
              </Typography>
              <Typography color='text.secondary' variant='body2' sx={{ mt: 1 }}>
                Fill it with groceries, electronic items, fashion wear, and more...
              </Typography>
              <Button variant='contained' color='primary' sx={{ mt: 3 }} onClick={() => navigate('/')}>
                Browse Products
              </Button>
            </Box>
          </Card>
        </Paper>
      )}

      {totalPages > 1 && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={totalPages}
            color='secondary'
            page={currentPage}
            onChange={(event, page) => handlePageChange(page)}
          />
        </Box>
      )}
    </Box>
  );
};

export default WishList;
